import React, { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next"
import { Storage } from "aws-amplify"
import PageModal from '../common/PageModal'
import VideoModal from '../common/VideoModal'
import LoadingSpinner from '../common/LoadingSpinner'
import { getDocuments } from "../../utils/requests/documentsApi"
import { getVideosList } from "../../utils/requests/videosAPI"
import { VideoPlayIcon } from '../../icons'
import './product-activation-steps-modal.scss'

const ProductActivationStepsModal = (props) => {
  const { t } = useTranslation()
  const { isG4Plus, isBravoDevice, closeModal } = props
  const [documents, setDocuments] = useState([])
  const [videos, setVideos] = useState([])
  const [selectedVideo, setSelectedVideo] = useState(null)
  const [showSpinner, setShowSpinner] = useState(false)
  const model = props.model || (isBravoDevice ? "Bravo" : "G4 Product");

  useEffect(async () => {
    setShowSpinner(true)

    const documentsQueryParams = {
      page: 1,
      exclude: "category:technical service bulletin",
      model: props.model,
      document_type: "Online Access",
    }

    const videosQueryParams = {
      page: 1,
      limit: 1,
      model: props.model,
      category: "Online Access",
    }

    const [documentsData, videosData] = await Promise.all([
      getDocuments(documentsQueryParams, 1), 
      getVideosList(videosQueryParams),
    ])
    
    if (documentsData?.data) {
      setDocuments(documentsData.data)
    }

    if (videosData?.data) {
      setVideos(videosData.data)
    }

    setShowSpinner(false)
  }, [])
  
  const getActivationSteps = () => {
    if (isG4Plus) return t("products.activation_step_g4plus")

    if (isBravoDevice) return t("products.activation_step_bravo")

    return t("products.activation_step_legacy", { model })
  }

  async function handleDownload(filePath, fileName) {
    Storage.configure({
      customPrefix: {
        public: "",
        protected: "",
        private: "",
      },
    })

    const result = await Storage.get(`${filePath}${fileName}`)
    window.open(result, "_blank")
  }

  const openVideoModal = (item) => {
    setSelectedVideo(item)
  }

  const closeVideoModal = () => {
    setSelectedVideo(null)
  }

  const handleCloseModal = () => {
    if (closeModal) {
      closeModal()
    }
  }

  return (
    <div className="product-activation-steps-modal-wrapper">
      <PageModal
        toggle
        onToggle={() => handleCloseModal()}
        title={t("products.activation_instructions", { model })}
      >
        {
          <div className="product-activation-steps-modal-content">
            <span className="instruction-wrapper">{getActivationSteps()}</span>
          </div>
        }
        {/* {isG4Plus &&
          <div className="product-activation-steps-modal-content">
            <div className="title">
              G4+ Product
            </div>
            <div className="instruction-wrapper">{t("products.activation_step_1")}</div>
            <div className="instruction-wrapper">{t("products.activation_step_2")}</div>
            <div className="instruction-wrapper">{t("products.activation_step_3")}</div>        
          </div>
        }
        {isBravoDevice && 
          <div className="product-activation-steps-modal-content">
            <div className="title">
              Bravo G4 Product
            </div>
            <ol className="instruction-wrapper">
              <li>{t("products.activation_step_1")}</li>
              <li>{t("products.activation_step_2")}</li>
              <li>{t("products.activation_step_3")}</li>
            </ol>
          </div>
        } */}
        {documents.length > 0 &&
          <div className="download-link-wrapper">
            <span>{t("products.problems_with_activation_download")}</span>
            <span className="download-link" onClick={() => handleDownload(documents[0].file_path, documents[0].file_name)}>
              {t("instructions")}
            </span>
          </div>
        }
        {videos.length > 0 &&
          <div className="thumbnail-wrapper" onClick = {() => openVideoModal(videos[0])}>
            <div className="thumbnail-icon">
              <VideoPlayIcon />
            </div>
            <img
              src={videos[0].thumbnail_url}
              alt="video"
            />
          </div>
        }
        {selectedVideo && 
          <VideoModal 
            title={selectedVideo.title} 
            videoId={selectedVideo.video_id} 
            onCloseVideo={closeVideoModal} 
          />
        }
        {showSpinner &&
          <div className="spinner-wrapper">
            <LoadingSpinner/>
          </div>
        }
      </PageModal>
    </div>
  )
}

export default ProductActivationStepsModal
