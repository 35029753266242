import React, { Fragment, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import AdminOrderDetails from "./AdminOrderDetails"
import FiltersModal, { FilterType } from "../common/FiltersModal"
import SearchWithFilter from "../common/SearchWithFilter"
import EmptyPageResults from "../common/EmptyPageResults"
import LoadingSpinner from "../common/LoadingSpinner"
import ClickOutside from "../common/ClickOutside"
import SimpleButton from "../common/SimpleButton"
import GenericTable from "../common/GenericTable"
import ActionsMenu from "../common/ActionsMenu"
import ExpandableText from "../common/ExpandableText"
import { toast } from "../common/Toast"
import {
  MenuKebabVerticalIcon,
  CaretDownIcon,
  MenuKebabVerticalBackgroundIcon,
  CheckCircleFilledIcon,
  PendingIcon,
  CanceledIcon
} from "../../icons"
import { loadOrderHistory, acceptRefund, declineRefund, completeRefund, clearAdminOrdersMessages } from "../../actions/adminOrderHistory"
import { formatStoreProductPrice } from "../../utils/filters/filters"
import { convertToSimpleDate } from "../../utils/filters/date"
import { downloadFile } from "../../utils/functions"
import { getAdminOrderHistoryCsv } from "../../utils/requests/productsAPI"
import { useWindowSize } from "../../libs/hooks"
import { useDeepCompareEffect } from "../../hooks/useDeepCompareEffect"

const orderingFields = [
  {
    field: "purchasedAt",
    order: "asc",
    translationKey: "date_asc",
  },
  {
    field: "purchasedAt",
    order: "desc",
    translationKey: "date_desc",
  },
  {
    field: "id",
    order: "asc",
    translationKey: "order_number_asc",
  },
  {
    field: "id",
    order: "desc",
    translationKey: "order_number_desc",
  },
  {
    field: "customerEmail",
    order: "asc",
    translationKey: "customer_asc",
  },
  {
    field: "customerEmail",
    order: "desc",
    translationKey: "customer_desc",
  },
  {
    field: "paymentAmount",
    order: "asc",
    translationKey: "total_price_asc",
  },
  {
    field: "paymentAmount",
    order: "desc",
    translationKey: "total_price_desc",
  },
  {
    field: "status",
    order: "asc",
    translationKey: "status_asc",
  },
  {
    field: "status",
    order: "desc",
    translationKey: "status_desc",
  },
]

const PendingOrders = (props) => {
  const { t } = useTranslation()
  const isMobile = useWindowSize()[0] <= 768
  const pendingStatuses = ["Refund Requested", "Refund In Progress"]
  const [ordersTableData, setOrdersTableData] = useState([])
  const [shouldApplyFilters, setShouldApplyFilters] = useState(true)
  const [startDateFilter, setStartDateFilter] = useState(null)
  const [endDateFilter, setEndDateFilter] = useState(null)
  const [searchParam, setSearchParam] = useState(null)
  const [orderBy, setOrderBy] = useState({ field: "purchasedAt", order: "desc", translationKey: "date_desc" })
  const [showSpinner, setShowSpinner] = useState(null)
  const [showFiltersModal, setShowFiltersModal] = useState(false)
  const [showActionsMenu, setShowActionsMenu] = useState(null)
  const [canLoadMore, setCanLoadMore] = useState(false)
  const [selectedPage, setSelectedPage] = useState(1)
  const [isFirstLoad, setIsFirstLoad] = useState(true)
  const [expandedOrder, setExpandedOrder] = useState(0)
  const [selectedOrder, setSelectedOrder] = useState(null)

  useEffect(() => {
    if (selectedOrder && props.orders.length > 0) {
      const order = props.orders.find((order) => order.id === selectedOrder.id)
      setSelectedOrder(order)
    }

    const tableData = props.orders.map((order, index) => {
      return {
        index,
        identifier: order.id,
        date: convertToSimpleDate(order.purchasedAt),
        customerEmail: order.customerEmail,
        price: formatStoreProductPrice((Number(order.paymentAmount) || 0) - (Number(order.taxAmount) || 0), order.paymentCurrency),
        tax: formatStoreProductPrice(order.taxAmount, order.paymentCurrency),
        totalPrice: formatStoreProductPrice(order.paymentAmount, order.paymentCurrency),
        status: order.status,
        actions: [
          {
            label: "view_details",
            onActionClick: (e) => { e.stopPropagation(); handleOpenOrderDetails(order.id) },
          },
          ...(order.status === "Refund Requested" ? [
            {
              label: "decline",
              onActionClick: (e) => { e.stopPropagation(); handleDeclineRefund(order.id) },
            },
            {
              label: "approve",
              onActionClick: (e) => { e.stopPropagation(); handleApproveRefund(order.id) },
            }
          ]
          : []),
          ...(order.status === "Refund In Progress" ? [
            {
              label: "store_context.reactivate_order",
              onActionClick: (e) => { e.stopPropagation(); handleDeclineRefund(order.id, true) },
            },
            {
              label: "store_context.complete_order",
              onActionClick: (e) => { e.stopPropagation(); handleCompleteRefund(order.id) },
            }
          ]
          : []),
        ]
      }
    })

    setOrdersTableData(tableData)
  }, [props.orders])

  useEffect(() => {
    setCanLoadMore(props.canLoadMore)
  }, [props.canLoadMore])

  useEffect(() => {
    setShowSpinner(props.isLoading)
  }, [props.isLoading])

  useEffect(() => {
    if (props.errorMessage) {
      toast.error(props.errorMessage)

      props.actions.clearAdminOrdersMessages()
    }
  }, [props.errorMessage])

  useEffect(() => {
    if (props.successMessage) {
      toast.success(props.successMessage)

      props.actions.clearAdminOrdersMessages()
    }
  }, [props.successMessage])

  useEffect(() => {
    if (selectedPage && selectedPage > 1) {
      handleLoadOrders(false)
    }
  }, [selectedPage])

  useDeepCompareEffect(() => {
    let changeValueTimeout = window.setTimeout(
      () => {
        if (shouldApplyFilters) {
          setSelectedPage(1)
          handleLoadOrders(true)
        }

        setShouldApplyFilters(true)
      },
      searchParam ? 1000 : 0
    )
    return () => {
      clearTimeout(changeValueTimeout)
    }
  }, [searchParam, startDateFilter, endDateFilter, orderBy])

  const handleLoadOrders = (withReset) => {
    let queryParams = createQueryParams()

    queryParams = {
      ...queryParams,
      offset: withReset ? 0 : (selectedPage - 1) * process.env.REACT_APP_PAGINATION_SIZE
    }

    props.actions.loadOrderHistory(queryParams, withReset)
      .then(() => setIsFirstLoad(false))
  }

  const handleSearch = (value) => {
    if (value?.length > 2) {
      setShowSpinner(true)
      setSearchParam(value)
    } else if (value?.length === 0 && searchParam?.length > 0) {
      setShowSpinner(true)
      setSearchParam(value)
    }
  }

  const handleSort = (orderingField) => {
    setOrderBy(orderingFields.find(o => o.field === orderingField.field && o.order === orderingField.order))
  }

  const handleLoadMore = () => {
    setSelectedPage((prevValue) => (prevValue ? prevValue + 1 : 2))
  }

  const handleResetFilters = () => {
    setStartDateFilter(null)
    setEndDateFilter(null)
  }

  const createQueryParams = () => {
    let queryParams = {status: pendingStatuses}
    
    if (searchParam) {
      queryParams = { ...queryParams, query: searchParam }
    }

    if (startDateFilter) {
      queryParams = { ...queryParams, startDate: startDateFilter }
    }

    if (endDateFilter) {
      queryParams = { ...queryParams, endDate: endDateFilter }
    }

    if (orderBy?.field && orderBy?.order) {
      queryParams = { ...queryParams, orderBy: orderBy.field, order: orderBy.order }
    }

    return queryParams
  }

  const exportToCsv = () => {
    if (showSpinner) {
      return
    }

    setShowSpinner(true)

    const queryParams = createQueryParams()

    getAdminOrderHistoryCsv(queryParams)
      .then((res) => {
        const date = new Date().toISOString().split('T')
        const dateNow = date[0].split('-').reverse().join('_')
        const timeNow = date[1].split('.')[0].split(':').join('_')
        downloadFile({
          data: res,
          fileName: `order-history_${dateNow}_${timeNow}.csv`,
          fileType: 'text/csv',
        })
        setShowSpinner(false)
        
        toast.success(t("coltene_store.admin_order_history.subscriptions-download-success"))
      })
      .catch(() => {
        setShowSpinner(false)

        toast.error(t("error.failure_msg"))
      })
  }

  const handleApproveRefund = (orderId) => {
    props.actions.acceptRefund(orderId)
  }

  const handleDeclineRefund = (orderId, isReactivate) => {
    props.actions.declineRefund(orderId, isReactivate)
  }

  const handleCompleteRefund = (orderId) => {
    props.actions.completeRefund(orderId)
  }

  const onSelectInput = (isComponentVisible, toggleVisibility) => {
    if (toggleVisibility) {
      toggleVisibility(!isComponentVisible)
    }
  }

  const selectOrderByOptions = (orderingField, toggleVisibility) => {
    handleSort(orderingField)

    if (toggleVisibility) {
      toggleVisibility(false)
    }
  }

  const selectOrderByOptionsInput = ({ toggleVisibility, isComponentVisible }) => (
    <div className={"select-input"}>
      <div className="height d-flex flex-align-center flex-justify-between" onClick={() => onSelectInput(isComponentVisible, toggleVisibility)}>
        <div className="d-flex flex-align-center h-100">{orderBy ? t(`sortable_context.${orderBy.translationKey}`) : t("select_ordering")}</div>
        <CaretDownIcon className={"mr-15 caret-dropdown-icon" + (isComponentVisible ? " icon-dropdown-open" : "")} />
      </div>
    </div>
  )

  const selectOrderByDropdownOptions = ({ toggleVisibility }) => (
    <div className="options-wrapper d-flex flex-align-center flex-column">
      {orderingFields?.map((orderingField, index) => (
        <div
          className={"option cursor-pointer no-wrap" + (orderingField === orderBy ? " selected-option" : "")}
          key={index}
          onClick={() => selectOrderByOptions(orderingField, toggleVisibility)}
        >
          {t(`sortable_context.${orderingField.translationKey}`)}
        </div>
      ))}
    </div>
  )

  const handleOpenOrderDetails = (orderId) => {
    const order = props.orders.find((order) => order.id === orderId)

    if (order) {
      setSelectedOrder(order)
    } 
  }

  const closeDetailsModal = () => {
    setSelectedOrder(null)
  }

  const toggleShowActionsMenu = (index) => {
    setShowActionsMenu(showActionsMenu === index ? null : index)
  }

  return (
    <Fragment>
      <div className="admin-orders-history-wrapper">
        <div className="actions-wrapper">
          <SearchWithFilter
            onSearch={(e) => handleSearch(e)}
            showFiltersModal={() => setShowFiltersModal(true)}
            onResetFilters={() => handleResetFilters()}
            showCounterBadge={startDateFilter && endDateFilter}
            counterBadge={startDateFilter && endDateFilter ? 1 : 0}
          />
          <SimpleButton 
            className="action-button" 
            onClick={() => exportToCsv()}
            disabled={showSpinner || !ordersTableData.length > 0}
          >
            {t('coltene_store.admin_order_history.export-csv')}
          </SimpleButton>
        </div>
        {isMobile && ordersTableData.length > 0 &&
          <div className="order-by-mobile-wrapper">
            <ClickOutside 
              itemRef="click-outside-wrapper" 
              eventItem={selectOrderByOptionsInput} 
              toDisplayItem={selectOrderByDropdownOptions} 
            />
          </div>
        }
        {!isMobile && ordersTableData.length > 0 && 
          <GenericTable
            data={ordersTableData}
            headers={[
              {
                title: t("date"),
                orderKey: 'purchasedAt'
              },
              {
                title: t("coltene_store.admin_order_history.order-number"),
                orderKey: 'id'
              },
              {
                title: t("coltene_store.admin_order_history.customer"),
                orderKey: 'customerEmail'
              },
              {
                title: t("price")
              },
              {
                title: t("tax")
              },
              {
                title: t("store_context.total_price"),
                orderKey: 'paymentAmount'
              },
              {
                title: t("status"),
                orderKey: 'status'
              },
              {
                title: ''
              },
            ]}
            keys={[
              'date',
              'identifier',
              'customerEmail',
              'price',
              'tax',
              'totalPrice',
              'status',
              'action',
            ]}
            keyRenderer={{
              date: (item) => {
                return (
                  <div className="date-wrapper">
                    <ExpandableText text={item.date} />
                  </div>
                )
              },
              status: (item) => {
                return (
                  <div className="status-wrapper">
                    {item.status === "Paid" || item.status === "Complete" || item.status === "Shipped" ? (
                      <CheckCircleFilledIcon className="success-svg" />
                    ) : item.status === "Refund In Progress" || item.status === "Refund Requested" ? (
                      <PendingIcon className="pending-svg" />
                    ) : (
                      <CanceledIcon className="cancel-svg" />
                    )}
                    <div
                      className={`status ${
                        item.status === "Paid" || item.status === "Complete" || item.status === "Shipped"
                          ? "processed"
                          : item.status === "Refund In Progress" || item.status === "Refund Requested"
                          ? "processing"
                          : "cancelled"
                      } d-flex flex-align-center`}
                    >
                      {item.status === "Refunded"
                        ? t("canceled")
                        : item.status === "Refund Requested"
                        ? t("coltene_store.admin_order_history.cancelation-requested")
                        : item.status === "Refund In Progress"
                        ? t("coltene_store.admin_order_history.cancelation-processing")
                        : item.status}
                    </div>
                  </div>
                )
              },
              action: (item) => {
                return (
                  <div className="table-actions-wrapper" onClick={(e) => { e.stopPropagation(); toggleShowActionsMenu(item["index"]) }}>
                    {showActionsMenu === item["index"] ? <MenuKebabVerticalBackgroundIcon /> : <MenuKebabVerticalIcon />}
                    {showActionsMenu === item["index"] &&
                      <ActionsMenu
                        setHideMenu={() => setShowActionsMenu(null)}
                        actions={item.actions}
                        isLoading={showSpinner}
                      />
                    }
                  </div>
                )
              }
            }}
            onRowClick={(order) => handleOpenOrderDetails(order.identifier)}
            activeSort={orderBy}
            onSort={(orderingField) => handleSort(orderingField)}
            isLoading={showSpinner}
          />
        }
        {isMobile && ordersTableData.map((order, index) =>
          <div 
            key={index}
            className="card" 
            onClick={() => setExpandedOrder(index)}
          >
            <div className={"card-item" + (expandedOrder !== index ? " align-center" : "")}>
              <div className="card-item-title">{t("date")}</div>
              <div className={"card-item-body" + (expandedOrder !== index ? " align-center" : "")}>
                <div>{order.date}</div>
                <div 
                  className="card-actions"
                  onClick={(e) => { e.stopPropagation(); setShowActionsMenu(index) }}
                >
                  {showActionsMenu === index ? <MenuKebabVerticalBackgroundIcon /> : <MenuKebabVerticalIcon />}
                  {showActionsMenu === index &&
                    <ActionsMenu
                      setHideMenu={() => setShowActionsMenu(null)}
                      actions={order.actions}
                      isLoading={showSpinner}
                    />
                  }
                </div>
              </div>
            </div>
            <div className="card-item">
              <div className="card-item-title">{t("coltene_store.admin_order_history.order-number")}</div>
              <div className="card-item-body">{order.identifier}</div>
            </div>
            {expandedOrder === index && (
              <>
                <div className="card-item">
                  <div className="card-item-title">{t("coltene_store.admin_order_history.customer")}</div>
                  <div className="card-item-body">{order.customerEmail}</div>
                </div>
                <div className="card-item">
                  <div className="card-item-title">{t("price")}</div>
                  <div className="card-item-body">{order.price}</div>
                </div>
                <div className="card-item">
                  <div className="card-item-title">{t("tax")}</div>
                  <div className="card-item-body">{order.tax}</div>
                </div>
                <div className="card-item">
                  <div className="card-item-title">{t("store_context.total_price")}</div>
                  <div className="card-item-body">{order.totalPrice}</div>
                </div>
                <div className="card-item">
                  <div className="card-item-title">{t("status")}</div>
                  <div className="card-item-body card-item-body-status">
                    {order.status === "Paid" || order.status === "Complete" || order.status === "Shipped" ? (
                      <CheckCircleFilledIcon className="success-svg" />
                    ) : order.status === "Refund In Progress" || order.status === "Refund Requested" ? (
                      <PendingIcon className="pending-svg" />
                    ) : (
                      <CanceledIcon className="cancel-svg" />
                    )}
                    <div
                      className={`status ${
                        order.status === "Paid" || order.status === "Complete" || order.status === "Shipped"
                          ? "processed"
                          : order.status === "Refund In Progress" || order.status === "Refund Requested"
                          ? "processing"
                          : "cancelled"
                      } d-flex flex-align-center`}
                    >
                      {order.status === "Refunded"
                        ? t("canceled")
                        : order.status === "Refund Requested"
                        ? t("coltene_store.admin_order_history.cancelation-requested")
                        : order.status === "Refund In Progress"
                        ? t("coltene_store.admin_order_history.cancelation-processing")
                        : order.status}
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>)
        }
        {!showSpinner && !isFirstLoad && ordersTableData.length === 0 && (
          <EmptyPageResults
            title={t("coltene_store.admin_order_history.no-orders-found")}
            subtitle={t("cannot_find_matching_search")}
          />
        )}
        {canLoadMore && (
          <div className="buttons-wrapper">
            <SimpleButton className="load-more-button" onClick={() => handleLoadMore()}>
              {t("load_more")}
            </SimpleButton>
          </div>
        )}
      </div>
      {showFiltersModal && (
        <FiltersModal
          filters={[
            {
              name: t("date"),
              type: FilterType.dateRange,
              dataset: null,
              input: { startDate: startDateFilter, endDate: endDateFilter },
              output: (startDate, endDate) => {
                setStartDateFilter(startDate)
                setEndDateFilter(endDate)
              },
            },
          ]}
          shouldFilter={(shouldApply) => setShouldApplyFilters(shouldApply)}
          resetFilters={() => handleResetFilters()}
          closeFilters={() => setShowFiltersModal(false)}
        />
      )}
      {showSpinner && (
        <div className={"spinner-wrapper"}>
          <LoadingSpinner />
        </div>
      )}
      {selectedOrder && (
        <AdminOrderDetails
          onCloseModal={closeDetailsModal}
          order={selectedOrder}
          showSpinner={showSpinner}
          handleDeclineRefundRequested={handleDeclineRefund}
          handleApproveRefund={handleApproveRefund}
          handleDeclineRefundInProgress={handleDeclineRefund}
          handleCompleteRefund={handleCompleteRefund}
        />
      )}
    </Fragment>
  )
}

function stateToProps({ adminOrderHistory }) {
  return {
    orders: adminOrderHistory?.orders || [],
    isLoading: adminOrderHistory?.ordersLoading,
    canLoadMore: adminOrderHistory?.canLoadMore,
    errorMessage: adminOrderHistory?.errorMessage,
    successMessage: adminOrderHistory?.successMessage,
  }
}

function dispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        loadOrderHistory,
        acceptRefund,
        declineRefund,
        completeRefund,
        clearAdminOrdersMessages,
      },
      dispatch
    ),
  }
}

export default connect(stateToProps, dispatchToProps)(PendingOrders)
