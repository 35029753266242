import React, { Fragment, useEffect, useLayoutEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { useHistory } from "react-router-dom"
import EditMemberModal from "./EditMemberModal"
import FiltersModal, { FilterType } from "../common/FiltersModal"
import SearchWithFilter from "../common/SearchWithFilter"
import EmptyPageResults from "../common/EmptyPageResults"
import LoadingSpinner from "../common/LoadingSpinner"
import ClickOutside from "../common/ClickOutside"
import SimpleButton from "../common/SimpleButton"
import GenericTable from "../common/GenericTable"
import ActionsMenu from "../common/ActionsMenu"
import ExpandableText from "../common/ExpandableText"
import ConfirmationModal from '../common/ConfirmationModal'
import { toast } from "../common/Toast"
import CheckboxInput from "../common/CheckboxInput"
import { SelectMenu } from "../common/SelectMenu"
import {
  MenuKebabVerticalIcon,
  CaretDownIcon,
  MenuKebabVerticalBackgroundIcon,
  EnabledIcon,
  PendingIcon,
  DisabledIcon,
} from "../../icons"
import { getCurrentUserPermissions } from '../../utils/requests/usersAPI'
import { updateAuthedUser } from "../../actions/authedUser"
import { handleSetCurrentUserPermissions } from "../../actions/userPermissions"
import { loadMembers, removeMember, clearMembersMessages, loadMembersFilterOptions, loadCurrentCompany } from "../../actions/members"
import { loadCompanyRoles } from "../../actions/companies"
import { updateCompanyInformation } from "../../actions/userCompany"
import { membersDeletePermission, membersWritePermission } from "../../utils/permissionValidation"
import { useWindowSize } from "../../libs/hooks"
import { useDeepCompareEffect } from "../../hooks/useDeepCompareEffect"

const orderingFields = [
  {
    field: "email",
    order: "asc",
    translationKey: "email_asc",
  },
  {
    field: "email",
    order: "desc",
    translationKey: "email_desc",
  },
  {
    field: "name",
    order: "asc",
    translationKey: "name_asc",
  },
  {
    field: "name",
    order: "desc",
    translationKey: "name_desc",
  },
  {
    field: "role",
    order: "asc",
    translationKey: "role_asc",
  },
  {
    field: "role",
    order: "desc",
    translationKey: "role_desc",
  },
  {
    field: "branch",
    order: "asc",
    translationKey: "branch_asc",
  },
  {
    field: "branch",
    order: "desc",
    translationKey: "branch_desc",
  },
  {
    field: "status",
    order: "asc",
    translationKey: "status_asc",
  },
  {
    field: "status",
    order: "desc",
    translationKey: "status_desc",
  }
]

const AllMembers = (props) => {
  const history = useHistory()
  const { t } = useTranslation()
  const isMobile = useWindowSize()[0] <= 768
  const [membersTableData, setMembersTableData] = useState([])
  const [shouldApplyFilters, setShouldApplyFilters] = useState(true)
  const [rolesFilter, setRolesFilter] = useState([])
  const [branchesFilter, setBranchesFilter] = useState([])
  const [statusesFilter, setStatusesFilter] = useState([])
  const [searchParam, setSearchParam] = useState(null)
  const [orderBy, setOrderBy] = useState(null)
  const [showSpinner, setShowSpinner] = useState(null)
  const [showFiltersModal, setShowFiltersModal] = useState(false)
  const [showActionsMenu, setShowActionsMenu] = useState(null)
  const [canLoadMore, setCanLoadMore] = useState(false)
  const [selectedPage, setSelectedPage] = useState(1)
  const [isFirstLoad, setIsFirstLoad] = useState(true)
  const [showDeleteMemberConfirmModal, setShowDeleteMemberConfirmModal] = useState(false)
  const [memberIdToDelete, setMemberIdToDelete] = useState(null)
  const [expandedMember, setExpandedMember] = useState(0)
  const [selectedMember, setSelectedMember] = useState(null)
  const [keepDevicesWithMember, setKeepDevicesWithMember] = useState(false)
  const [roleOptions, setRoleOptions] = useState([])
  const [showChangeAdminConfirmModal, setShowChangeAdminConfirmModal] = useState(false)
  const [newAdminEmail, setNewAdminEmail] = useState(null)
  const [oldAdminNewRoleId, setOldAdminNewRoleId] = useState(null)
  const [oldAdminNewRoleIdError, setOldAdminNewRoleIdError] = useState(false)
  const isDentistCompany = props.currentUserGroup.includes("dentist")
  const memberStatuses = ["activated", "pending", "disabled"]

  useLayoutEffect(() => {
    if (!props.companyId) {
      props.actions.loadCurrentCompany()
    }
  }, [props.actions])

  useEffect(() => {
    if (!props.companyId) {
      return
    }

    if (props.branchesOptions.length === 0 || props.rolesOptions.length === 0) {
      props.actions.loadMembersFilterOptions(props.companyId)
    }

    if (props.companyRoles.length === 0) {
      props.actions.loadCompanyRoles(props.companyId)
    }
  }, [props.companyId])

  useEffect(() => {
    if (props.companyRoles.length > 0) {
      const mappedRoles = props.companyRoles.map((role) => ({
        key: role.id,
        value: role.name.replace(/Dealer |Dentist /g, ""),
      }))

      setRoleOptions(mappedRoles)
    }
  }, [props.companyRoles])

  useEffect(() => {
    const tableData = props.members.map((member, index) => {
      return {
        index,
        identifier: member.id,
        email: member.email,
        name: `${member.firstname} ${member.lastname}`,
        role: member.role,
        branch: member.branchName,
        status: member.activationstatus,
        actions: member.email !== props.companyAdminEmail ? [
          {
            label: t("edit"),
            onActionClick: (e) => { e.stopPropagation(); handleOpenEditMember(member.id) },
          },
          ...(member.activationstatus === memberStatuses[0] ? 
            [
              {
                label: t("organization_context.change_admin"),
                onActionClick: (e) => { e.stopPropagation(); showChangeAdminModal(member.email) },
                requiredPermission: membersWritePermission,
              },
            ] : []
          ),
          {
            label: t("remove"),
            onActionClick: (e) => { e.stopPropagation(); showDeleteConfirmationModal(member.uuid) },
            requiredPermission: membersDeletePermission,
          },
        ] : []
      }
    })

    setMembersTableData(tableData)
  }, [props.members])

  useEffect(() => {
    setCanLoadMore(props.canLoadMore)
  }, [props.canLoadMore])

  useEffect(() => {
    setShowSpinner(props.isLoading)
  }, [props.isLoading])

  useEffect(() => {
    if (props.errorMessage) {
      toast.error(props.errorMessage)

      props.actions.clearMembersMessages()
    }
  }, [props.errorMessage])

  useEffect(() => {
    if (props.successMessage) {
      toast.success(props.successMessage)

      props.actions.clearMembersMessages()
    }
  }, [props.successMessage])

  useEffect(() => {
    if (selectedPage && selectedPage > 1) {
      handleLoadMembers(false)
    }
  }, [selectedPage])

  useDeepCompareEffect(() => {
    let changeValueTimeout = window.setTimeout(
      () => {
        if (shouldApplyFilters && props.companyId) {
          setSelectedPage(1)
          handleLoadMembers(true)
        }

        setShouldApplyFilters(true)
      },
      searchParam ? 1000 : 0
    )
    return () => {
      clearTimeout(changeValueTimeout)
    }
  }, [props.companyId, searchParam, rolesFilter, branchesFilter, statusesFilter, orderBy])

  const handleLoadMembers = (withReset) => {
    let queryParams = createQueryParams()

    queryParams = {
      ...queryParams,
      offset: withReset ? 0 : (selectedPage - 1) * process.env.REACT_APP_PAGINATION_SIZE,
      limit: process.env.REACT_APP_PAGINATION_SIZE,
    }

    props.actions.loadMembers(props.companyId, queryParams, withReset)
      .finally(() => setIsFirstLoad(false))
  }

  const handleSearch = (value) => {
    if (value?.length > 2) {
      setShowSpinner(true)
      setSearchParam(value)
    } else if (value?.length === 0 && searchParam?.length > 0) {
      setShowSpinner(true)
      setSearchParam(value)
    }
  }

  const handleSort = (orderingField) => {
    setOrderBy(orderingFields.find(o => o.field === orderingField.field && o.order === orderingField.order))
  }

  const handleLoadMore = () => {
    setSelectedPage((prevValue) => (prevValue ? prevValue + 1 : 2))
  }

  const handleResetFilters = () => {
    setRolesFilter([])
    setBranchesFilter([])
    setStatusesFilter([])
  }

  const createQueryParams = () => {
    let queryParams = {}

    if (searchParam) {
      queryParams = { ...queryParams, query: searchParam }
    }

    if (rolesFilter.length > 0) {
      queryParams = { ...queryParams, roles: rolesFilter }
    }

    if (branchesFilter.length > 0) {
      queryParams = { ...queryParams, branches: branchesFilter }
    }

    if (statusesFilter.length > 0) {
      queryParams = { ...queryParams, status: statusesFilter }
    }

    if (orderBy?.field && orderBy?.order) {
      queryParams = { ...queryParams, orderBy: orderBy.field, order: orderBy.order }
    }

    return queryParams
  }

  const onSelectInput = (isComponentVisible, toggleVisibility) => {
    if (toggleVisibility) {
      toggleVisibility(!isComponentVisible)
    }
  }

  const selectOrderByOptions = (orderingField, toggleVisibility) => {
    handleSort(orderingField)

    if (toggleVisibility) {
      toggleVisibility(false)
    }
  }

  const selectOrderByOptionsInput = ({ toggleVisibility, isComponentVisible }) => (
    <div className={"select-input"}>
      <div className="height d-flex flex-align-center flex-justify-between" onClick={() => onSelectInput(isComponentVisible, toggleVisibility)}>
        <div className="d-flex flex-align-center h-100">{orderBy ? t(`sortable_context.${orderBy.translationKey}`) : t("select_ordering")}</div>
        <CaretDownIcon className={"mr-15 caret-dropdown-icon" + (isComponentVisible ? " icon-dropdown-open" : "")} />
      </div>
    </div>
  )

  const selectOrderByDropdownOptions = ({ toggleVisibility }) => (
    <div className="options-wrapper d-flex flex-align-center flex-column">
      {orderingFields.map((orderingField, index) => (
        <div
          className={"option cursor-pointer no-wrap" + (orderingField === orderBy ? " selected-option" : "")}
          key={index}
          onClick={() => selectOrderByOptions(orderingField, toggleVisibility)}
        >
          {t(`sortable_context.${orderingField.translationKey}`)}
        </div>
      ))}
    </div>
  )

  const handleOpenEditMember = (memberId) => {
    const member = props.members.find((member) => member.id === memberId)

    if (member) {
      setSelectedMember(member)
    }
  }

  const showDeleteConfirmationModal = (id) => {
    setMemberIdToDelete(id)
    setShowDeleteMemberConfirmModal(true)
  }

  const handleDeleteMember = () => {
    setShowDeleteMemberConfirmModal(false)

    if (!props.companyId) {
      return
    }

    const bodyParams = {
      ...(false && { keepDeviceWithMember: keepDevicesWithMember }),
    }
  
    props.actions.removeMember(props.companyId, memberIdToDelete, bodyParams)
  }

  const closeEditModal = () => {
    setSelectedMember(null)
  }
 
  const toggleShowActionsMenu = (index) => {
    setShowActionsMenu(showActionsMenu === index ? null : index)
  }

  const renderMemberStatus = (status) => {
    switch (status) {
      case memberStatuses[0]:
        return (
          <div className="status-active">
            <EnabledIcon />
            <p>{t("enabled")}</p>
          </div>
        )
      case memberStatuses[1]:
        return (
          <div className="status-pending">
            <PendingIcon />
            <p>{t("pending")}</p>
          </div>
        )
      case memberStatuses[2]:
        return (
          <div className="status-inactive">
            <DisabledIcon />
            <p>{t("disabled")}</p>
          </div>
        )
      default:
        return null
    }
  }

  const showChangeAdminModal = (email) => {
    setNewAdminEmail(email)
    setShowChangeAdminConfirmModal(true)
  }

  const handleChangeAdmin = () => {
    setOldAdminNewRoleIdError(false)

    if (!oldAdminNewRoleId) {
      setOldAdminNewRoleIdError(true)
      toast.error(t("error.fill_all_fields"))
      return
    }

    setShowChangeAdminConfirmModal(false)

    const bodyParams = {
      companyName: props.companyName,
      country: props.country,
      officeAddressOne: props.officeAddressOne,
      city: props.city,
      stateProvinceRegion: props.stateProvinceRegion,
      zipPostalCode: props.zipPostalCode,
      status: props.status,
      prevAdminRoleId: oldAdminNewRoleId,
      email: newAdminEmail,
    }

    props.actions.updateCompanyInformation(bodyParams)
      .then(() => {
        return new Promise((resolve, reject) => {
          try {
            props.actions.updateAuthedUser({
              companyAccountType: "individual",
              userGroup: props.companyRoles.find((role) => role.id === oldAdminNewRoleId)?.name?.toLowerCase()?.replace(/ /g, "_") || "",
            })
            resolve()
          } catch (error) {
            reject(error)
          }
        })
      })
      .then(async () => {
        history.push({ pathname: `/` })

        const permissions = await getCurrentUserPermissions()
        props.actions.handleSetCurrentUserPermissions(permissions)
      })
  }

  return (
    <Fragment>
      <div className="all-members-wrapper">
        <div className="actions-wrapper">
          <SearchWithFilter
            onSearch={(e) => handleSearch(e)}
            showFiltersModal={() => setShowFiltersModal(true)}
            onResetFilters={() => handleResetFilters()}
            showCounterBadge={rolesFilter.length > 0 || branchesFilter.length > 0 || statusesFilter.length > 0}
            counterBadge={rolesFilter.length + branchesFilter.length + statusesFilter.length}
          />
        </div>
        {isMobile && membersTableData.length > 0 &&
          <div className="order-by-mobile-wrapper">
            <ClickOutside 
              itemRef="click-outside-wrapper" 
              eventItem={selectOrderByOptionsInput} 
              toDisplayItem={selectOrderByDropdownOptions} 
            />
          </div>
        }
        {!isMobile && membersTableData.length > 0 && 
          <GenericTable
            data={membersTableData}
            headers={[
              {
                title: t("email"),
                orderKey: "email",
              },
              {
                title: t("name"),
                orderKey: "name",
              },
              {
                title: t("role"),
                orderKey: "role",
              },
              {
                title: t("branch"),
                orderKey: "branch",
              },
              {
                title: t("dealer-portal.registration_status"),
                orderKey: "status",
              },
              {
                title: ''
              },
            ]}
            keys={[
              'email',
              'name',
              'role',
              'branch',
              'status',
              'action',
            ]}
            keyRenderer={{
              branch: (item) => {
                return (
                  <div className="branch-wrapper">
                    <ExpandableText text={item.branch} />
                  </div>
                )
              },
              status: (item) => {
                return (
                  <div className="status-wrapper">
                    {renderMemberStatus(item.status)}
                  </div>
                )
              },
              action: (item) => {
                return (
                  <>
                    {item.email !== props.companyAdminEmail && (
                      <div className="table-actions-wrapper" onClick={(e) => { e.stopPropagation(); toggleShowActionsMenu(item["index"]) }}>
                        {showActionsMenu === item["index"] ? <MenuKebabVerticalBackgroundIcon /> : <MenuKebabVerticalIcon />}
                        {showActionsMenu === item["index"] &&
                          <ActionsMenu
                            setHideMenu={() => setShowActionsMenu(null)}
                            actions={item.actions}
                            isLoading={showSpinner}
                          />
                        }
                      </div>)
                    }
                  </>
                )
              }
            }}
            onRowClick={(member) => member.email !== props.companyAdminEmail && handleOpenEditMember(member.identifier)}
            activeSort={orderBy}
            onSort={(orderingField) => handleSort(orderingField)}
            isLoading={showSpinner}
          />
        }
        {isMobile && membersTableData.map((member, index) =>
          <div 
            key={index}
            className="card" 
            onClick={() => setExpandedMember(index)}
          >
            <div className={"card-item" + (expandedMember !== index ? " align-center" : "")}>
              <div className="card-item-title">{t("email")}</div>
              <div className={"card-item-body" + (expandedMember !== index ? " align-center" : "")}>
                <div>{member.email}</div>
                {member.email !== props.companyAdminEmail && (
                  <div 
                    className="card-actions"
                    onClick={(e) => { e.stopPropagation(); setShowActionsMenu(index) }}
                  >
                    {showActionsMenu === index ? <MenuKebabVerticalBackgroundIcon /> : <MenuKebabVerticalIcon />}
                    {showActionsMenu === index &&
                      <ActionsMenu
                        setHideMenu={() => setShowActionsMenu(null)}
                        actions={member.actions}
                        isLoading={showSpinner}
                      />
                    }
                  </div>)
                }
              </div>
            </div>
            {expandedMember === index && (
              <>
                <div className="card-item">
                  <div className="card-item-title">{t("name")}</div>
                  <div className="card-item-body">{member.name}</div>
                </div>
                <div className="card-item">
                  <div className="card-item-title">{t("role")}</div>
                  <div className="card-item-body">{member.role}</div>
                </div>
                <div className="card-item">
                  <div className="card-item-title">{t("branch")}</div>
                  <div className="card-item-body">{member.branch}</div>
                </div>
                <div className="card-item">
                  <div className="card-item-title">{t("dealer-portal.registration_status")}</div>
                  <div className="card-item-body card-item-body-status">
                    {renderMemberStatus(member.status)}
                  </div>
                </div>
              </>
            )}
          </div>)
        }
        {!showSpinner && !isFirstLoad && membersTableData.length === 0 && (
          <EmptyPageResults
            title={t("dealer-portal.no_members_found")}
            subtitle={t("dealer-portal.no_members_found_subtitle")}
          />
        )}
        {canLoadMore && (
          <div className="buttons-wrapper">
            <SimpleButton className="load-more-button" onClick={() => handleLoadMore()}>
              {t("load_more")}
            </SimpleButton>
          </div>
        )}
      </div>
      {showFiltersModal && (
        <FiltersModal
          filters={[
            {
              name: t("dealer-portal.registration_status"),
              type: FilterType.multiSelect,
              dataset: memberStatuses,
              input: statusesFilter,
              output: (filterStatuses) => {
                setStatusesFilter(filterStatuses)
              },
            },
            {
              name: t("branch"),
              type: FilterType.multiSelect,
              dataset: props.branchesOptions,
              input: branchesFilter,
              output: (filterBranches) => {
                setBranchesFilter(filterBranches)
              },
            },
            {
              name: t("role"),
              type: FilterType.multiSelect,
              dataset: props.rolesOptions,
              input: rolesFilter,
              output: (filterRoles) => {
                setRolesFilter(filterRoles)
              },
            },
          ]}
          shouldFilter={(shouldApply) => setShouldApplyFilters(shouldApply)}
          resetFilters={() => handleResetFilters()}
          closeFilters={() => setShowFiltersModal(false)}
        />
      )}
      {showSpinner && (
        <div className={"spinner-wrapper"}>
          <LoadingSpinner />
        </div>
      )}
      {selectedMember && (
        <EditMemberModal
          selectedMember={selectedMember}
          closeModal={closeEditModal}
          showRoleDetails={props.showRoleDetails}
        />
      )}
      {showDeleteMemberConfirmModal && (
        <ConfirmationModal
          className="remove-member-confirmation"
          onToggle={() => setShowDeleteMemberConfirmModal(false)}
          onAccept={() => handleDeleteMember()}
          onCancel={() => setShowDeleteMemberConfirmModal(false)}
          message={t("dealer-portal.confirm_delete_member")}
          acceptButtonText={t("remove")}
          isWarning
          requiredPermission={membersDeletePermission}
        >
          {/*A.S: To be hidden until requirement is established */}
          {false && (
            <div className="remove-member-confirmation-wrapper">
              <div className="disclaimer">
                <p>
                  {t("dealer-portal.keep_devices_with_member_disclaimer")}
                </p>
              </div>
              <CheckboxInput
                checked={keepDevicesWithMember}
                text={t("dealer-portal.keep_devices_with_member")}
                onChange={() => setKeepDevicesWithMember(!keepDevicesWithMember)}
              />
            </div>)
          }
        </ConfirmationModal>)
      }
      {showChangeAdminConfirmModal && (
        <ConfirmationModal
          className="change-admin-confirmation"
          onToggle={() => {
            setNewAdminEmail(null)
            setShowChangeAdminConfirmModal(false)
          }}
          message={t("dealer-portal.change_admin_confirmation")}
          onCancel={()=> {
            setNewAdminEmail(null)
            setShowChangeAdminConfirmModal(false)
          }}
          onAccept={() => handleChangeAdmin()}
          acceptButtonText={t("confirm")}
          requiredPermission={membersWritePermission}
          isInformation
        >
          <div className="change-admin-confirmation-wrapper">
            <div className="disclaimer">
              <p>
                {t("dealer-portal.change_admin_disclaimer_one")}
              </p>
            </div>
            <div className="title">{t("dealer-portal.new_role")}</div>
            {/*A.S: Needs to be Dropdown when that task is merged */}
            <SelectMenu
              className={`${oldAdminNewRoleIdError ? 'has-error' : ''}`}
              options={roleOptions}
              onChange={(option) => setOldAdminNewRoleId(option.key)}
              placeholder={t("dealer-portal.select_new_role")}
            />
          </div>
        </ConfirmationModal>
      )}
    </Fragment>
  )
}

function stateToProps({ authedUser, members, companies, userCompany }) {
  return {
    companyAdminEmail: authedUser?.email,
    currentUserGroup: authedUser?.userGroup || "",
    companyId: members?.companyId,
    companyStatus: members?.companyStatus,
    companyName: members?.companyName,
    country: members?.country,
    officeAddressOne: members?.officeAddressOne,
    city: members?.city,
    stateProvinceRegion: members?.stateProvinceRegion,
    zipPostalCode: members?.zipPostalCode,
    members: members?.members || [],
    branchesOptions: members?.branchesOptions || [],
    rolesOptions: members?.rolesOptions || [],
    isLoading: members?.isLoading || companies?.isLoading || userCompany?.isLoading,
    canLoadMore: members?.canLoadMoreMembers,
    errorMessage: members?.errorMessage || userCompany?.errorMessage,
    successMessage: members?.successMessage || userCompany?.successMessage,
    companyRoles: companies?.roles || [],
  }
}

function dispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        loadCurrentCompany,
        loadMembers,
        loadMembersFilterOptions,
        loadCompanyRoles,
        removeMember,
        clearMembersMessages,
        updateCompanyInformation,
        updateAuthedUser,
        handleSetCurrentUserPermissions,
      },
      dispatch
    ),
  }
}

export default connect(stateToProps, dispatchToProps)(AllMembers)
