import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import TemperatureChart from './TemperatureChart'
import InputChart from './InputChart'
import OutputChart from './OutputChart'
import FanFrame1 from './SVG/fan_frame1.svg'
import FanFrame2 from './SVG/fan_frame2.svg'
import FanOff from './SVG/fan_off.svg'
import DoorClosed from './SVG/door_closed.svg'
import DoorOpened from './SVG/door_opened.svg'
import LeakSensor from './SVG/leak_sensor.svg'
import BlowerHighHeaterOn from './SVG/blower_high_heater_on.svg'
import BlowerHighHeaterOff from './SVG/blower_high_heater_off.svg'
import BlowerLowHeaterOff from './SVG/blower_low_heater_off.svg'
import BlowerOffHeaterOff from './SVG/blower_off_heater_off.svg'
import FanBlowerFrame1 from './SVG/fan_blower_frame1.svg'
import FanBlowerFrame2 from './SVG/fan_blower_frame2.svg'
import CondenserOnHeaterOn from './SVG/condenser_on_heater_on.svg'
import CondenserOnHeaterOff from './SVG/condenser_on_heater_off.svg'
import CondenserOffHeaterOff from './SVG/condenser_off_heater_off.svg'
import HepaOn from './SVG/hepa_on.svg'
import HepaOff from './SVG/hepa_off.svg'
import WasherBox from './SVG/washer_box.svg'
import RpmMonitor0 from './SVG/rpm_monitor_0.svg'
import RpmMonitor1 from './SVG/rpm_monitor_1.svg'
import RpmMonitor2 from './SVG/rpm_monitor_2.svg'
import RpmMonitor2Plus from './SVG/rpm_monitor_2+.svg'
import WasherArmTopNoPressureCold from './SVG/washer_arm_top_no_pressure_cold.svg'
import WasherArmTopNoPressureHot from './SVG/washer_arm_top_no_pressure_hot.svg'
import WasherArmTopLowPressureCold from './SVG/washer_arm_top_low_pressure_cold.svg'
import WasherArmTopLowPressureHot from './SVG/washer_arm_top_low_pressure_hot.svg'
import WasherArmTopHighPressureCold from './SVG/washer_arm_top_high_pressure_cold.svg'
import WasherArmTopHighPressureHot from './SVG/washer_arm_top_high_pressure_hot.svg'
import WasherArmTopOff from './SVG/washer_arm_top_off.svg'
import WasherArmBottomNoPressureCold from './SVG/washer_arm_bottom_no_pressure_cold.svg'
import WasherArmBottomNoPressureHot from './SVG/washer_arm_bottom_no_pressure_hot.svg'
import WasherArmBottomLowPressureCold from './SVG/washer_arm_bottom_low_pressure_cold.svg'
import WasherArmBottomLowPressureHot from './SVG/washer_arm_bottom_low_pressure_hot.svg'
import WasherArmBottomHighPressureCold from './SVG/washer_arm_bottom_high_pressure_cold.svg'
import WasherArmBottomHighPressureHot from './SVG/washer_arm_bottom_high_pressure_hot.svg'
import WasherArmBottomOff from './SVG/washer_arm_bottom_off.svg'
import TempOff from './SVG/temperature_off.svg'
import TempCold from './SVG/temperature_cold.svg'
import TempWarm from './SVG/temperature_warm.svg'
import TempHot from './SVG/temperature_hot.svg'
import WaterValvesOff from './SVG/water_valves_off.svg'
import WaterValvesColdOn from './SVG/water_valves_cold_on.svg'
import WaterValvesHotOn from './SVG/water_valves_hot_on.svg'
import WaterValvesColdAndHotOn from './SVG/water_valves_cold_and_hot_on.svg'
import AirGapValveSoftenerOn from './SVG/air_gap_valve_softener_on.svg'
import AirGapValveSoftenerOff from './SVG/air_gap_valve_softener_off.svg'
import SaltReservoirSwitchOn from './SVG/salt_reservoir_switch_on.svg'
import SaltReservoirSwitchOff from './SVG/salt_reservoir_switch_off.svg'
import SaltReservoirValveOn from './SVG/salt_reservoir_valve_on.svg'
import SaltReservoirValveOff from './SVG/salt_reservoir_valve_off.svg'
import AirGapPumpConnOn from './SVG/air_gap_pump_conn_on.svg'
import AirGapPumpConnOff from './SVG/air_gap_pump_conn_off.svg'
import AirGapPumpConnOnValveOn from './SVG/air_gap_pump_conn_on_valve_on.svg'
import AirGapPumpConnOnValveOff from './SVG/air_gap_pump_conn_on_valve_off.svg'
import AirGapPumpConnOffValveOff from './SVG/air_gap_pump_conn_off_valve_off.svg'
import airGapSwitchOverflowHotWater from './SVG/air_gap_switch_overflow_hot_water.svg'
import airGapSwitchOverflowColdWater from './SVG/air_gap_switch_overflow_cold_water.svg'
import airGapSwitchFullHotWater from './SVG/air_gap_switch_full_hot_water.svg'
import airGapSwitchFullColdWater from './SVG/air_gap_switch_full_cold_water.svg'
import airGapSwitchLowHotWater from './SVG/air_gap_switch_low_hot_water.svg'
import airGapSwitchLowColdWater from './SVG/air_gap_switch_low_cold_water.svg'
import airGapSwitchEmpty from './SVG/air_gap_switch_empty.svg'
import roValveOn from './SVG/ro_valve_on.svg'
import roValveOff from './SVG/ro_valve_off.svg'
import drainPumpOnCondenserOn from './SVG/drain_pump_on_condenser_on.svg'
import drainPumpOffCondenserOn from './SVG/drain_pump_off_condenser_on.svg'
import drainPumpOffCondenserOff from './SVG/drain_pump_off_condenser_off.svg'
import circulationPumpOn from './SVG/circulation_pump_on.svg'
import circulationPumpOff from './SVG/circulation_pump_off.svg'
import airValveOn from './SVG/air_valve_on.svg'
import airValveOff from './SVG/air_valve_off.svg'
import airTankOn from './SVG/air_tank_on.svg'
import airTankOff from './SVG/air_tank_off.svg'
import waterConnectionOn from './SVG/water_connection_on.svg'
import waterConnectionOff from './SVG/water_connection_off.svg'
import lcsAirConnected from './SVG/lcs_air_connected.svg'
import lcsPumpOn from './SVG/lcs_pump_on.svg'
import lcsAirWaterOff from './SVG/lcs_air_water_off.svg'
import lcsNotInUsePumpOn from './SVG/lcs_not_in_use_pump_on.svg'
import lcsNotInUsePumpOff from './SVG/lcs_not_in_use_pump_off.svg'
import lcsNotConnected from './SVG/lcs_not_connected.svg'
import chamberOverflowCold from './SVG/chamber_overflow_cold_water.svg'
import chamberOverflowHot from './SVG/chamber_overflow_hot_water.svg'
import chamberFullCold from './SVG/chamber_full_cold_water.svg'
import chamberFullHot from './SVG/chamber_full_hot_water.svg'
import chamberLowCold from './SVG/chamber_low_cold_water.svg'
import chamberLowHot from './SVG/chamber_low_hot_water.svg'
import chamberEmpty from './SVG/chamber_empty.svg'
import waterHeaterOn from './SVG/water_heater_on.svg'
import waterHeaterOff from './SVG/water_heater_off.svg'
import rinseAidValveOn from './SVG/rinse_aid_valve_on.svg'
import rinseAidValveOff from './SVG/rinse_aid_valve_off.svg'
import rinseAidOn from './SVG/rinse_aid_on.svg'
import rinseAidOff from './SVG/rinse_aid_off.svg'
import dosingBellowsPumpOn from './SVG/dosing_bellows_pump_on.svg'
import dosingBellowsPumpOff from './SVG/dosing_bellows_pump_off.svg'
import dosingFillingPumpOn from './SVG/dosing_filling_pump_on.svg'
import dosingFillingPumpOff from './SVG/dosing_filling_pump_off.svg'
import detergentReservoirOn from './SVG/detergent_reservoir_on.svg'
import detergentReservoirOff from './SVG/detergent_reservoir_off.svg'
import detergentPouch from './SVG/detergent_pouch.svg'
import TimeElapsedIcon from './SVG/time_elapsed.svg'
import TimeRemainingIcon from './SVG/time_remaining.svg'
import LedCycleComplete from './SVG/led_cycle_complete.svg'
import LedCycleStopped from './SVG/led_cycle_stopped.svg'
import LedCycleRunning from './SVG/led_cycle_running.svg'
import LedStandby from './SVG/led_standby.svg'
import LedOff from './SVG/led_off.svg'

//screen 2
import { ReactComponent as WdDeviceScreen2 } from './SVG/wd_screen2.svg'
import { ReactComponent as WDeviceScreen2 } from './SVG/w_screen2.svg'

import './hydrim-debug.scss'

const dataParsing = (rawData) => {
  return {
    airGapPump: rawData?.o_pwm_airgap_pump,
    valveCondenser: rawData?.o_valve_condenser,
    valveColdWater: rawData?.o_valve_cold_water,
    valveHotWater: rawData?.o_valve_hot_water,
    airGapSwitchLow: rawData?.i_sw_airgap_low,
    airGapSwitchFull: rawData?.i_sw_airgap_full,
    airGapSwitchOverflow: rawData?.i_sw_airgap_overflow,
    valveAirGap: rawData?.o_valve_airgap,
    lcsAirPressureSwitch: rawData?.i_sw_lcs_air_pressure,
    valveLcs: rawData?.o_valve_lcs,
    dryerHeater: rawData?.o_dryer_heater,
    dryerMotor: rawData?.o_pwm_dryer_motor,
    chamberOverflow: rawData?.i_sw_chamber_overflow,
    chamberFull: rawData?.i_sw_chamber_full,
    chamberLow: rawData?.i_sw_chamber_low,
    temperatureChamber: rawData?.d_temperature_chamber ? rawData?.d_temperature_chamber / 100 : 0,
    circulationPump: rawData?.o_pwm_circulation_pump,
    chemicalReservoirSwitch: rawData?.i_sw_chemical_reservoir_full,
    doorPosition: rawData?.i_sw_door_position,
    dosingBellowsPump: rawData?.o_dosing_bellows_pump,
    dosingFillingPump: rawData?.o_dosing_filling_pump,
    dosingBellowsPumpCountdown: rawData?.c_bellows_pump_dosing_countdown,
    evacuationPump: rawData?.o_evacuation_pump,
    airFilterHepa: rawData?.i_sw_air_filter_hepa,
    lcsPresent: rawData?.i_sw_lcs_present,
    lcsInstrumentsConnected: rawData?.i_lcs_instruments_connected,
    rinseAidSwitch: rawData?.i_sw_rinse_aid_level,
    valveRinseAid: rawData?.o_valve_rinse_aid,
    valveRoWater: rawData?.o_valve_ro_water,
    valveSalt: rawData?.o_valve_salt,
    saltReservoirSwitch: rawData?.i_sw_salt,
    pressureBottomArm: rawData?.i_pressure_bottom_arm_mbar,
    pressureTopArm: rawData?.i_pressure_top_arm_mbar,
    waterHeater: rawData?.o_water_heater,
    countRpmSignalsBottomArm: rawData?.i_rpm_bottom_arm_640ms,
    countRpmSignalsTopArm: rawData?.i_rpm_top_arm_640ms,
    cycleElapsedTime: rawData?.c_cycle_elapsed_time_ms,
    cycleId: rawData?.d_unique_cycle_id,
    primaryPhaseRemainingTime: rawData?.c_primary_phase_remaining_time_ms,
    machineActionState: rawData?.c_machine_action_state,
    machineAbstractState: rawData?.c_machine_abstract_state,
    rpmBottomArmFiltered: rawData?.i_rpm_bottom_arm_filtered,
    rpmTopArmFiltered: rawData?.i_rpm_top_arm_filtered,
    cyclePhasePrimary: rawData?.c_cycle_phase_primary,
    fanLcd: rawData?.o_pwm_fan_lcd,
    fanPcb: rawData?.o_pwm_fan_pcb,
    time: rawData?.SYST,

    //Screen 2
    dosingValidation: rawData?.i_sw_dosing_validation,
    waterLeak: rawData?.i_sw_water_leak,
    TCH: rawData?.i_temperature_chamber / 100 || '00.00',
    TCHV: rawData?.i_temperature_chamber_validation / 100 || '00.00',
    TDRY: rawData?.i_temperature_drying_air / 100 || '00.00',
    RPMD: rawData?.i_rpm_blower || '00.00',
    PTAR: rawData?.i_pressure_top_arm_mbar || '00.00',
    PBAR: rawData?.i_pressure_bottom_arm_mbar || '00.00',
    RPMT: rawData?.i_rpm_top_arm_filtered || '00.00',
    RPMB: rawData?.i_rpm_bottom_arm_filtered || '00.00',
    CW: rawData?.i_conductivity_us || '00.00',
    PCL: rawData?.i_pressure_chamber / 10 || '00.00',
    TIO: rawData?.i_temperature_ambient_1 / 100 || '00.00',
    TLCD: rawData?.i_temperature_ambient_2 / 100 || '00.00',
    A0: rawData?.d_a0 || '00.00', //not available in payload
    CPCD: rawData?.c_bellows_pump_dosing_countdown || '00.00',
    // MLC : rawData?. TODO
    FROW: rawData?.i_ro_water_flow_rate || '00.00',
    FCHW: rawData?.i_hot_cold_water_flow_rate || '00.00',
    FLCS: rawData?.i_lcs_water_flow_rate || '00.00',

    PPAG: rawData?.o_pwm_airgap_pump || '00.00',
    PFIO: rawData?.o_pwm_fan_pcb || '00.00',
    PFLC: rawData?.o_pwm_fan_lcd || '00.00',
    PDRY: rawData?.o_pwm_dryer_motor || '00.00',
    PPCI: rawData?.o_pwm_circulation_pump || '00.00',
    LEDR: rawData?.o_pwm_led_r || '00.00',
    LEDG: rawData?.o_pwm_led_g || '00.00',
    LEDB: rawData?.o_pwm_led_b || '00.00',
  }
}

const processTemperatureInput = (item) => ({
  temperatureChamber: item?.d_temperature_chamber / 100 || '00.00',
  time: item?.SYST,
})

const processSensorInput = (item) => ({
  airGapPump: item?.o_pwm_airgap_pump || 0,
  countRpmSignalsTopArm: item?.i_rpm_top_arm_640ms || 0,
  countRpmSignalsBottomArm: item?.i_rpm_bottom_arm_640ms || 0,
  circulationPump: item?.o_pwm_circulation_pump || 0,
  dryerMotor: item?.o_pwm_dryer_motor || 0,
  fanLcd: item?.o_pwm_fan_lcd || 0,
  fanPcb: item?.o_pwm_fan_pcb || 0,
  time: item?.SYST || null,
})

const processSensorOutput = (item) => ({
  airGapSwitchLow: item.i_sw_airgap_low ? { key: 'ON', value: 10 } : { key: 'OFF', value: 5 },
  airGapSwitchFull: item.i_sw_airgap_full ? { key: 'ON', value: 20 } : { key: 'OFF', value: 15 },
  airGapSwitchOverflow: item.i_sw_airgap_overflow ? { key: 'ON', value: 30 } : { key: 'OFF', value: 25 },
  airFilterHepa: item.i_sw_air_filter_hepa ? { key: 'ON', value: 40 } : { key: 'OFF', value: 35 },
  chamberOverflow: item.i_sw_chamber_overflow ? { key: 'ON', value: 50 } : { key: 'OFF', value: 45 },
  chamberFull: item.i_sw_chamber_full ? { key: 'ON', value: 60 } : { key: 'OFF', value: 55 },
  chamberLow: item.i_sw_chamber_low ? { key: 'ON', value: 70 } : { key: 'OFF', value: 65 },
  chemicalReservoirSwitch: item.i_sw_chemical_reservoir_full ? { key: 'ON', value: 80 } : { key: 'OFF', value: 75 },
  dryerHeater: item.o_dryer_heater ? { key: 'ON', value: 90 } : { key: 'OFF', value: 85 },
  doorPosition: item.i_sw_door_position ? { key: 'ON', value: 100 } : { key: 'OFF', value: 95 },
  dosingBellowsPump: item.o_dosing_bellows_pump ? { key: 'ON', value: 110 } : { key: 'OFF', value: 105 },
  dosingValidation: item.i_sw_dosing_validation ? { key: 'ON', value: 120 } : { key: 'OFF', value: 115 },
  dosingFillingPump: item.o_dosing_filling_pump ? { key: 'ON', value: 130 } : { key: 'OFF', value: 125 },
  evacuationPump: item.o_evacuation_pump ? { key: 'ON', value: 140 } : { key: 'OFF', value: 135 },
  lcsAirPressureSwitch: item.i_sw_lcs_air_pressure ? { key: 'ON', value: 150 } : { key: 'OFF', value: 145 },
  lcsPresent: item.i_sw_lcs_present ? { key: 'ON', value: 160 } : { key: 'OFF', value: 155 },
  rinseAidSwitch: item.i_sw_rinse_aid_level ? { key: 'ON', value: 170 } : { key: 'OFF', value: 165 },
  saltReservoirSwitch: item.i_sw_salt ? { key: 'ON', value: 180 } : { key: 'OFF', value: 175 },
  valveLcs: item.o_valve_lcs ? { key: 'ON', value: 190 } : { key: 'OFF', value: 185 },
  valveCondenser: item.o_valve_condenser ? { key: 'ON', value: 200 } : { key: 'OFF', value: 195 },
  valveColdWater: item.o_valve_cold_water ? { key: 'ON', value: 210 } : { key: 'OFF', value: 205 },
  valveHotWater: item.o_valve_hot_water ? { key: 'ON', value: 220 } : { key: 'OFF', value: 215 },
  valveAirGap: item.o_valve_airgap ? { key: 'ON', value: 230 } : { key: 'OFF', value: 225 },
  valveRinseAid: item.o_valve_rinse_aid ? { key: 'ON', value: 240 } : { key: 'OFF', value: 235 },
  valveRoWater: item.o_valve_ro_water ? { key: 'ON', value: 250 } : { key: 'OFF', value: 245 },
  valveSalt: item.o_valve_salt ? { key: 'ON', value: 260 } : { key: 'OFF', value: 255 },
  waterHeater: item.o_water_heater ? { key: 'ON', value: 270 } : { key: 'OFF', value: 265 },
  waterLeak: item.i_sw_water_leak ? { key: 'ON', value: 280 } : { key: 'OFF', value: 275 },
  time: item.SYST || null,
})

const DebugScreen = (props) => {
  const { rawData, deviceModel, rawHistoricInput, width } = props
  const wdDevice = deviceModel === 'HYDRIM 112WD G4+'
  const { t } = useTranslation()
  const [data, setData] = useState({})
  const [tempChartInput, setTempChartInput] = useState([])
  const [sensorChartInput, setSensorChartInput] = useState([])
  const [sensorChartOutput, setSensorChartOutput] = useState([])
  const [lastWaterValveOpen, setLastWaterValveOpen] = useState(0)
  const [temperatureImage, setTemperatureImage] = useState(TempOff)
  const [waterValveImage, setWaterValveImage] = useState(WaterValvesOff)
  const [airGapValveImage, setAirGapValveImage] = useState(AirGapValveSoftenerOff)
  const [saltReservoirSwitchImage, setSaltReservoirSwitchImage] = useState(SaltReservoirSwitchOff)
  const [saltReservoirValveImage, setSaltReservoirValveImage] = useState(SaltReservoirValveOff)
  const [airGapPumpImage, setAirGapPumpImage] = useState(wdDevice ? AirGapPumpConnOffValveOff : AirGapPumpConnOff)
  const [airGapSwitchImage, setAirGapSwitchImage] = useState(airGapSwitchEmpty)
  const [roWaterValveImage, setRoWaterValveImage] = useState(roValveOff)
  const [drainPumpImage, setDrainPumpImage] = useState(drainPumpOffCondenserOff)
  const [circulationPumpImage, setCirculationPumpImage] = useState(circulationPumpOff)
  const [airValveImage, setAirValveImage] = useState(airValveOff)
  const [airTankImage, setAirTankImage] = useState(airTankOff)
  const [waterConnectionImage, setWaterConnectionImage] = useState(waterConnectionOff)
  const [lcsSystemImage, setLcsSystemImage] = useState(lcsNotConnected)
  const [sumpImage, setSumpImage] = useState(chamberEmpty)
  const [sumpHeaterImage, setSumpHeaterImage] = useState(waterHeaterOff)
  const [rinseAidValveImage, setRinseAidValveImage] = useState(rinseAidValveOff)
  const [rinseAidImage, setRinseAidImage] = useState(rinseAidOff)
  const [bellowsPumpImage, setBellowsPumpImage] = useState(dosingBellowsPumpOff)
  const [bellowsPumpCountdown, setBellowsPumpCountdown] = useState(0)
  const [dosingFillingPumpImage, setDosingFillingPumpImage] = useState(dosingFillingPumpOff)
  const [detergentReservoirImage, setDetergentReservoirImage] = useState(detergentReservoirOff)
  const [phasePrimary, setPhasePrimary] = useState('')
  const [cycleID, setCycleID] = useState('')
  const [currentLCDFrame, setCurrentLCDFrame] = useState(FanOff)
  const [currentPCBFrame, setCurrentPCBFrame] = useState(FanOff)
  const [elapsedTime, setElapsedTime] = useState('')
  const [remainingTime, setRemainingTime] = useState('')
  const [machineState, setMachineState] = useState('')
  const [machineAbstractState, setMachineAbstractState] = useState(LedOff)
  const [rpmSignalsBottom, setRpmSignalsBottom] = useState(Array(10).fill(0))
  const [rpmSignalsTop, setRpmSignalsTop] = useState(Array(10).fill(0))
  const [previousRPMSignalTop, setPreviousRPMSignalTop] = useState(0)
  const [previousRPMSignalBottom, setPreviousRMPSignalBottom] = useState(0)

  useEffect(() => {
    setData(dataParsing(rawData))
  }, [rawData])

  useEffect(() => {
    if (rawHistoricInput.length > 0) {
      const processedData = rawHistoricInput.map((item) => ({
        temperature: processTemperatureInput(item),
        sensorInput: processSensorInput(item),
        sensorOutput: processSensorOutput(item),
      }))

      const tempChartInput = []
      const sensorChartInput = []
      const sensorChartOutput = []

      processedData.forEach((data) => {
        tempChartInput.push(data.temperature)
        sensorChartInput.push(data.sensorInput)
        sensorChartOutput.push(data.sensorOutput)
      })

      setTempChartInput(tempChartInput)
      setSensorChartInput(sensorChartInput)
      setSensorChartOutput(sensorChartOutput)
    }
  }, [rawHistoricInput])

  useEffect(() => {
    handleCycleType()
  }, [data.cycleId])

  useEffect(() => {
    handlePhaseChange()
  }, [data.cyclePhasePrimary])

  useEffect(() => {
    updateElapsed()
  }, [data.cycleElapsedTime])

  useEffect(() => {
    updateRemaining()
  }, [data.primaryPhaseRemainingTime])

  useEffect(() => {
    updateState()
  }, [data.machineActionState])

  useEffect(() => {
    updateAbstractState()
  }, [data.machineAbstractState])

  useEffect(() => {
    const temperature = data.temperatureChamber

    if (temperature === 0) {
      setTemperatureImage(TempOff)
      return
    }

    if (temperature < 50) {
      setTemperatureImage(TempCold)
      return
    }

    if (temperature < 70) {
      setTemperatureImage(TempWarm)
      return
    }

    setTemperatureImage(TempHot)
  }, [data.temperatureChamber])

  useEffect(() => {
    let lcdInterval

    if (data.fanLcd <= 0) {
      setCurrentLCDFrame(FanOff)
      return
    }

    let frame = 1
    const animationSpeed = 400

    lcdInterval = setInterval(() => {
      setCurrentLCDFrame(frame === 1 ? FanFrame1 : FanFrame2)
      frame = frame === 1 ? 2 : 1
    }, animationSpeed)

    return () => clearInterval(lcdInterval)
  }, [data.fanLcd])

  useEffect(() => {
    let pcbInterval

    if (data.fanPcb <= 0) {
      setCurrentPCBFrame(FanOff)
      return
    }

    let frame = 1
    const animationSpeed = 400

    pcbInterval = setInterval(() => {
      setCurrentPCBFrame(frame === 1 ? FanFrame1 : FanFrame2)
      frame = frame === 1 ? 2 : 1
    }, animationSpeed)

    return () => clearInterval(pcbInterval)
  }, [data.fanPcb])

  useEffect(() => {
    const bottomIntervalId = setInterval(() => {
      const newSignal = previousRPMSignalBottom + data.countRpmSignalsBottomArm
      const validSignal = Number.isFinite(newSignal) ? newSignal : 0
      setPreviousRMPSignalBottom(data.countRpmSignalsBottomArm)

      setRpmSignalsBottom((prevSignals) => {
        const updatedSignals = [...prevSignals.slice(1), validSignal]
        return updatedSignals
      })
    }, 1000)
    return () => clearInterval(bottomIntervalId)
  }, [previousRPMSignalBottom, data.countRpmSignalsBottomArm])

  useEffect(() => {
    const topIntervalId = setInterval(() => {
      const newSignal = previousRPMSignalTop + data.countRpmSignalsTopArm
      const validSignal = Number.isFinite(newSignal) ? newSignal : 0
      setPreviousRPMSignalTop(data.countRpmSignalsTopArm)

      setRpmSignalsTop((prevSignals) => {
        const updatedSignals = [...prevSignals.slice(1), validSignal]
        return updatedSignals
      })
    }, 1000)
    return () => clearInterval(topIntervalId)
  }, [previousRPMSignalTop, data.countRpmSignalsTopArm])

  useEffect(() => {
    if (data.valveHotWater) {
      setLastWaterValveOpen(1)
    }

    if (data.valveColdWater) {
      setLastWaterValveOpen(0)
    }

    updateWaterValves()
  }, [data.valveHotWater, data.valveColdWater])

  useEffect(() => {
    setAirGapValveImage(data.valveAirGap ? AirGapValveSoftenerOn : AirGapValveSoftenerOff)
  }, [data.valveAirGap])

  useEffect(() => {
    setSaltReservoirSwitchImage(data.saltReservoirSwitch ? SaltReservoirSwitchOn : SaltReservoirSwitchOff)
  }, [data.saltReservoirSwitch])

  useEffect(() => {
    setSaltReservoirValveImage(data.valveSalt ? SaltReservoirValveOn : SaltReservoirValveOff)
  }, [data.valveSalt])

  useEffect(() => {
    updateAirGapPump()
  }, [data.airGapPump, data.valveCondenser])

  useEffect(() => {
    updateAirGapSwitch()
    setRoWaterValveImage(data.valveRoWater ? roValveOn : roValveOff)
    setDrainPumpImage(data.evacuationPump ? drainPumpOnCondenserOn : data.valveCondenser ? drainPumpOffCondenserOn : drainPumpOffCondenserOff)
    setCirculationPumpImage(data.circulationPump > 0 ? circulationPumpOn : circulationPumpOff)
    setAirValveImage(data.valveLcs ? airValveOn : airValveOff)
  }, [data])

  useEffect(() => {
    setAirTankImage(data.lcsAirPressureSwitch && data.valveLcs ? airTankOn : airTankOff)
  }, [data.lcsAirPressureSwitch, data.valveLcs])

  useEffect(() => {
    setWaterConnectionImage(data.circulationPump > 0 ? waterConnectionOn : waterConnectionOff)
  }, [data.circulationPump])

  useEffect(() => {
    if (!data.lcsPresent) {
      setLcsSystemImage(lcsNotConnected)
      return
    }

    if (data.lcsInstrumentsConnected) {
      if (data.valveLcs) {
        setLcsSystemImage(lcsAirConnected)
        return
      }
      if (data.circulationPump > 0) {
        setLcsSystemImage(lcsPumpOn)
        return
      }
      setLcsSystemImage(lcsAirWaterOff)
    } else {
      setLcsSystemImage(data.circulationPump > 0 ? lcsNotInUsePumpOn : lcsNotInUsePumpOff)
    }
  }, [data.lcsPresent, data.lcsInstrumentsConnected, data.circulationPump, data.valveLcs])

  useEffect(() => {
    const isCold = data.temperatureChamber < 50

    if (data.chamberOverflow) {
      setSumpImage(isCold ? chamberOverflowCold : chamberOverflowHot)
      return
    }

    if (data.chamberFull) {
      setSumpImage(isCold ? chamberFullCold : chamberFullHot)
      return
    }

    if (data.chamberLow) {
      setSumpImage(isCold ? chamberLowCold : chamberLowHot)
      return
    }

    setSumpImage(chamberEmpty)
  }, [data.chamberOverflow, data.chamberFull, data.chamberLow, data.temperatureChamber])

  useEffect(() => {
    setSumpHeaterImage(data.waterHeater ? waterHeaterOn : waterHeaterOff)
  }, [data.waterHeater])

  useEffect(() => {
    setRinseAidValveImage(data.valveRinseAid ? rinseAidValveOn : rinseAidValveOff)
  }, [data.valveRinseAid])

  useEffect(() => {
    setRinseAidImage(data.rinseAidSwitch ? rinseAidOn : rinseAidOff)
  }, [data.rinseAidSwitch])

  useEffect(() => {
    setBellowsPumpImage(data.dosingBellowsPump ? dosingBellowsPumpOn : dosingBellowsPumpOff)
    setBellowsPumpCountdown(data.dosingBellowsPumpCountdown)
  }, [data.dosingBellowsPump, data.dosingBellowsPumpCountdown])

  useEffect(() => {
    setDosingFillingPumpImage(data.dosingFillingPump ? dosingFillingPumpOn : dosingFillingPumpOff)
  }, [data.dosingFillingPump])

  useEffect(() => {
    setDetergentReservoirImage(data.chemicalReservoirSwitch ? detergentReservoirOn : detergentReservoirOff)
  }, [data.chemicalReservoirSwitch])

  // Screen 2
  useEffect(() => {
    if (Object.keys(data).length) {
      document.getElementById('TCH_Value').textContent = data.TCH
      document.getElementById('TCHV_Value').textContent = data.TCHV
      document.getElementById('TDRY_Value').textContent = data.TDRY
      document.getElementById('RPMD_Value').textContent = data.RPMD
      document.getElementById('RPMT_Value').textContent = data.RPMT
      document.getElementById('RPMB_Value').textContent = data.RPMB
      document.getElementById('PTAR_Value').textContent = data.PTAR
      document.getElementById('PBAR_Value').textContent = data.PBAR
      document.getElementById('CW_Value').textContent = data.CW
      document.getElementById('PCL_Value').textContent = data.PCL
      document.getElementById('TIO_Value').textContent = data.TIO
      document.getElementById('TLCD_Value').textContent = data.TLCD
      document.getElementById('A0_Value').textContent = data.A0
      document.getElementById('CPCD_Value').textContent = data.CPCD
      // document.getElementById('MLC_Value').textContent = data.MLC
      document.getElementById('FROW_Value').textContent = data.FROW
      document.getElementById('FCHW_Value').textContent = data.FCHW
      document.getElementById('FLCS_Value').textContent = data.FLCS
      document.getElementById('PPAG_Value').textContent = data.PPAG
      document.getElementById('PFIO_Value').textContent = data.PFIO
      document.getElementById('PFLC_Value').textContent = data.PFLC
      document.getElementById('PDRY_Value').textContent = data.PDRY
      document.getElementById('PPCI_Value').textContent = data.PPCI
      document.getElementById('LEDR_Value').textContent = data.LEDR
      document.getElementById('LEDG_Value').textContent = data.LEDG
      document.getElementById('LEDB_Value').textContent = data.LEDB
    }
  }, [data])

  useEffect(() => {
    Object.entries(svgComponentConditions).forEach(([id, { attribute, activeCondition }]) => {
      const condition = data[attribute] > 0 ? activeCondition : 'default'

      const rects = document.querySelectorAll(`#${id} rect`)
      const texts = document.querySelectorAll(`#${id} text`)

      if (rects.length > 0 && texts.length > 0 && colorConditions[condition]) {
        const colors = colorConditions[condition]

        rects.forEach((rect) => {
          rect.setAttribute('fill', colors.rectFill)
          rect.setAttribute('stroke', colors.rectStroke)
        })

        texts.forEach((text) => {
          text.setAttribute('fill', colors.textFill)
        })
      }
    })
  }, [data])

  const celsiusToFahrenheit = (celsius) => (celsius * 9) / 5 + 32

  const modifyString = (input) => {
    return input?.replace(/_/g, ' ').split(' ').slice(1).join(' ') || ''
  }

  const handleCycleType = () => {
    const value = data.cycleId
    if (value) {
      setCycleID(value.split('_').pop())
    }
  }

  const handlePhaseChange = () => {
    setPhasePrimary(modifyString(data.cyclePhasePrimary))
  }

  const updateElapsed = () => {
    const elapsed = data.cycleElapsedTime
    const minutes = Math.floor(elapsed / 60000)
    const seconds = ('0' + Math.floor((elapsed / 1000) % 60)).slice(-2)
    setElapsedTime(minutes + ':' + seconds)
  }

  const updateRemaining = () => {
    const remaining = data.primaryPhaseRemainingTime
    const minutes = Math.floor(remaining / 60000)
    const seconds = ('0' + Math.floor((remaining / 1000) % 60)).slice(-2)
    setRemainingTime(minutes + ':' + seconds)
  }

  const updateState = () => {
    setMachineState(modifyString(data.machineActionState))
  }

  const updateAbstractState = () => {
    switch (data.machineAbstractState) {
      case 6:
        setMachineAbstractState(LedCycleComplete)
        break
      case 9:
        setMachineAbstractState(LedCycleStopped)
        break
      case 5:
        setMachineAbstractState(LedCycleRunning)
        break
      case 2:
        setMachineAbstractState(LedStandby)
        break
      default:
        setMachineAbstractState(LedOff)
    }
  }

  const displayTemperature = () => {
    if (data.temperatureUnit === 'F') {
      return Math.floor(celsiusToFahrenheit(data.temperatureChamber))
    }
    return Math.floor(celsiusToFahrenheit(data.temperatureChamber))
  }

  const displayDecimal = () => {
    let temp = data.temperatureChamber
    if (data.temperatureUnit === 'F') {
      temp = celsiusToFahrenheit(data.temperatureChamber)
    }
    if (Number.isInteger(temp)) {
      return '.0'
    }
    return '.' + temp?.toFixed(1).split('.')[1][0]
  }

  const doorImage = data.doorPosition ? DoorClosed : DoorOpened

  const leakSensorVisible = data.waterLeak ? LeakSensor : null

  const blowerImage = data.dryerHeater
    ? BlowerHighHeaterOn
    : data.dryerMotor >= 100
      ? BlowerHighHeaterOff
      : data.dryerMotor > 0
        ? BlowerLowHeaterOff
        : BlowerOffHeaterOff

  const blowerFanFrame = data.dryerMotor > 0 ? FanBlowerFrame1 : FanBlowerFrame2

  const condenserImage = data.valveCondenser ? (data.dryerHeater ? CondenserOnHeaterOn : CondenserOnHeaterOff) : CondenserOffHeaterOff

  const hepaImage = data.airFilterHepa ? HepaOn : HepaOff

  const getWasherArmTopImage = () => {
    const { circulationPump, pressureTopArm, temperatureChamber } = data

    if (circulationPump < 1) {
      return WasherArmTopOff
    }

    if (pressureTopArm === 0) {
      return temperatureChamber < 50 ? WasherArmTopNoPressureCold : WasherArmTopNoPressureHot
    }

    if (pressureTopArm < 700) {
      return temperatureChamber < 50 ? WasherArmTopLowPressureCold : WasherArmTopLowPressureHot
    }

    return temperatureChamber < 50 ? WasherArmTopHighPressureCold : WasherArmTopHighPressureHot
  }

  const getWasherArmBottomImage = () => {
    if (data.circulationPump < 1) {
      return WasherArmBottomOff
    }

    if (data.pressureBottomArm === 0) {
      return data.temperatureChamber < 50 ? WasherArmBottomNoPressureCold : WasherArmBottomNoPressureHot
    }

    if (data.pressureBottomArm < 110) {
      return data.temperatureChamber < 50 ? WasherArmBottomLowPressureCold : WasherArmBottomLowPressureHot
    }

    return data.temperatureChamber < 50 ? WasherArmBottomHighPressureCold : WasherArmBottomHighPressureHot
  }

  const rpmSignalsBottomImages = rpmSignalsBottom.map((signal) => {
    switch (signal) {
      case 0:
        return RpmMonitor0
      case 1:
        return RpmMonitor1
      case 2:
        return RpmMonitor2
      default:
        return RpmMonitor2Plus
    }
  })

  const rpmSignalsTopImages = rpmSignalsTop.map((signal) => {
    switch (signal) {
      case 0:
        return RpmMonitor0
      case 1:
        return RpmMonitor1
      case 2:
        return RpmMonitor2
      default:
        return RpmMonitor2Plus
    }
  })

  const updateWaterValves = () => {
    if (data.valveHotWater && data.valveColdWater) {
      setWaterValveImage(WaterValvesColdAndHotOn)
      return
    }

    if (data.valveHotWater) {
      setWaterValveImage(WaterValvesHotOn)
      return
    }

    if (data.valveColdWater) {
      setWaterValveImage(WaterValvesColdOn)
      return
    }

    setWaterValveImage(WaterValvesOff)
  }

  const updateAirGapPump = () => {
    const pumpOn = data.airGapPump > 0
    const valveOn = data.valveCondenser

    if (pumpOn) {
      setAirGapPumpImage(wdDevice ? (valveOn ? AirGapPumpConnOnValveOn : AirGapPumpConnOnValveOff) : AirGapPumpConnOn)
      return
    }

    setAirGapPumpImage(wdDevice ? AirGapPumpConnOffValveOff : AirGapPumpConnOff)
  }

  const updateAirGapSwitch = () => {
    const isHotWater = data.valveHotWater || lastWaterValveOpen === 1

    if (data.airGapSwitchOverflow) {
      setAirGapSwitchImage(isHotWater ? airGapSwitchOverflowHotWater : airGapSwitchOverflowColdWater)
      return
    }

    if (data.airGapSwitchFull) {
      setAirGapSwitchImage(isHotWater ? airGapSwitchFullHotWater : airGapSwitchFullColdWater)
      return
    }

    if (data.airGapSwitchLow) {
      setAirGapSwitchImage(isHotWater ? airGapSwitchLowHotWater : airGapSwitchLowColdWater)
      return
    }

    setAirGapSwitchImage(airGapSwitchEmpty)
  }

  //screen 2
  const colorConditions = {
    waterSystemActive: { rectFill: '#121417', rectStroke: '#00b7ff', textFill: '#F1F2F2' },
    dryerSystemActive: { rectFill: '#121417', rectStroke: '#FF5240', textFill: '#F1F2F2' },
    lumensSystemActive: { rectFill: '#121417', rectStroke: '#858FFF', textFill: '#F1F2F2' },
    detergentSystemActive: { rectFill: '#121417', rectStroke: '#E9CC63', textFill: '#F1F2F2' },
    operationalSystemActive: { rectFill: '#121417', rectStroke: '#FFFFFF', textFill: '#F1F2F2' },
    // default: { rectFill: '#121417', rectStroke: '#6d6e71', textFill: '#6d6e71' },
  }

  const svgComponentConditions = {
    'SCHO-Default': { attribute: 'chamberOverflow', activeCondition: 'waterSystemActive' },
    'SCHE-Default': { attribute: 'chamberLow', activeCondition: 'waterSystemActive' },
    'SCHF-Default': { attribute: 'chamberFull', activeCondition: 'waterSystemActive' },
    'STAR-Default': { attribute: 'countRpmSignalsTopArm', activeCondition: 'waterSystemActive' },
    'SBAR-Default': { attribute: 'countRpmSignalsBottomArm', activeCondition: 'waterSystemActive' },

    'SSR-Default': { attribute: 'saltReservoirSwitch', activeCondition: 'waterSystemActive' },
    'SAGE-Default': { attribute: 'airGapSwitchLow', activeCondition: 'waterSystemActive' },
    'SAGF-Default': { attribute: 'airGapSwitchFull', activeCondition: 'waterSystemActive' },
    'SAGO-Default': { attribute: 'airGapSwitchOverflow', activeCondition: 'waterSystemActive' },
    'SAFP-Default': { attribute: 'airFilterHepa', activeCondition: 'dryerSystemActive' },

    'SRAL-Default': { attribute: 'rinseAidSwitch', activeCondition: 'detergentSystemActive' },
    'SCRL-Default': { attribute: 'chemicalReservoirSwitch', activeCondition: 'detergentSystemActive' },
    'SPCD-Default': { attribute: 'dosingValidation', activeCondition: 'detergentSystemActive' },
    'SLCA-Default': { attribute: 'lcsAirPressureSwitch', activeCondition: 'lumensSystemActive' },
    'SLCS-Default': { attribute: 'lcsPresent', activeCondition: 'lumensSystemActive' },

    'SDL-Default': { attribute: 'doorPosition', activeCondition: 'operationalSystemActive' },
    'SWL-Default': { attribute: 'waterLeak', activeCondition: 'operationalSystemActive' },

    'VAG-Default': { attribute: 'valveAirGap', activeCondition: 'waterSystemActive' },
    'VCON-Default': { attribute: 'valveCondenser', activeCondition: 'waterSystemActive' },
    'VLCS-Default': { attribute: 'valveLcs', activeCondition: 'lumensSystemActive' },

    'VCW-Default': { attribute: 'valveColdWater', activeCondition: 'waterSystemActive' },
    'VSR-Default': { attribute: 'valveSalt', activeCondition: 'waterSystemActive' },
    'VRAD-Default': { attribute: 'valveRinseAid', activeCondition: 'detergentSystemActive' },

    'VHW-Default': { attribute: 'valveHotWater', activeCondition: 'waterSystemActive' },
    'VROW-Default': { attribute: 'valveRoWater', activeCondition: 'waterSystemActive' },

    'PCIRC-Default': { attribute: 'circulationPump', activeCondition: 'waterSystemActive' },
    'PDRA-Default': { attribute: 'evacuationPump', activeCondition: 'waterSystemActive' },
    'PAG-Default': { attribute: 'airGapPump', activeCondition: 'waterSystemActive' },

    'PCF-Default': { attribute: 'dosingFillingPump', activeCondition: 'detergentSystemActive' },
    'PCD-Default': { attribute: 'dosingBellowsPump', activeCondition: 'detergentSystemActive' },

    'FLCD-Default': { attribute: 'fanLcd', activeCondition: 'operationalSystemActive' },
    'FIO-Default': { attribute: 'fanPcb', activeCondition: 'operationalSystemActive' },
    'BDRY-Default': { attribute: 'dryerMotor', activeCondition: 'dryerSystemActive' },
    'HD-Default': { attribute: 'dryerHeater', activeCondition: 'dryerSystemActive' },
    'HW-Default': { attribute: 'waterHeater', activeCondition: 'waterSystemActive' },
  }

  return (
    <div className="debug-screen">
      <div className="hydrim-screen-1">
        <div className="debug-screen1">
          {cycleID && <div className="debug-name">{cycleID}</div>}

          <div className="primary-phase">{phasePrimary}</div>

          <div className="action-state">{machineState}</div>

          <div className="timing-info">
            <div className="row">
              <img src={TimeElapsedIcon} alt="Elapsed Time" className="icon" />
              <span className="time-text">{elapsedTime}</span>
            </div>

            <div className="row">
              <img src={TimeRemainingIcon} alt="Remaining Time" className="icon" />
              <span className="time-text">{remainingTime}</span>
            </div>
          </div>

          <div className="lcd-text">LCD</div>
          <div className="pcb-text">PCB</div>

          <img src={machineAbstractState} alt="Machine State" className="machine-state-image" />

          {/* Fan LCD */}
          <div className="fan-lcd">
            <img src={currentLCDFrame} alt="Fan LCD" />
          </div>

          {/* Fan PCB */}
          <div className="fan-pcb">
            <img src={currentPCBFrame} alt="Fan PCB" />
          </div>

          {/* Door Position */}
          <div className="door-position">
            <img src={doorImage} alt="Door Position" />
          </div>

          {/* Leak Sensor */}
          {leakSensorVisible && (
            <div className="leak-sensor">
              <img src={leakSensorVisible} alt="Leak Sensor" />
            </div>
          )}

          {/* Blower */}
          <div className="blower">
            <img src={blowerImage} alt="Blower Status" />
            <img src={blowerFanFrame} alt="Blower Fan Frame" />
          </div>

          {/* Condenser */}
          {wdDevice && (
            <div className="condenser">
              <img src={condenserImage} alt="Condenser Status" />
            </div>
          )}

          {/* HEPA Filter */}
          <div className="hepa-filter">
            <img src={hepaImage} alt="HEPA Filter Status" />
          </div>

          {/* RPM Monitor */}
          <div className="rpm-top-text">
            <span className="rpm-value">{data.rpmTopArmFiltered}</span>
            <span className="rpm-label">RPM</span>
          </div>

          <div className="rpm-monitor">
            {rpmSignalsTopImages.map((imageSrc, index) => (
              <img key={index} src={imageSrc} alt={`RPM Signal ${index}`} />
            ))}
          </div>

          {/* Washer Box */}
          <div className="washer-box">
            <img src={WasherBox} alt="Washer Box" />
          </div>

          <div className="washer-arm-top">
            <img src={getWasherArmTopImage()} alt="Washer Arm Top" />
          </div>

          <div className="washer-arm-bottom">
            <img src={getWasherArmBottomImage()} alt="Washer Arm Bottom" />
          </div>

          {/* RPM Bottom */}
          <div className="rpm-bottom-text">
            <span className="rpm-value">{data.rpmBottomArmFiltered}</span>
            <span className="rpm-label">RPM</span>
          </div>
          <div className="rpm-bottom-separator" />

          {/* RPM Monitor Bottom */}
          <div className="rpm-monitor-bottom">
            {rpmSignalsBottomImages.map((imageSrc, index) => (
              <img key={index} src={imageSrc} alt={`RPM Signal ${index}`} />
            ))}
          </div>

          {/* Chamber Temperature */}
          <img src={temperatureImage} alt="Chamber Temperature" className="chamber-temperature-img" />
          <div className="chamber-temperature-text">
            <span>{displayTemperature()}</span>
            <span className="chamber-decimal">{displayDecimal()}</span>
            <span className="degree-symbol">°</span>
            <span className="temp-unit">{data.temperatureUnit === 'F' ? 'F' : 'C'}</span>
          </div>

          <div className="water-valves">
            <img src={waterValveImage} alt="Water Valves" />
          </div>

          <div className="air-gap-valve-softener">
            <img src={airGapValveImage} alt="Air Gap Valve Softener" />
          </div>

          <div className="salt-reservoir-switch">
            <img src={saltReservoirSwitchImage} alt="Salt Reservoir Switch" />
          </div>

          <div className="salt-reservoir-valve">
            <img src={saltReservoirValveImage} alt="Salt Reservoir Valve" />
          </div>

          <div className="air-gap-pump">
            <img src={airGapPumpImage} alt="Air Gap Pump" />
          </div>

          <div className="air-gap-switch">
            <img src={airGapSwitchImage} alt="Air Gap Switch" />
          </div>

          <div className="ro-water-valve">
            <img src={roWaterValveImage} alt="RO Water Valve" />
          </div>

          <div className="drain-pump">
            <img src={drainPumpImage} alt="Drain Pump" />
          </div>

          <div className="circulation-pump">
            <img src={circulationPumpImage} alt="Circulation Pump" />
          </div>

          {wdDevice && (
            <>
              <div className="air-valve-connection">
                <img src={airValveImage} alt="Air Valve Connection" />
              </div>

              <div className="air-tank">
                <img src={airTankImage} alt="Air Tank" />
              </div>

              <div className="water-connection">
                <img src={waterConnectionImage} alt="Water Connection" />
              </div>
            </>
          )}

          {/* LCS System */}
          {wdDevice && (
            <div className="lcs-system">
              <img src={lcsSystemImage} alt="LCS System" />
            </div>
          )}

          {/* Sump */}
          <div className="sump">
            <img src={sumpImage} alt="Sump" />

            {/* Sump Heater */}
            <div className="sump-heater">
              <img src={sumpHeaterImage} alt="Sump Heater" />
            </div>
          </div>

          {/* Rinse Aid Valve */}
          <div className="rinse-aid-valve">
            <img src={rinseAidValveImage} alt="Rinse Aid Valve" />
          </div>

          {/* Rinse Aid */}
          <div className="rinse-aid">
            <img src={rinseAidImage} alt="Rinse Aid" />
          </div>

          {/* Dosing Bellows Pump */}
          <div className="bellows-pump">
            <img src={bellowsPumpImage} alt="Dosing Bellows Pump" />
            {bellowsPumpCountdown > 0 && (
              <div className="bellows-pump-countdown">
                <span>{`00${bellowsPumpCountdown}`.slice(-3)}</span>
              </div>
            )}
          </div>

          {/* Dosing Filling Pump */}
          <div className="dosing-filling-pump">
            <img src={dosingFillingPumpImage} alt="Dosing Filling Pump" />
          </div>

          {/* Detergent Reservoir */}
          <div className="detergent-reservoir">
            <img src={detergentReservoirImage} alt="Detergent Reservoir" />
          </div>

          {/* Detergent Pouch */}
          <div className="detergent-pouch">
            <img src={detergentPouch} alt="Detergent Pouch" />
          </div>
        </div>
      </div>

      {/* Screen 2 */}
      <div className="debug-screen2">{wdDevice ? <WdDeviceScreen2 /> : <WDeviceScreen2 />}</div>

      <div className="charts">
        <div className="temperature-chart-details">
          <div className="chart-title">{`${t('advance_troubleshooting_context.temp_chart')}`}</div>
          <div className="temperature-chart">
            <TemperatureChart data={tempChartInput} width={width} />
          </div>
        </div>

        <div className="input-chart-details">
          <div className="chart-title">{`${t('advance_troubleshooting_context.sensor_input')}`}</div>
          <div className="input-chart">
            <InputChart data={sensorChartInput} width={width} />
          </div>
        </div>

        <div className="output-chart-details">
          <div className="chart-title">{`${t('advance_troubleshooting_context.sensor_output')}`}</div>
          <div className="output-chart">
            <OutputChart data={sensorChartOutput} width={width} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default DebugScreen