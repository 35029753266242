import React, { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import ReactTooltip from "react-tooltip"
import exportFromJSON from "export-from-json"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faDownload, faSearch } from "@fortawesome/free-solid-svg-icons"
import LogTable from "./LogTable"
import Pagination from "../../common/Pagination"
import { toast } from "../../common/Toast"
import LoadingSpinner from "../../common/LoadingSpinner"
import { FilterList, RefreshIcon } from "../../../icons"
import { getEventLog } from "../../../utils/requests/recordsAPI"
import { convertTimeWithTAndZ } from '../../../utils/filters/date'
import "./eventLog.scss"

function EventLog(props) {
  const { t } = useTranslation()
  const PAGE_LIMIT = 25
  const NA = "N/A"

  const [selectedPage, setSelectedPage] = useState(1)

  const [data, setData] = useState([])
  const [filteredData, setFilteredData] = useState([])
  const [showData, setShowData] = useState([])
  const [searchKey, setSearchKey] = useState(null)
  const [showSpinner, setShowSpinner] = useState(false)

  const ref = useRef(null)

  const scrollRef = useRef(null)

  const filterOptions = ["All", "Normal", "Fault", "Interrupted"]
  const [selectedOption, setSelectedOption] = useState(filterOptions[0])

  const [disableButton, setDisableButton] = useState(false)
  const refreshInterval = 30 * 1000

  useEffect(() => {
    const fetchData = () => {
      if (props.serialNumber) {
        getEventLogData(props.serialNumber);
      }
    };
  
    fetchData();
    const intervalId = setInterval(fetchData, refreshInterval);
    return () => clearInterval(intervalId);
  }, [props.serialNumber, refreshInterval]);
  

  useEffect(() => {
    if (filteredData?.length) {
      let offset = Math.ceil((selectedPage - 1) * PAGE_LIMIT)
      const end = offset + PAGE_LIMIT > filteredData.length ? filteredData.length : offset + PAGE_LIMIT
      setShowData(filteredData.slice(offset, end))
      scrollToRef()
    } else {
      setShowData([])
    }
  }, [selectedPage, filteredData])

  useEffect(() => {
    const searchData = SearchData(data)
    const filteredData = filterData(searchData)
    setFilteredData(filteredData)
  }, [data, searchKey, selectedOption])

  const SearchData = (data) => {
    return searchKey ? data.filter((log) => JSON.stringify(log).toLowerCase().includes(searchKey.toLowerCase())) : data
  }

  const filterData = (data) => {
    switch (selectedOption) {
      case "Normal":
        return data.filter((log) => !(log.type_name.toLowerCase().includes("fault") || log.type_name.toLowerCase().includes("interrupted")))
      case "Fault":
        return data.filter((log) => log.type_name.toLowerCase().includes("fault"))
      case "Interrupted":
        return data.filter((log) => log.type_name.toLowerCase().includes("interrupted"))
      default:
        return data
    }
  }

  const getEventLogData = (serNum) => {
    setShowSpinner(true)

    getEventLog(getQueryParam(), serNum)
      .then((data) => {
        setData(data?.events?.length > 0 ? data.events : [])
        setShowSpinner(false)
      })
      .catch((e) => {
        console.log("ERROR", e.message)
        setShowSpinner(false)
      })
  }

  const getQueryParam = () => {
    const queryParam = {}
    queryParam["end-time"] = new Date().toISOString()
    const d = new Date()
    d.setMonth(d.getMonth() - 4)
    queryParam["start-time"] = d.toISOString()
    return queryParam
  }

  const handleSearch = () => {
    setSearchKey(ref.current.value)
  }

  const scrollToRef = () => {
    if (scrollRef && scrollRef.current) {
      scrollRef.current.scrollIntoView()
      document.documentElement.scrollTop = 0
    }
  }

  const notify = () => {
    toast.info(t("enhanced_event_log.download_in_progress"), { autoClose: false })
  }

  const update = () => {
    toast.success(t("enhanced_event_log.download_completed"), { autoClose: 10000 })
  }

  const error = () => {
    toast.error(t("enhanced_event_log.download_error"), { autoClose: 10000 })
  }

  const download = async () => {
    setDisableButton(true)

    if (!disableButton) {
      try {
        notify()
        await getAllEvents()
        update()
      } catch {
        error()
      }
    }
  }
  
  const refresh = async () => {
    try {
      getEventLogData(props.serialNumber)
    } catch {
      toast.error(t("error.something_wrong"))
    }
  }

  async function getAllEvents() {
    const results = []

    try {
      const result = await getEventLog(getQueryParam(), props.serialNumber)
      result?.events.forEach((log) => results.push(rawDataProcess(log)))

      convertToExcel(results)
    } catch (e) {
      console.log("ERROR", e.message)
      throw t("enhanced_event_log.download_error")
    } finally {
      setDisableButton(false)
    }
  }

  const rawDataProcess = (log) => {
    return {
      dateTime: log.date ? convertTimeWithTAndZ(log.date) : NA,
      machine: log.serialNumber || NA,
      device: log.model || NA,
      eventType: log.cycleEvent || NA,
      description: log.description || NA,
      cycleNumber: log.cycleNumber || NA,
    }
  }

  const convertToExcel = (data) => {
    const fileName = "event_log"
    const exportType = "xls"
    return exportFromJSON({ data, fileName, exportType })
  }

  return (
    <div className={`event-log-wrapper`}>
      <div className={"event-log-table-utils"}>
        <div></div>
        <div className="filters-wrapper">
          <div className={"sort-filter"}>
            <div className={"sort-filter-icon"}>
              <input ref={ref} className={"search"} placeholder={"Search"} />
              <FontAwesomeIcon icon={faSearch} onClick={handleSearch} className={"search-button"} />
            </div>
          </div>
          <div className={"sort-filter"}>
            <div className={"sort-filter-icon"}>
              <FilterList />
              <div>Filter by:</div>
            </div>
            <select className={"sort-filter-select"} value={selectedOption} onChange={(e) => setSelectedOption(e.target.value)}>
              {filterOptions.map((opt, idx) => (
                <option className={"option"} key={idx}>
                  {opt}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
      <div className={"event-log-table-legend"}>
        <div className={"event-log-table-legend-left"}>
          <div className={"event-log-table-legend-fault"}></div>
          <div className={"event-log-table-legend-text"}>{t("cycle_fault")}</div>
          <div className={"event-log-table-legend-interrupted"}></div>
          <div className={"event-log-table-legend-text"}>{t("tracking.cycle_interrupted")}</div>
        </div>
        <div className="icons">
          <div className={"event-log-table-legend-right"}>
            <RefreshIcon className="refresh-icon" data-tip={t("enhanced_event_log.refresh")} onClick={refresh} />
            <ReactTooltip place="top" type="dark" effect="solid" />
          </div>
          <div className={"event-log-table-legend-right"}>
            <FontAwesomeIcon icon={faDownload} data-tip={t("enhanced_event_log.download_tip")} onClick={download} className={disableButton ? "disabled" : ""} />
            <ReactTooltip place="top" type="dark" effect="solid" />
          </div>
        </div>
      </div>
      <LogTable data={showData} />
      {filteredData.length > PAGE_LIMIT ? (
        <Pagination total={filteredData.length} selected={selectedPage} setSelected={setSelectedPage} perPage={PAGE_LIMIT} />
      ) : (
        <></>
      )}
      {showSpinner && (
        <div className={"spinner-wrapper"}>
          <LoadingSpinner />
        </div>
      )}
    </div>
  )
}

export default EventLog
