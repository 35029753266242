import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { useTranslation } from "react-i18next"
import { useHistory } from 'react-router-dom'
import AddEditProductModal from "./AddEditProductModal"
import Breadcrumb from "../common/Breadcrumb"
import DashboardHeader from "../common/DashboardHeader/DashboardHeader"
import SimpleButton from "../common/SimpleButton"
import SearchWithFilter from "../common/SearchWithFilter"
import ConfirmationModal from "../common/ConfirmationModal"
import EmptyPageResults from "../common/EmptyPageResults"
import LoadingSpinner from "../common/LoadingSpinner"
import GenericTable from "../common/GenericTable"
import ActionsMenu from "../common/ActionsMenu"
import ExpandableText from "../common/ExpandableText"
import FiltersModal, { FilterType } from "../common/FiltersModal"
import { toast } from "../common/Toast"
import {
  loadAdminStoreProducts,
  loadAdminStoreProductCategories,
  delProduct,
  changeProductStatus,
  clearProductsMessages,
  clearNewProduct,
} from "../../actions/adminStore"
import { loadDiscounts, loadTestDrives, loadTrials } from "../../actions/businessRules"
import { loadServices } from "../../actions/services"
import { EnabledIcon, DisabledIcon, MenuKebabVerticalIcon, MenuKebabVerticalBackgroundIcon } from "../../icons"
import { useWindowSize } from "../../libs/hooks"
import { useDeepCompareEffect } from '../../hooks/useDeepCompareEffect'
import { adminStoreDeletePermission } from "../../utils/permissionValidation"
import { capitalizeFirstLetter } from "../../utils/functions"
import "./admin-store.scss"

const AdminStore = (props) => {
  const { t } = useTranslation()
  const history = useHistory()
  const isMobile = useWindowSize()[0] <= 768
  const [storeProductsTableData, setStoreProductsTableData] = useState([])
  const [filterQuery, setFilterQuery] = useState(null)
  const [selectedFilterIsPublished, setSelectedFilterIsPublished] = useState([])
  const [selectedFilterCategories, setselectedFilterCategories] = useState([])
  const [selectedFilterIsDigital, setSelectedFilterIsDigital] = useState([])
  const [shouldApplyFilters, setShouldApplyFilters] = useState(true)
  const [selectedPage, setSelectedPage] = useState(1)
  const [canLoadMore, setCanLoadMore] = useState(false)
  const [editProduct, setEditProduct] = useState(null)
  const [showAddEditProductModal, setShowAddEditProductModal] = useState(false)
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false)
  const [showFiltersModal, setShowFiltersModal] = useState(false)
  const [showSpinner, setShowSpinner] = useState(false)
  const [showActionsMenu, setShowActionsMenu] = useState(null)
  const [storeProductIdToDelete, setStoreProductIdToDelete] = useState(null)
  const [expandedStoreProduct, setExpandedStoreProduct] = useState(0)
  const [newProductDisabled, setNewProductDisabled] = useState(props.newProduct ? true : false)
  const [isFirstLoad, setIsFirstLoad] = useState(true)
  
  useEffect(() => {
    if (props.productCategories.length === 0) {
      props.actions.loadAdminStoreProductCategories()
    }

    props.actions.loadServices({ isEnabled: true }, false, true)
    props.actions.loadTestDrives({ isEnabled: true }, true)
    props.actions.loadTrials({ isEnabled: true }, true)
    // props.actions.loadDiscounts({ isEnabled: true }, true)
  }, [props.actions])

  useEffect(() => {
    if (props.newProduct) {
      const sixtySeconds = 60000
      const newProductCreatedAt = props.newProduct.createdAt.getTime()
      const currentTime = new Date().getTime()

      if (currentTime - newProductCreatedAt > sixtySeconds) {
        props.actions.clearNewProduct()
        return
      }

      setNewProductDisabled(true)

      const timeoutId = setTimeout(() => {
        props.actions.clearNewProduct()
      }, sixtySeconds)
  
      return () => clearTimeout(timeoutId)
    }

    setNewProductDisabled(false)
  },[props.newProduct])

  useEffect(() => {
    const tableData = props.products.map((product, index) => {
      return {
        index,
        identifier: product.id,
        name: product.title,
        partNumber: product.partNumber || "-",
        category: props.productCategories.find((c) => c.id === product.categoryId)?.name || "-",
        isDigital: product.isDigital ? "Yes" : "No",
        isPublished: product.isPublished,
        actions: [
          {
            label: "preview",
            onActionClick: (e) => { e.stopPropagation(); handleNavigateToPreview(product.id) },
          },
          {
            label: "edit",
            onActionClick: (e) => { e.stopPropagation(); handleOpenEditModal(product.id) },
          },
          {
            label: product.isPublished ? "unpublish" : "publish",
            onActionClick: (e) => { e.stopPropagation(); handleChangePublishStatus(product.id, product.isPublished) },
            isDisabled: newProductDisabled && product.id === props.newProduct?.id,
          },
          {
            label: "delete",
            onActionClick: (e) => { e.stopPropagation(); showConfirmationModal(product.id) },
            isDisabled: product.isPublished || (newProductDisabled && product.id === props.newProduct?.id),
            requiredPermission: adminStoreDeletePermission
          },
        ]
      }
    })

    setStoreProductsTableData(tableData)
  }, [props.products, props.productCategories, newProductDisabled])

  useEffect(() => {
    setCanLoadMore(props.canLoadMore)
  }, [props.canLoadMore])

  useEffect(() => {
    setShowSpinner(props.isLoading)
  }, [props.isLoading])

  useEffect(() => {
    if (props.errorMessage) {
      toast.error(props.errorMessage)

      props.actions.clearProductsMessages()
    }
  }, [props.errorMessage])

  useEffect(() => {
    if (props.successMessage) {
      toast.success(props.successMessage)

      props.actions.clearProductsMessages()
    }
  }, [props.successMessage])

  useDeepCompareEffect(() => {
    let changeValueTimeout = window.setTimeout(
      () => {
        if (shouldApplyFilters) {
          setSelectedPage(1)
          handleLoadProducts(true)
        }
        setShouldApplyFilters(true)
      },
      filterQuery ? 1000 : 0
    )
    return () => {
      clearTimeout(changeValueTimeout)
    }
  }, [filterQuery, selectedFilterCategories, selectedFilterIsDigital, selectedFilterIsPublished])

  useEffect(() => {
    if (selectedPage && selectedPage > 1) {
      handleLoadProducts(false)
    }
  }, [selectedPage])

  const handleSearch = (value) => {
    if (value?.length > 2) {
      setShowSpinner(true)
      setFilterQuery(value)
    } else if (value?.length === 0 && filterQuery?.length > 0) {
      setShowSpinner(true)
      setFilterQuery(null)
    }
  }

  const handleLoadMore = () => {
    setSelectedPage((prevValue) => (prevValue ? prevValue + 1 : 2))
  }

  const handleLoadProducts = (withReset) => {
    let queryParams = { startPage: withReset ? 1 : selectedPage }

    if (filterQuery) {
      queryParams = { ...queryParams, title: filterQuery }
    }

    if (selectedFilterIsDigital.length === 1) {
      queryParams = {
        ...queryParams,
        digital: selectedFilterIsDigital.map((filter) => (filter === t("yes") ? true : false)),
      }
    }

    if (selectedFilterIsPublished.length === 1) {
      queryParams = {
        ...queryParams,
        published: selectedFilterIsPublished.map((filter) => (filter === t("published") ? true : false)),
      }
    }

    if (selectedFilterCategories.length > 0) {
      queryParams = {
        ...queryParams,
        categories_id: props.productCategories.filter((category) => selectedFilterCategories.includes(category.name)).map((category) => category.id),
      }
    }

    props.actions.loadAdminStoreProducts(queryParams, withReset)
      .then(() => setIsFirstLoad(false))
  }

  const handleNavigateToPreview = (storeProductId) => {
    history.push({
      pathname: `/preview/${storeProductId}`,
      state: {
        isPreview: true,
      }
    })
  }

  const handleOpenEditModal = (productId) => {
    const storeProduct = props.products.find((product) => product.id === productId)
    
    if (storeProduct) {
      setEditProduct(storeProduct)
      setShowAddEditProductModal(true)
    }
  }

  const showConfirmationModal = (id) => {
    setStoreProductIdToDelete(id)
    setShowDeleteConfirmationModal(true)
  }

  const handleDeleteStoreProduct = (productId) => {
    setShowDeleteConfirmationModal(false)
    props.actions.delProduct(productId)
  }

  const handleChangePublishStatus = (id, isPublished) => {
    props.actions.changeProductStatus(id, !isPublished)
  }

  const handleResetFilters = () => {
    setselectedFilterCategories([])
    setSelectedFilterIsPublished([])
    setSelectedFilterIsDigital([])
  }

  const toggleShowActionsMenu = (index) => {
    setShowActionsMenu(showActionsMenu === index ? null : index)
  }

  return (
    <div className="admin-store-wrapper">
      <Breadcrumb path={window.location.pathname} />
      <DashboardHeader
        headerText={t("nav.admin_store")}
      >
        <SimpleButton 
          className="submit-button" 
          onClick={() => setShowAddEditProductModal(true)}
        >
          {t("coltene_store.admin_store.new-product")}
        </SimpleButton>
      </DashboardHeader>
      <div className="separator" />
      <div className="actions-wrapper">
        <SearchWithFilter
          onSearch={handleSearch}
          showFiltersModal={() => setShowFiltersModal(true)}
          onResetFilters={() => handleResetFilters()}
          showCounterBadge={selectedFilterCategories.length > 0 || selectedFilterIsPublished.length > 0 || selectedFilterIsDigital.length > 0}
          counterBadge={selectedFilterCategories.length + selectedFilterIsPublished.length + selectedFilterIsDigital.length}
        />
      </div>
      {!isMobile && storeProductsTableData.length > 0 &&
        <GenericTable
          data={storeProductsTableData}
          headers={[
            {
              title: t("name")
            },
            {
              title: capitalizeFirstLetter(t("part_number"))
            },
            {
              title: t("category")
            },
            {
              title: t("coltene_store.admin_store.digital")
            },
            {
              title: t("status")
            },
            {
              title: ''
            },
          ]}
          keys={[
            'name',
            'partNumber',
            'category',
            'isDigital',
            'isPublished',
            'action',
          ]}
          keyRenderer={{
            name: (item) => {
              return (
                <div className="name-wrapper">
                  <ExpandableText text={item.name} />
                </div>
              )
            },
            category: (item) => {
              return (
                <div className="category-wrapper">
                  <ExpandableText text={item.category} />
                </div>
              )
            },
            isPublished: (item) => {
              return (
                <div className="status-wrapper">
                  {item.isPublished ?
                    <div className="status-active">
                      <EnabledIcon />
                      <p>{t("published")}</p>
                    </div>
                  : 
                    <div className="status-inactive">
                      <DisabledIcon /> 
                      <p>{t("unpublished")}</p>
                    </div>
                  }
                </div>
              )
            },
            action: (item) => {
              return (
                <div className="table-actions-wrapper" onClick={(e) => { e.stopPropagation(); toggleShowActionsMenu(item["index"]) }}>
                  {showActionsMenu === item["index"] ? <MenuKebabVerticalBackgroundIcon /> : <MenuKebabVerticalIcon />}
                  {showActionsMenu === item["index"] &&
                    <ActionsMenu
                      setHideMenu={() => setShowActionsMenu(null)}
                      actions={item.actions}
                      isLoading={showSpinner}
                    />
                  }
                </div>
              )
            }
          }}
          onRowClick={(storeItem) => handleOpenEditModal(storeItem.identifier)}
        />
      }
      {isMobile && storeProductsTableData.map((storeProduct, index) =>
        <div 
          key={index}
          className="card"
          onClick={() => setExpandedStoreProduct(index)}
        >
          <div className={"card-item" + (expandedStoreProduct !== index ? " align-center" : "")}>
            <div className="card-item-title">{t("name")}</div>
            <div className={"card-item-body" + (expandedStoreProduct !== index ? " align-center" : "")}>
              <div>{storeProduct.name}</div>
              <div 
                className="card-actions"
                onClick={(e) => { e.stopPropagation(); setShowActionsMenu(index) }}
              >
                {showActionsMenu === index ? <MenuKebabVerticalBackgroundIcon /> : <MenuKebabVerticalIcon />}
                {showActionsMenu === index &&
                  <ActionsMenu
                    setHideMenu={() => setShowActionsMenu(null)}
                    actions={storeProduct.actions}
                    isLoading={showSpinner}
                  />
                }
              </div>
            </div>
          </div>
          {expandedStoreProduct === index && (
            <>
              <div className="card-item">
                <div className="card-item-title">{capitalizeFirstLetter(t("part_number"))}</div>
                <div className="card-item-body">{storeProduct.partNumber}</div>
              </div>
              <div className="card-item">
                <div className="card-item-title">{t("category")}</div>
                <div className="card-item-body">{storeProduct.category}</div>
              </div>
              <div className="card-item">
                <div className="card-item-title">{t("coltene_store.admin_store.digital")}</div>
                <div className="card-item-body">{storeProduct.isDigital}</div>
              </div>
              <div className="card-item">
                <div className="card-item-title">{t("status")}</div>
                <div className="card-item-body">
                  <div className="card-item-body-status">
                    {storeProduct.isPublished ? 
                      <div className="status-active">
                        <EnabledIcon /> 
                        <p>{t("published")}</p>
                      </div>
                    : 
                      <div className="status-inactive">
                        <DisabledIcon /> 
                        <p>{t("unpublished")}</p>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </>
          )}
        </div>)
      }
      {!showSpinner && !isFirstLoad && storeProductsTableData.length === 0 &&  (
        <EmptyPageResults 
          title={t("coltene_store.admin_store.no-products")} 
        />
      )}
      {canLoadMore && (
        <div className="buttons-wrapper">
          <SimpleButton className="load-more-button" onClick={() => handleLoadMore()}>
            {t('load_more')}
          </SimpleButton>
        </div>)
      }
      {showFiltersModal && (
        <FiltersModal
          filters={[
            {
              name: t("status"),
              type: FilterType.multiSelect,
              dataset: [t("published"), t("unpublished")],
              input: selectedFilterIsPublished,
              output: (filterStatuses) => {
                setSelectedFilterIsPublished(filterStatuses)
              },
            },
            {
              name: t("coltene_store.admin_store.digital"),
              type: FilterType.multiSelect,
              dataset: [t("yes"), t("no")],
              input: selectedFilterIsDigital,
              output: (filterIsDigital) => {
                setSelectedFilterIsDigital(filterIsDigital)
              },
            },
            {
              name: t("category"),
              type: FilterType.multiSelect,
              dataset: props.productCategories.map((category) => {
                return category.name
              }),
              input: selectedFilterCategories,
              output: (filterCategories) => {
                setselectedFilterCategories(filterCategories)
              },
            },
          ]}
          shouldFilter={(shouldApply) => setShouldApplyFilters(shouldApply)}
          resetFilters={() => handleResetFilters()}
          closeFilters={() => setShowFiltersModal(false)}
        />
      )}
      {showAddEditProductModal && (
        <AddEditProductModal
          editedProduct={editProduct}
          handleClose={() => {
            setShowAddEditProductModal(false)
            setEditProduct(null)
          }}
          navigateToPreview={handleNavigateToPreview}
        />
      )}
      {showDeleteConfirmationModal && (
        <ConfirmationModal
          onToggle={() => setShowDeleteConfirmationModal(false)}
          message={t("coltene_store.admin_store.delete_confirmation")}
          acceptButtonText={t("delete")}
          onCancel={() => setShowDeleteConfirmationModal(false)}
          onAccept={() => handleDeleteStoreProduct(storeProductIdToDelete)}
          isWarning
          requiredPermission={adminStoreDeletePermission}
        />
      )}
      {showSpinner && (
        <div className="spinner-wrapper">
          <LoadingSpinner />
        </div>
      )}
    </div>
  )
}

function stateToProps({ adminStore }) {
  return {
    products: adminStore?.products || [],
    newProduct: adminStore?.newProduct,
    productCategories: adminStore?.productCategories || [],
    isLoading: adminStore?.productsLoading || adminStore?.isLoading || adminStore?.productLoading,
    canLoadMore: adminStore?.canLoadMore,
    errorMessage: adminStore?.errorMessage,
    successMessage: adminStore?.successMessage,
  }
}

function dispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        loadAdminStoreProducts,
        loadAdminStoreProductCategories,
        loadServices,
        loadDiscounts,
        loadTestDrives,
        loadTrials,
        clearProductsMessages,
        clearNewProduct,
        delProduct,
        changeProductStatus,
      },
      dispatch
    ),
  }
}

export default connect(stateToProps, dispatchToProps)(AdminStore)
