import {_baseDeleteFetch, _baseGetFetch, _basePatchFetch, _basePostFetch, _basePutFetch, ApiEnum} from './baseFetch'

const path = "/companies"

export const getAllBranches = (queryParams) => {
  return _baseGetFetch(`${path}/branches`, queryParams,  ApiEnum.admin)
}

export const getAllCompanies = (queryParams) => {
  return _baseGetFetch(`${path}`, queryParams,  ApiEnum.admin)
}

export const addCompany = (bodyParams) => {
  return _basePostFetch(`${path}`, bodyParams,  ApiEnum.admin)
}

export const updateCompany = (companyId, bodyParams) => {
  return _basePutFetch(`${path}/${companyId}`, bodyParams,  ApiEnum.admin)
}

export const verifyCompany = (companyId, bodyParams) => {
  return _basePatchFetch(`${path}/${companyId}/verify`, {bodyParams},  ApiEnum.admin)
}

export const deleteCompany = (companyId) => {
  return _baseDeleteFetch(`${path}/${companyId}`, undefined,  ApiEnum.admin)
}

export const getCompaniesCountryOptions = async () => {
  return _baseGetFetch(`${path}/countries`, undefined, ApiEnum.admin);
}

export const getBranchById = (companyId) => {
  return _baseGetFetch(`${path}/${companyId}/branches`, {},  ApiEnum.admin)
}

export const addBranch = (companyId, bodyParams) => {
  return _basePostFetch(`${path}/${companyId}/branches`, bodyParams,  ApiEnum.admin)
}

export const updateBranch = (companyId, branchId, bodyParams) => {
  return _basePutFetch(`${path}/${companyId}/branches/${branchId}`, bodyParams,  ApiEnum.admin)
}

export const deleteBranch = (companyId, branchId) => {
  return _baseDeleteFetch(`${path}/${companyId}/branches/${branchId}`, undefined,  ApiEnum.admin)
}

export const getBranchesCountryOptions = async () => {
  return _baseGetFetch(`${path}/branches/countries`, undefined, ApiEnum.admin);
}

export const getCompanyLogo = (companyId, branchId) => {
  return _baseGetFetch(`${path}/${companyId}/branches/${branchId}/logo`, undefined, ApiEnum.admin)
}

export const postCompanyLogo = (companyId, branchId, body) => {
  return _basePostFetch(`${path}/${companyId}/branches/${branchId}/logo`, body , ApiEnum.admin)
}

export const deleteCompanyLogo = (companyId, branchId) => {
  return _baseDeleteFetch(`${path}/${companyId}/branches/${branchId}/logo`, undefined, ApiEnum.admin)
}

export const getCompanyRoles = async (companyId) => {
  return _baseGetFetch(`${path}/${companyId}/roles`, undefined, ApiEnum.admin)
}

export const putConvertToIndividual = async (companyId) => {
  return _basePutFetch(`${path}/${companyId}/convert-individual`, undefined,  ApiEnum.admin)
}

// -----------------------------------------------------------
// Members API
// -----------------------------------------------------------

export const getCurrentCompany = () => {
  return _baseGetFetch("/users/current/company", undefined, ApiEnum.admin)
}

export const getMembersByCompanyId = (companyId, params) => {
  return _baseGetFetch(`${path}/${companyId}/members`, params, ApiEnum.admin)
}

export const getRequestsByCompanyId = (companyId, bodyParams) => {
  return _baseGetFetch(`${path}/${companyId}/requests`, bodyParams, ApiEnum.admin)
}

export const inviteUserToCompany = (companyId, payload) => {
  return _basePostFetch(`${path}/${companyId}/requests/invite`, payload, ApiEnum.admin)
}

export const patchRequestStatus = (companyId, id, payload) => {
  return _basePatchFetch(`${path}/${companyId}/requests/${id}`, payload, ApiEnum.admin)
}

export const deleteUserFromCompany = (companyId, uuid, bodyParams) => {
  return _basePatchFetch(`${path}/${companyId}/members/${uuid}`, bodyParams, ApiEnum.admin)
}

export const getMembersFilterOptions = (companyId) => {
  return _baseGetFetch(`${path}/${companyId}/members/filter-options`, undefined, ApiEnum.admin)
}

export const editMember = (companyId, uuid, payload) => {
  return _basePostFetch(`${path}/${companyId}/members/${uuid}`, payload, ApiEnum.admin)
}

export const updateCurrentCompany = (bodyParams) => {
  return _basePutFetch(`/users/current/company`, bodyParams, ApiEnum.admin)
}

export const updateCurrentBranch = (bodyParams) => {
  return _basePutFetch(`/users/current/branch`, bodyParams, ApiEnum.admin)
}

export const getCompanyRolePermissions = (companyId, roleId) => {
  return _baseGetFetch(`${path}/${companyId}/roles/${roleId}/permissions`, undefined, ApiEnum.admin)
}
// export const getMembersByCompanyAndBranchId = (companyId, branchId) => {
//   return _baseGetFetch(`${path}/${companyId}/branches/${branchId}/members`, {},  ApiEnum.admin)
// }

// export const getAdminViewMember = (companyId, uuid) => {
//   return _baseGetFetch(`${path}/${companyId}/branches/members/${uuid}`, {},  ApiEnum.admin)
// }

// export const removeUserFromBranchCompany = (companyId, uuid) => {
//   return _basePatchFetch(`${path}/${companyId}/members/${uuid}`, {},  ApiEnum.admin)
// }

// ---------------------------------------------------
// End User
// ---------------------------------------------------

export const getUserInviteRequest = (queryParams) => {
  return _baseGetFetch('/users/current/company/requests', queryParams, ApiEnum.admin)
}

export const userAcceptRejectCompanyInvitation = (id, bodyParams) => {
  return _basePatchFetch(`/users/current/company/requests/${id}`, bodyParams, ApiEnum.admin)
}

export const usersRequestToJoinCompany = (bodyParams) => {
  return _basePatchFetch("/users/current/company/requests/join", bodyParams, ApiEnum.admin)
}

export const usersLeaveCompany = (bodyParams) => {
  return _basePatchFetch("/users/current/company/leave", bodyParams, ApiEnum.admin)
}

export const getUsersCurrentDetails = () => {
  return _baseGetFetch("/users/current/details", undefined, ApiEnum.admin)
}

export const updateAccountToOrganization = (bodyParams) => {
  return _basePostFetch("/users/current/company/convert-organization", bodyParams, ApiEnum.admin)
}
