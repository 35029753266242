import "./videos.scss"
import PropTypes from "prop-types"
import Troubleshoot from "../Troubleshoot"
import { BoxTabs, Tab } from "../common/Tabs"
import VideosTable from "./VideosTable"
import FavIcon from "../../icons/fav.svg"
import ActiveFavIcon from "../../icons/active_fav.svg"
import Breadcrumb from "../common/Breadcrumb"
import { useTranslation } from "react-i18next"
import DashboardHeader from "../common/DashboardHeader/DashboardHeader"
import { useHistory, useLocation } from "react-router-dom"
import { useEffect } from "react"

const Videos = (props) => {
  const { t } = useTranslation()
  const history = useHistory()
  const location = useLocation()
  const stateModel = location.state && location.state.model
  const canGoBack = location.state && location.state.withBackNavigation
  const model = props.model || stateModel
  const hideBreadcrumb = props.hideBreadcrumb

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const vid = params.get("vid")
    if (!vid) {
      const state = location.state
      delete state?.model
      history.replace(`${history.location.pathname}`, { ...state })
      return
    }
  }, [])

  return (
    <div className="videos-wrapper">
      {!hideBreadcrumb ? (
        <>
          <Breadcrumb path={window.location.pathname} withBackNavigation={canGoBack} />
          <DashboardHeader headerText={t("nav.videos")}/>
          {props.hideFilters && (
            <div className="divider"/>
          )}
          <BoxTabs className="underlined-box-tab">
            <Tab index={0} label={t("video_context.all_videos")} key="AllVideos">
              <VideosTable model={model} hideFilters={props.hideFilters} key="all-videos" />
            </Tab>
            <Tab
              index={1}
              label={t("favorites")}
              key="Favorites"
              activeIcon={<img style={{ display: "inline-block" }} src={ActiveFavIcon} alt="favourites-active" />}
              inactiveIcon={<img src={FavIcon} alt="favourites-inactive" />}
            >
              <VideosTable isFavourite={true} model={model} hideFilters={props.hideFilters} key="favourites" hideBreadcrumb />
            </Tab>
          </BoxTabs>
        </>
      ) : (
        <>
          <DashboardHeader headerText={t('nav.videos')} />
          {props.hideFilters && <div className="divider" />}
          <VideosTable model={model} hideFilters={props.hideFilters} key="favourites" withAllVideos={true} />
        </>
      )}
    </div>
  )
}

Troubleshoot.propTypes = {
  model: PropTypes.string,
}

export default Videos
