import React, { useCallback, useEffect, useState, useMemo } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { debounce } from 'lodash'
import VideoModal from '../common/VideoModal'
import SimpleButton from '../common/SimpleButton'
import SearchWithFilter from '../common/SearchWithFilter'
import FiltersModal, { FilterType } from '../common/FiltersModal'
import LoadingSpinner from '../common/LoadingSpinner'
import EmptyPageResults from '../common/EmptyPageResults'
import GenericTable from '../common/GenericTable'
import ExpandableText from '../common/ExpandableText'
import { toast } from '../common/Toast'
import { useAppContext } from '../../libs/contextLib'
import { useWindowSize } from '../../libs/hooks'
import { handleSetQueryParams } from '../../actions/queryParams'
import { loadVideoModels, loadVideoCategories } from '../../actions/videos'
import { FilledFavIcon, UnFilledFavIcon, VideoPlayIcon } from '../../icons'
import { formatDateMonthDayYear } from '../../utils/filters/date'
import {
  getVideosListFavorites,
  getVideosModels,
  getVideosList,
  postVideoFavourites,
  putVideoFavourites,
  getVideosCategories,
} from '../../utils/requests/videosAPI'
import { checkPermission, videosWritePermission } from '../../utils/permissionValidation'
import { keyboardEvents } from '../../utils/keyboardEvents'
import { capitalizeWords } from '../../utils/functions'

function VideosTable(props) {
  const { t } = useTranslation()
  const history = useHistory()
  const { isAuthenticated } = useAppContext()
  const isMobile = useWindowSize()[0] <= 768
  const userId = props.authedUser?.cognitoSub
  const pageSize = parseInt(process.env.REACT_APP_PAGINATION_SIZE) || 10
  const [models, setModels] = useState([])
  const [videos, setVideos] = useState([])
  const [videoCategories, setVideoCategories] = useState([])
  const [expandedVideo, setExpandedVideo] = useState(0)
  const [selectedVideo, setSelectedVideo] = useState(null)
  const [canLoadMore, setCanLoadMore] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [selectedCategory, setSelectedCategory] = useState(null)
  const [selectedModel, setSelectedModel] = useState(props.model || null)
  const [isSearchDirty, setIsSearchDirty] = useState(false)
  const [showSpinner, setShowSpinner] = useState(false)
  const [showFiltersModal, setShowFiltersModal] = useState(false)
  const [queryParamsState, setQueryParamsState] = useState(() => ({
    //page: 1,
    //limit: parseInt(process.env.REACT_APP_PAGINATION_SIZE),
    orderBy: 'title',
    order: 'asc',
    ...(selectedModel ? { model: selectedModel } : {}),
    //...(props.isFavourite ? { username: userId } : {}),
  }))

  // const fetcher = useMemo(() => (props.isFavourite ? getVideosListFavorites : getVideosList), [props.isFavourite])
  const fetcher = getVideosList
  const [isFirstLoad, setIsFirstLoad] = useState(true)

  useEffect(() => {
    if (props.videoModels.length === 0) {
      props.actions.loadVideoModels()
    }

    if (props.videoCategories.length === 0) {
      props.actions.loadVideoCategories()
    }
  }, [props.actions])

  useEffect(() => {
    if (props.videoModels.length > 0) {
      setModels(props.videoModels)
    }
  }, [props.videoModels])

  useEffect(() => {
    if (props.videoCategories.length > 0) {
      setVideoCategories(props.videoCategories.filter((category) => category !== 'Online Access'))
    }
  }, [props.videoCategories])

  const fetchVideos = useCallback(
    async (queryParams, videos) => {
      try {
        if (!userId) {
          return []
        }

        const page = await fetcher({ ...queryParams })
        //const totalPages = page?.totalPages || 1
        setCurrentPage(1)
        //setCanLoadMore(totalPages > queryParams.page)

        // const dataWithoutDuplicates = page?.data.reduce((acc, current) => {
        //   const existingVideo = acc.find((item) => item.video_id === current.video_id)
        //   if (!existingVideo) {
        //     return acc.concat([current])
        //   } else {
        //     return acc.map((item) =>
        //       item.video_id === current.video_id
        //         ? { ...item, model: `${item.model}, ${current.model}`, is_favourite: item.is_favourite || current.is_favourite }
        //         : item,
        //     )
        //   }
        // }, [])
        let allVideos = page?.data || []

        if (!props.withAllVideos) {
          allVideos = allVideos.filter((video) => video.category !== 'Online Access')
        }

        if (props.isFavourite) {
          return allVideos.filter((video) => video.is_favourite)
        }

        return allVideos
        // if (queryParams.page === 1) {
        //   return page?.data || []
        // }

        // const newVideos = videos.concat(page?.data)
        // return newVideos
      } catch (e) {
        console.error(e.message)

        toast.error(t('error.failure_msg'))
        setShowSpinner(false)
      } finally {
        setIsFirstLoad(false)
      }
    },
    [fetcher, t],
  )

  const videosTableData = (videos ?? []).map((video) => {
    return {
      identifier: video.id,
      title: video.title,
      model: video.model,
      category: capitalizeWords(video.category?.replaceAll('-', ' ')),
      lastUpdated: video.video_updated_at ? formatDateMonthDayYear(video.video_updated_at) : '',
      isFavourite: video.is_favourite,
      favouriteId: video.favourite_id,
      thumbnailUrl: video.thumbnail_url,
      video_id: video.video_id,
    }
  })

  const openVideoModal = useCallback(
    (video) => {
      setSelectedVideo(video)
      const params = new URLSearchParams({ vid: video.video_id })
      if (!selectedModel) {
        history.push({
          search: params.toString(),
        })
      }
    },
    [history, selectedModel],
  )

  const closeVideoModal = useCallback(() => {
    setSelectedVideo(null)

    const params = new URLSearchParams({})
    history.push({
      search: params.toString(),
    })
  }, [history])

  useEffect(() => {
    async function loadVideoModal() {
      if (!isAuthenticated) {
        history.push({ pathname: '/' })
      }

      if (props.queryParams?.vid) {
        props.actions.handleSetQueryParams({ vid: null })
      }

      const params = new URLSearchParams(window.location.search)
      const vid = params.get('vid')
      if ((videos && videos.length === 0) || !vid) {
        return
      }

      const response = await getVideosList({
        vid,
      })
      const requestedVideo = response.data[0]

      if (requestedVideo) {
        openVideoModal(requestedVideo)
      } else {
        const params = new URLSearchParams({})

        toast.error(t(`error.video_not_found`))

        history.push({
          search: params.toString(),
          state: {
            errorMessage: 'Something went wrong',
          },
        })
      }
    }
    loadVideoModal()
  }, [history, isAuthenticated, openVideoModal, videos])

  const handleSort = async (orderBy) => {
    const nextQueryParams = {
      ...queryParamsState,
      //page: 1,
      orderBy: orderBy.field,
      order: orderBy.order,
    }

    setShowSpinner(true)
    setQueryParamsState(nextQueryParams)
    const newVideos = await fetchVideos(nextQueryParams, videos)
    setVideos(newVideos)
    setShowSpinner(false)
  }

  useEffect(() => {
    async function handleFilters() {
      let isMounted = true
      setShowSpinner(true)
      const { category, model, ...queryParams } = queryParamsState
      const nextQueryParams = {
        ...queryParams,
        ...(selectedModel ? { model: selectedModel } : {}),
        ...(selectedCategory ? { category: selectedCategory } : {}),
        //page: 1,
      }
      setQueryParamsState(nextQueryParams)
      const newVideos = await fetchVideos(nextQueryParams, videos)
      if (isMounted) {
        setVideos(newVideos)
      }
      setShowSpinner(false)

      return () => {
        isMounted = false
      }
    }

    return handleFilters()
  }, [fetchVideos, selectedCategory, selectedModel])

  const handleSearch = async (searchTerm) => {
    setShowSpinner(true)
    const isSearchValid = searchTerm.length > 2
    if (isSearchValid || isSearchDirty) {
      const { query, ...queryParams } = queryParamsState
      const nextQueryParams = {
        ...queryParams,
        ...(searchTerm.length > 2 && { query: searchTerm }),
        //page: 1,
      }
      setQueryParamsState(nextQueryParams)
      const newVideos = await fetchVideos(nextQueryParams, videos)
      setVideos(newVideos)

      if (isSearchValid) {
        setIsSearchDirty(true)
      }
    }

    setShowSpinner(false)
  }

  const debouncedHandleSearch = useCallback(debounce(handleSearch, 200), [handleSearch])

  const handleLoadMore = async () => {
    // setShowSpinner(true)
    // const nextQueryParams = {
    //   ...queryParamsState,
    //   page: queryParamsState.page + 1,
    // }
    // setQueryParamsState(nextQueryParams)
    // const newVideos = await fetchVideos(nextQueryParams, videos)
    // setVideos(newVideos)
    // setShowSpinner(false)
    setCurrentPage((prev) => prev + 1)
  }

  const handleResetFilters = async () => {
    const { model, category, ...queryParams } = queryParamsState
    const nextQueryParams = {
      ...queryParams,
      //page: 1
    }
    setQueryParamsState(nextQueryParams)

    setSelectedModel(null)
    setSelectedCategory(null)
  }

  const handleFavourites = async (favourite_id, video_id, is_favourite) => {
    if (showSpinner || !checkPermission(props.userPermissions, videosWritePermission)) {
      return
    }

    setShowSpinner(true)

    if (favourite_id) {
      let isFav
      if (props.isFavourite) {
        isFav = false
      } else {
        isFav = is_favourite ? false : true
      }

      try {
        await putVideoFavourites(userId, favourite_id, isFav)

        if (props.isFavourite) {
          const updatedVideos = videos.filter((video) => video.id !== video_id)
          setVideos(updatedVideos)

          return
        }

        const updatedVideos = videos.map((video) => {
          if (video.id === video_id) {
            return { ...video, is_favourite: isFav }
          }
          return video
        })
        setVideos(updatedVideos)
      } catch (e) {
        console.error(e.message)
      } finally {
        setShowSpinner(false)
      }

      return
    }

    try {
      const favouriteData = await postVideoFavourites(userId, video_id, true)

      const updatedVideos = videos.map((video) => {
        if (video.id === video_id) {
          return { ...video, favourite_id: favouriteData?.data?.insert_id || 0, is_favourite: true }
        }
        return video
      })

      setVideos(updatedVideos)
    } catch (e) {
      console.error(e.message)
    } finally {
      setShowSpinner(false)
    }
  }

  const getDesktopView = () => {
    return (
      <>
        {videosTableData.length > 0 && (
          <GenericTable
            data={videosTableData.slice(0, pageSize * currentPage)}
            headers={[
              {
                title: t('title'),
                orderKey: 'title',
              },
              {
                title: t('model'),
                orderKey: 'model',
              },
              {
                title: t('category'),
                orderKey: 'category',
              },
              {
                title: t('last_updated'),
                orderKey: 'video_updated_at',
              },
              {
                title: '',
              },
            ]}
            keys={['title', 'model', 'category', 'lastUpdated', 'action']}
            keyRenderer={{
              title: (item) => {
                return (
                  <div className="title-wrapper">
                    <div className="thumbnail-mask">
                      <div className="thumbnail-icon">
                        <VideoPlayIcon />
                      </div>
                      <img className="thumbnail" src={item.thumbnailUrl} alt={item.title} />
                    </div>
                    <div className="title">{item.title}</div>
                  </div>
                )
              },
              action: (item) => {
                return (
                  <div className="table-actions-wrapper">
                    {/* || props.isFavourite */}
                    {item.isFavourite ? (
                      <FilledFavIcon
                        className="star-icon filled"
                        onClick={(e) => {
                          e.stopPropagation()
                          handleFavourites(item.favouriteId, item.identifier, item.isFavourite)
                        }}
                        tabIndex={0}
                        role="button"
                        onKeyDown={(e) => {
                          if (e.key === keyboardEvents.ENTER) {
                            e.stopPropagation()
                            handleFavourites(item.favouriteId, item.identifier, item.isFavourite)
                          }
                        }}
                      />
                    ) : (
                      <UnFilledFavIcon
                        className="star-icon"
                        onClick={(e) => {
                          e.stopPropagation()
                          handleFavourites(item.favouriteId, item.identifier, item.isFavourite)
                        }}
                        tabIndex={0}
                        role="button"
                        onKeyDown={(e) => {
                          if (e.key === keyboardEvents.ENTER) {
                            e.stopPropagation()
                            handleFavourites(item.favouriteId, item.identifier, item.isFavourite)
                          }
                        }}
                      />
                    )}
                  </div>
                )
              },
            }}
            onRowClick={(video) => openVideoModal(video)}
            activeSort={{ field: queryParamsState.orderBy, order: queryParamsState.order }}
            onSort={(orderingField) => handleSort(orderingField)}
            isLoading={showSpinner}
          />
        )}
      </>
    )
  }

  const getMobileView = () => {
    return (
      <>
        {videosTableData.slice(0, pageSize * currentPage).map((video, index) => (
          <div key={index} className={'card'} onClick={() => setExpandedVideo(index)}>
            <div className={'card-item' + (expandedVideo !== index ? ' align-center' : '')}>
              <div
                className="card-item-title video-icon-title"
                onClick={(e) => {
                  e.stopPropagation()
                  openVideoModal(video)
                }}
              >
                <div className="thumbnail-mask">
                  <div className="thumbnail-icon">
                    <VideoPlayIcon />
                  </div>
                  <img className="thumbnail" src={video.thumbnailUrl} alt={video.title} />
                </div>
              </div>
              <div className={'card-item-body' + (expandedVideo !== index ? ' align-center' : '')}>
                <div>{video.title}</div>
                <div className="card-actions">
                  {video.isFavourite || props.isFavourite ? (
                    <FilledFavIcon
                      className="star-icon filled"
                      onClick={(e) => {
                        e.stopPropagation()
                        handleFavourites(video.favouriteId, video.identifier, video.isFavourite)
                      }}
                    />
                  ) : (
                    <UnFilledFavIcon
                      className="star-icon"
                      onClick={(e) => {
                        e.stopPropagation()
                        handleFavourites(video.favouriteId, video.identifier, video.isFavourite)
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
            {expandedVideo === index && (
              <>
                <div className="card-item">
                  <div className="card-item-title">{t('model')}</div>
                  <div className="card-item-body">{video.model}</div>
                </div>
                <div className="card-item">
                  <div className="card-item-title">{t('category')}</div>
                  <div className="card-item-body">{video.category}</div>
                </div>
                <div className="card-item">
                  <div className="card-item-title">{t('last_updated')}</div>
                  <div className="card-item-body">{video.lastUpdated}</div>
                </div>
              </>
            )}
          </div>
        ))}
      </>
    )
  }

  return (
    <>
      {!props.hideFilters && (
        <div className="actions-wrapper">
          <SearchWithFilter
            onSearch={debouncedHandleSearch}
            showFiltersModal={() => setShowFiltersModal(true)}
            onResetFilters={() => handleResetFilters()}
            showCounterBadge={selectedModel || selectedCategory}
            counterBadge={(selectedModel && 1) + (selectedCategory && 1)}
          />
        </div>
      )}
      {!isMobile && getDesktopView()}
      {isMobile && getMobileView()}
      {!showSpinner && !isFirstLoad && videosTableData.length === 0 && (
        <EmptyPageResults className={'empty-results-margin'} title={t('video_context.no_videos_found')} subtitle={t('cannot_find_matching_search')} />
      )}
      {videosTableData.length > pageSize * currentPage && (
        <div className="buttons-wrapper">
          <SimpleButton className="load-more-button" onClick={() => handleLoadMore()}>
            {t('load_more')}
          </SimpleButton>
        </div>
      )}
      {showFiltersModal && (
        <FiltersModal
          filters={[
            {
              name: t('model'),
              type: FilterType.singleSelect,
              dataset: models,
              input: selectedModel,
              output: (newModel) => {
                setSelectedModel(newModel)
              },
            },
            {
              name: t('category'),
              type: FilterType.singleSelect,
              dataset: videoCategories,
              input: selectedCategory,
              output: (newCategory) => {
                setSelectedCategory(newCategory)
              },
            },
          ]}
          resetFilters={() => handleResetFilters()}
          closeFilters={() => setShowFiltersModal(false)}
        />
      )}
      {showSpinner && (
        <div className={'spinner-wrapper'}>
          <LoadingSpinner />
        </div>
      )}
      {selectedVideo && <VideoModal title={selectedVideo.title} videoId={selectedVideo.video_id} onCloseVideo={closeVideoModal} />}
    </>
  )
}

function mapStateToProps({ authedUser, userPermissions, productTypes, queryParams, videos }) {
  return {
    authedUser,
    productTypes: productTypes || {},
    userPermissions,
    queryParams,
    videoModels: videos?.models || [],
    videoCategories: videos?.categories || [],
  }
}

function dispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        loadVideoModels,
        loadVideoCategories,
        handleSetQueryParams,
      },
      dispatch,
    ),
  }
}

export default connect(mapStateToProps, dispatchToProps)(VideosTable)
