import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons"
import { useTranslation } from "react-i18next"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useEffect, useState } from "react"
import { convertTimeWithTAndZ } from '../../../utils/filters/date'

function LogTable(props) {
  const { t } = useTranslation()
  const { data } = props
  const NA = "N/A"
  const [sortList, setSortList] = useState(Array(6).fill(true))
  const columns = [
    t("enhanced_event_log.date_time"),
    t("enhanced_event_log.machine"),
    t("enhanced_event_log.device"),
    t("enhanced_event_log.event_type"),
    t("description"),
    t("device_context.cycle_number"),
  ]
  const payloadMappings = ["dateTime", "machine", "device", "eventType", "description", "cycleNumber"]
  const [processedData, setProcessedData] = useState([])

  useEffect(() => {
    setProcessedData(rawDataProcess())
  }, [data])

  const getStatus = (log) => {
    if (log.eventType.toLowerCase().includes("fault")) {
      return "fault"
    } else if (log.eventType.toLowerCase().includes("interrupted")) {
      return "interrupted"
    }
    return ""
  }

  const sort = (idx) => {
    sortList[idx] = !sortList[idx]
    setSortList(JSON.parse(JSON.stringify(sortList)))
    sortDataByField(idx, sortList[idx])
  }

  const sortDataByField = (idx, desc) => {
    const field = payloadMappings[idx]
    let sorted
    if (desc) {
      sorted = [...processedData].sort((a, b) => b[field].toString().localeCompare(a[field].toString()))
    } else {
      sorted = [...processedData].sort((a, b) => a[field].toString().localeCompare(b[field].toString()))
    }
    setProcessedData(sorted)
  }

  const rawDataProcess = () => {
    const newData = []
    data.forEach((log) => {
      newData.push({
        dateTime: log.date ? convertTimeWithTAndZ(log.date) : NA,
        machine: log.serialNumber || NA,
        device: log.model || NA,
        eventType: log.cycleEvent || NA,
        description: log.description || NA,
        cycleNumber: log.cycleNumber || NA,
      })
    })
    return newData
  }

  return (
    <div className={"log-table-wrapper"}>
      <div className={"log-table-header"}>
        {columns &&
          columns.map((col, idx) => (
            <div className={"log-table-header-section"} key={idx}>
              <div>{col}</div>
              <FontAwesomeIcon icon={sortList[idx] ? faArrowDown : faArrowUp} className={"arrow"} onClick={() => sort(idx)} />
            </div>
          ))}
      </div>
      {processedData &&
        processedData.map((log, index) => (
          <div className={"log-table-body"} key={index}>
            <div className={`log-table-body-section ${getStatus(log)}`}>
              <div>{log.dateTime}</div>
            </div>
            <div className={`log-table-body-section ${getStatus(log)}`}>
              <div>{log.machine}</div>
            </div>
            <div className={`log-table-body-section ${getStatus(log)}`}>
              <div>{log.device}</div>
            </div>
            <div className={`log-table-body-section ${getStatus(log)}`}>
              <div>{log.eventType}</div>
            </div>
            <div className={`log-table-body-section ${getStatus(log)}`}>
              <div>{log.description}</div>
            </div>
            <div className={`log-table-body-section ${getStatus(log)}`}>
              <div>{log.cycleNumber}</div>
            </div>
          </div>
        ))}
    </div>
  )
}

export default LogTable
