import React, { Fragment, useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { Storage } from "aws-amplify"
import LoadingSpinner from "../common/LoadingSpinner"
import HighlightedText from "../common/HighlightedText"
import EmptyPageResults from '../common/EmptyPageResults'
import SimpleButton from '../common/SimpleButton'
import SearchWithFilter from '../common/SearchWithFilter'
import FiltersModal, { FilterType } from "../common/FiltersModal"
import { toast } from "../common/Toast"
import { useDeepCompareEffect } from '../../hooks/useDeepCompareEffect'
import { DownloadModernIcon, PdfModernIcon } from "../../icons"
import { loadTSBDocuments, clearDocumentsMessages, loadTsbDocumentLanguages } from "../../actions/documents"
import { keyboardEvents } from "../../utils/keyboardEvents"

const TechnicalService = (props) => {
  const { t } = useTranslation()
  const [documents, setDocuments] = useState([])
  const [languages, setLanguages] = useState([])
  const [shouldApplyFilters, setShouldApplyFilters] = useState(true)
  const [selectedFilterYear, setSelectedFilterYear] = useState(null)
  const [selectedFilterLanguage, setSelectedFilterLanguage] = useState(null)
  const [filterQuery, setFilterQuery] = useState(null)
  const [showFiltersModal, setShowFiltersModal] = useState(false)
  const [showSpinner, setShowSpinner] = useState(null)
  const [canLoadMore, setCanLoadMore] = useState(false)
  const [selectedPage, setSelectedPage] = useState(1)
  const [isFirstLoad, setIsFirstLoad] = useState(true)

  useEffect(() => {    
    if (props.documentLanguages.length === 0) {
      props.actions.loadTsbDocumentLanguages()
    }
  }, [])

  useEffect(() => {
    setDocuments(props.documents)
  }, [props.documents])

  useEffect(() => {
    setLanguages(props.documentLanguages)
  }, [props.documentLanguages])

  useEffect(() => {
    setCanLoadMore(props.canLoadMore)
  }, [props.canLoadMore])

   useEffect(() => {
    if (props.errorMessage) {
      toast.error(props.errorMessage)

      props.actions.clearDocumentsMessages()
    }
  }, [props.errorMessage])

  useEffect(() => {
    if (selectedPage && selectedPage > 1) {
      handleLoadDocuments(false)
    }
  }, [selectedPage])

  useDeepCompareEffect(() => {
    let changeValueTimeout = window.setTimeout(
      () => {
        if (shouldApplyFilters) {
          setSelectedPage(1)
          handleLoadDocuments(true)
        }

        setShouldApplyFilters(true)
      },
      filterQuery ? 1000 : 0
    )
    return () => {
      clearTimeout(changeValueTimeout)
    }    
  },[filterQuery, selectedFilterYear, selectedFilterLanguage])

  useEffect(() => {
    setShowSpinner(props.isLoading)
  }, [props.isLoading])

  const handleLoadDocuments = (withReset) => {
    let queryParams = {
      page: withReset ? 1 : selectedPage
    }

    if (filterQuery) {
      queryParams = { ...queryParams, query: filterQuery}
    }

    if (selectedFilterYear) {
      const year = new Date(selectedFilterYear).getUTCFullYear()
      queryParams = { ...queryParams, year: year}
    }

    if (selectedFilterLanguage) {
      queryParams = { ...queryParams, lang: selectedFilterLanguage}
    }

    props.actions.loadTSBDocuments(queryParams, withReset)
      .then(() => setIsFirstLoad(false))
  }

  const handleLoadMore = () => {
    setSelectedPage((prevValue) => (prevValue ? prevValue + 1 : 2))
  }

  const handleSearch = (value) => {
    if (value?.length > 2) {
      setShowSpinner(true)
      setFilterQuery(value)
    } else if (value?.length === 0 && filterQuery?.length > 0) {
      setShowSpinner(true)
      setFilterQuery(null)
    }
  }

  const handleResetFilters = () => {
    setSelectedFilterYear(null)
    setSelectedFilterLanguage(null)
  }

  async function handleDownload(filePath, fileName) {
    Storage.configure({
      customPrefix: {
        public: "",
        private: "documents/private/",
      },
    })

    const result = await Storage.get(`${filePath}${fileName}`)
    window.open(result, "_blank")
  }

  return (
    <Fragment>
      <div className="documents-content-wrapper">
        {!props.model && (
          <div className="actions-wrapper d-flex flex-align-center">
            <SearchWithFilter
              onSearch={(e) => handleSearch(e)}
              showFiltersModal={() => setShowFiltersModal(true)}
              onResetFilters={() => handleResetFilters()}
              showCounterBadge={selectedFilterYear || selectedFilterLanguage}
              counterBadge={(selectedFilterYear && 1 ) + (selectedFilterLanguage && 1)}
            />
          </div>
        )}
        {documents.length > 0 && (
          <div className="list-wrapper">
            {documents.map((item, index) => {
              return (
                <div
                  key={index}
                  className={"item-wrapper" + (index === documents.length - 1 ? " no-border" : "")}
                  onClick = {() => handleDownload(item?.file_path, item?.file_name)}
                  tabIndex={0}
                  role="button"
                  onKeyDown={(event) => {
                    if (event.key === keyboardEvents.ENTER) {
                      handleDownload(item?.file_path, item?.file_name)
                    }
                  }}
                >
                  <div className="image-wrapper">
                    <DownloadModernIcon />
                  </div>
                  <div className="image-wrapper rounded-image">
                    <PdfModernIcon />
                  </div>                  
                  <div className="name-wrapper">
                    <HighlightedText
                      defaultTextClassName="title"
                      matchingTextClassName="title matching-text"
                      nonMatchingTextClassName="title non-matching-text"
                      text={item?.document_title ? item?.document_title : item?.file_name} 
                      highlight={filterQuery}
                    />
                    <p className="section">{item?.section_name}</p>
                  </div>
                </div>
              )})
            }
          </div>)
        }
        {!showSpinner && !isFirstLoad && documents.length === 0 && (
          <EmptyPageResults 
            title={t("document_context.no_documents_found")}
            subtitle={t("cannot_find_matching_search")}
          />)
        }
        {canLoadMore && documents.length > 0 && (
          <div className="buttons-wrapper">
            <SimpleButton className="load-more-button" onClick={() => handleLoadMore()}>
              {t('load_more')}
            </SimpleButton>
          </div>)
        }
      </div>
      {showFiltersModal &&
        <FiltersModal
          filters={[
            {
              name: t("year"),
              type: FilterType.dateFixed,
              dataset: null,
              input: selectedFilterYear,
              output: (filterYear) => {
                setSelectedFilterYear(filterYear)
              },
              yearPicker: true
            },
            {
              name: t("language"),
              type: FilterType.singleSelect,
              dataset: languages.map((lang) => `${lang}-lang`),
              input: selectedFilterLanguage ? `${selectedFilterLanguage}-lang` : null,
              output: (filterLanguage) => {
                const language = filterLanguage?.split("-")[0] || null
                setSelectedFilterLanguage(language)
              },
            },
          ]}
          shouldFilter={(shouldApply) => setShouldApplyFilters(shouldApply)}
          resetFilters={() => handleResetFilters()}
          closeFilters={() => setShowFiltersModal(false)}
        />
      }
      {showSpinner &&
        <div className={"spinner-wrapper"}>
          <LoadingSpinner/>
        </div>
      }
    </Fragment>
  )
}

function stateToProps({ documents }) {
  return {
    documents: documents?.tsbDocuments || [],
    documentLanguages: documents?.tsbDocumentLanguages || [],
    isLoading: documents?.isLoading,
    canLoadMore: documents?.canLoadMore,
    errorMessage: documents?.errorMessage,
  }
}

function dispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        loadTSBDocuments,
        loadTsbDocumentLanguages,
        clearDocumentsMessages
      },
      dispatch
    ),
  }
}

export default connect(stateToProps, dispatchToProps)(TechnicalService)
