import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { LineChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, Line } from 'recharts'
import { formatTime } from '../../../utils/functions'

function getColorMap() {
  return {
    airGapSwitchLow: '#BA05A5',
    airGapSwitchFull: '#67D4FF',
    airGapSwitchOverflow: '#00B7FF',
    airFilterHepa: '#0999D1',
    chamberOverflow: '#FA926B',
    chamberFull: '#B13205',
    chamberLow: '#F7B051',
    chemicalReservoirSwitch: '#E8CC63',
    dryerHeater: '#F42C16',
    doorPosition: '#C3F25A',
    dosingBellowsPump: '#A62BDE',
    dosingValidation: '#732098',
    dosingFillingPump: '#B749ED',
    evacuationPump: '#BA8905',
    lcsAirPressureSwitch: '#BCC1FA',
    lcsPresent: '#7F8BFC',
    rinseAidSwitch: '#E8CC63',
    saltReservoirSwitch: '#10B209',
    valveLcs: '#6370EF',
    valveCondenser: '#004EFF',
    valveColdWater: '#1F12BA',
    valveHotWater: '#F86240',
    valveAirGap: '#41F8DC',
    valveRinseAid: '#0F7C07',
    valveRoWater: '#13AD96',
    valveSalt: '#095504',
    waterHeater: '#046959',
    waterLeak: '#0A6FA3',
  }
}

export default function OutputChart(props) {
  const { t } = useTranslation()
  const [timeFormat, setTimeFormat] = useState('24hr')
  const [visibleKeys, setVisibleKeys] = useState([])
  const [data, setData] = useState([])
  const colorMap = getColorMap()

  useEffect(() => {
    const updatedData = props.data.map((item) => {
      const transformedItem = { time: item.time }
      Object.keys(item).forEach((key) => {
        if (key !== 'time' && item[key].value !== undefined) {
          transformedItem[key] = item[key].value
          transformedItem[`${key}_status`] = item[key].key
        }
      })
      return transformedItem
    })

    setData(updatedData)
  }, [props.data])

  useEffect(() => {
    if (data && data.length > 0) {
      const keys = Object.keys(data[0]).filter((key) => key !== 'time')
      setVisibleKeys((prev) => (prev.length > 0 ? prev : keys))
    }
  }, [data])

  const validData = data && data.length > 0

  const handleToggleTimeFormat = () => {
    setTimeFormat((prev) => (prev === '24hr' ? '12hr' : '24hr'))
  }

  const handleCheckboxChange = (key) => {
    setVisibleKeys((prev) => {
      if (prev.includes(key)) {
        if (prev.length === 1) {
          return prev
        }
        return prev.filter((item) => item !== key)
      } else {
        return [...prev, key]
      }
    })
  }

  const renderLegend = ({ onCheckboxChange, onToggleTimeFormat, timeFormat }) => {
    const legendItems = [
      { key: 'airGapSwitchLow', label: t('advance_troubleshooting_context.airGapSwitchLow') },
      { key: 'airGapSwitchFull', label: t('advance_troubleshooting_context.airGapSwitchFull') },
      { key: 'airGapSwitchOverflow', label: t('advance_troubleshooting_context.airGapSwitchOverflow') },
      { key: 'airFilterHepa', label: t('advance_troubleshooting_context.airFilterHepa') },
      { key: 'chamberOverflow', label: t('advance_troubleshooting_context.chamberOverflow') },
      { key: 'chamberFull', label: t('advance_troubleshooting_context.chamberFull') },
      { key: 'chamberLow', label: t('advance_troubleshooting_context.chamberLow') },
      { key: 'chemicalReservoirSwitch', label: t('advance_troubleshooting_context.chemicalReservoirSwitch') },
      { key: 'dryerHeater', label: t('advance_troubleshooting_context.dryerHeater') },
      { key: 'doorPosition', label: t('advance_troubleshooting_context.doorPosition') },
      { key: 'dosingBellowsPump', label: t('advance_troubleshooting_context.dosingBellowsPump') },
      { key: 'dosingValidation', label: t('advance_troubleshooting_context.dosingValidation') },
      { key: 'dosingFillingPump', label: t('advance_troubleshooting_context.dosingFillingPump') },
      { key: 'evacuationPump', label: t('advance_troubleshooting_context.evacuationPump') },
      { key: 'lcsAirPressureSwitch', label: t('advance_troubleshooting_context.lcsAirPressureSwitch') },
      { key: 'lcsPresent', label: t('advance_troubleshooting_context.lcsPresent') },
      { key: 'rinseAidSwitch', label: t('advance_troubleshooting_context.rinseAidSwitch') },
      { key: 'saltReservoirSwitch', label: t('advance_troubleshooting_context.saltReservoirSwitch') },
      { key: 'valveLcs', label: t('advance_troubleshooting_context.valveLcs') },
      { key: 'valveCondenser', label: t('advance_troubleshooting_context.valveCondenser') },
      { key: 'valveColdWater', label: t('advance_troubleshooting_context.valveColdWater') },
      { key: 'valveHotWater', label: t('advance_troubleshooting_context.valveHotWater') },
      { key: 'valveAirGap', label: t('advance_troubleshooting_context.valveAirGap') },
      { key: 'valveRinseAid', label: t('advance_troubleshooting_context.valveRinseAid') },
      { key: 'valveRoWater', label: t('advance_troubleshooting_context.valveRoWater') },
      { key: 'valveSalt', label: t('advance_troubleshooting_context.valveSalt') },
      { key: 'waterHeater', label: t('advance_troubleshooting_context.waterHeater') },
      { key: 'waterLeak', label: t('advance_troubleshooting_context.waterLeak') },
    ]

    return (
      <>
        <div className="button-wrapper">
          <button className="simple-button" onClick={onToggleTimeFormat}>
            {timeFormat === '24hr' ? t('advance_troubleshooting_context.switch_12_hr') : t('advance_troubleshooting_context.switch_24_hr')}
          </button>
        </div>

        <div className="legend-items">
          {legendItems.map((item) => (
            <div className="legend-item" key={item.key}>
              <input
                type="checkbox"
                id={`checkbox-${item.key}`}
                checked={visibleKeys.includes(item.key)}
                onChange={() => onCheckboxChange(item.key)}
                className="legend-checkbox"
                style={{
                  backgroundColor: colorMap[item.key],
                }}
              />
              <label htmlFor={`checkbox-${item.key}`}>{item.label}</label>
            </div>
          ))}
        </div>
      </>
    )
  }

  const renderTooltip = ({ active, payload, label }) => {
    if (active && payload?.length) {
      const filteredPayload = payload.filter((entry) => !entry.dataKey.endsWith('_status'))
      return (
        <div className="tooltip">
          {filteredPayload.map((entry, index) => {
            const dataKey = entry.dataKey
            const status = entry.payload[`${dataKey}_status`]
            return (
              <div key={index} style={{ color: entry.color }} className="tooltip-item">
                <p>{`${t(`advance_troubleshooting_context.${dataKey}`)}: ${status}`}</p>
              </div>
            )
          })}
          <div className="legend">
            <p>{t('time')}</p>
            <p className="item">{formatTime(timeFormat, label)}</p>
          </div>
        </div>
      )
    }
    return null
  }

  return (
    <div className="charts-responsive-container">
      <div className="output-chart-wrapper">
        <ResponsiveContainer width="95%" height="90%">
          <LineChart style={{ textAlign: 'right', width: '100%' }} data={data}>
            <CartesianGrid />
            <XAxis tickFormatter={(time) => formatTime(timeFormat, time)} dataKey="time" style={{ fontSize: '0.8em' }} />
            {validData &&
              Object.keys(data[0])
                .filter((key) => key !== 'time' && visibleKeys.includes(key))
                .map((key, index) => <Line key={key} yAxisId="left" type="step" dataKey={key} stroke={colorMap[key]} strokeWidth="2" dot={false} />)}
            <Tooltip content={renderTooltip} />
          </LineChart>
        </ResponsiveContainer>
      </div>

      <div className="legend-container">
        {renderLegend({
          onToggleTimeFormat: handleToggleTimeFormat,
          timeFormat,
          onCheckboxChange: handleCheckboxChange,
        })}
      </div>
    </div>
  )
}