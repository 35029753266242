import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import SterilizerDiagram from './SterilizerDiagram'
import DTChart1 from './DTChart1'
import DTChart2 from './DTChart2'
import DTChart3 from './DTChart3'
import TabularView from './TabularView'
import DebugScreen from './DebugScreen'
import Button from '../../common/Button'
import { toast } from '../../common/Toast'
import { fetchLatest, fetchPrevNextMinute } from '../../../utils/requests/telemetryAPI'
import { formatCurrentTime } from '../../../utils/filters/date'
import './digital-twin.scss'

const NA = 'N / A'

const processHistoryInput = (data) => ({
  time: data.__date ? formatCurrentTime(data.__date) : formatCurrentTime(new Date().toISOString()),
  'Steam Generator Temp 1': data.i_temperature_steam_generator_1 ? data.i_temperature_steam_generator_1 / 100 : '',
  'Steam Generator Temp 2': data.i_temperature_steam_generator_2 ? data.i_temperature_steam_generator_2 / 100 : '',
  'Ambient Temp': data.i_temperature_ambient ? data.i_temperature_ambient / 100 : '',
  'Heat Exchanger Temp': data.i_temperature_heat_exchanger ? data.i_temperature_heat_exchanger / 100 : '',
  'Chamber Temp': data.i_temperature_chamber ? data.i_temperature_chamber / 100 : '',
  'Band Heater (Top) Temp': data.i_temperature_band_heater_top ? data.i_temperature_band_heater_top / 100 : '',
  'Pressure Chamber': data.i_pressure_chamber ? data.i_pressure_chamber / 100 : '',
  'Water Reservoir Temp': data.i_temperature_water_reservoir ? data.i_temperature_water_reservoir / 100 : '',
  'Band Heater (Bottom) Temp': data.i_temperature_band_heater_bottom ? data.i_temperature_band_heater_bottom / 100 : '',
})

const processHistoryOutput = (data) => ({
  time: data.__date ? formatCurrentTime(data.__date) : formatCurrentTime(new Date().toISOString()),
  'Steam Generator 2 Valve': data.o_valve_fill_steam_generator_2 ? 115 : 110,
  'Water Drain Valve': data.o_valve_water_drain ? 105 : 100,
  'Fill Steam Generator Valve 1': data.o_valve_fill_steam_generator_1 ? 95 : 90,
  'Water Fill Valve': data.o_valve_water_fill === 1 ? 85 : 80,
  'Vacuum Pump': data.o_vacuum_pump === 1 ? 75 : 70,
  'Exhaust Valve 1': data.o_valve_exhaust_1 === 1 ? 65 : 60,
  'Exhaust Valve 2': data.o_valve_exhaust_2 === 1 ? 55 : 50,
  'Water Pump Fill System Generator 1': data.o_water_pump_fill_steam_generator_1 === 1 ? 45 : 40,
  'Water Pump Fill System Generator 2': data.o_water_pump_fill_steam_generator_2 === 1 ? 35 : 30,
  'Vent Chamber Valve': data.o_valve_vent_chamber === 1 ? 25 : 20,
  'Heater Exchanger Fan': data.o_fan_heat_exchanger === 1 ? 15 : 10,
  'LCD Fan': data.o_fan_lcd === 1 ? 5 : 0,
})

const dataParsing = (rawData) => {
  const data = {}
  data.vacuumPump = transformOnOff(rawData?.o_vacuum_pump)
  data.VFW = transformOpenClose(rawData?.o_valve_water_fill)
  data.waterReservoir = transformTemp(rawData?.i_temperature_water_reservoir)
  data.steamGenerator2 = transformTemp(rawData?.i_temperature_steam_generator_2)
  data.waterPump2 = transformOnOff(rawData?.o_water_pump_fill_steam_generator_2)
  data.waterPump1 = transformOnOff(rawData?.o_water_pump_fill_steam_generator_1)
  data.steamGenerator1 = transformTemp(rawData?.i_temperature_steam_generator_1)
  data.VFB1 = transformOpenClose(rawData?.o_valve_fill_steam_generator_1)
  data.VFB2 = transformOpenClose(rawData?.o_valve_fill_steam_generator_2)
  data.VVC = transformOpenClose(rawData?.o_valve_vent_chamber)
  data.VEC = transformOpenClose(rawData?.o_valve_exhaust_1)
  data.VDR = transformOpenClose(rawData?.o_valve_water_drain)
  data.heatExchanger = transformTemp(rawData?.i_temperature_heat_exchanger)

  data.chamberTemp = transformTemp(rawData?.i_temperature_chamber)
  data.chamberPuressure = transformPressure(rawData?.i_pressure_chamber)

  data.bandHeater1 = transformTemp(rawData?.i_temperature_band_heater_top)
  data.bandHeater2 = transformTemp(rawData?.i_temperature_band_heater_bottom)
  data.VDHE = transformOpenClose(rawData?.o_valve_exhaust_2)

  data.currentVFB1 = transformCurrent(rawData?.i_current_VFB1)
  data.currentVFB2 = transformCurrent(rawData?.i_current_VFB2)
  data.currentVEC = transformCurrent(rawData?.i_current_VEC)
  data.currentVDHE = transformCurrent(rawData?.i_current_VDHE)
  data.currentVVC = transformCurrent(rawData?.i_current_VVC)
  data.currentVFW = transformCurrent(rawData?.i_current_VFW)
  data.currentVDR = transformCurrent(rawData?.i_current_VDR)

  data.steamGenerator1PowerTarget = transformNumber(rawData?.o_steam_generator_1_power_target)
  data.steamGenerator1TempTarget = transformTemp(rawData?.o_steam_generator_1_temperature_target)
  data.steamGenerator1PowerTrack1Target = transformPercentage(rawData?.o_steam_generator_1_power_track_1)
  data.steamGenerator1PowerTrack1Curr = transformCurrentAmp(rawData?.i_current_steam_generator_1_track_1)
  data.steamGenerator1PowerTrack2Target = transformPercentage(rawData?.o_steam_generator_1_power_track_2)
  data.steamGenerator1PowerTrack2Curr = transformCurrentAmp(rawData?.i_current_steam_generator_1_track_2)

  data.steamGenerator2PowerTarget = transformNumber(rawData?.o_steam_generator_2_power_target)
  data.steamGenerator2TempTarget = transformTemp(rawData?.o_steam_generator_2_temperature_target)
  data.steamGenerator2PowerTrack1Target = transformPercentage(rawData?.o_steam_generator_2_power_track_1)
  data.steamGenerator2PowerTrack1Curr = transformCurrentAmp(rawData?.i_current_steam_generator_2_track_1)
  data.steamGenerator2PowerTrack2Target = transformPercentage(rawData?.o_steam_generator_2_power_track_2)
  data.steamGenerator2PowerTrack2Curr = transformCurrentAmp(rawData?.i_current_steam_generator_2_track_2)

  data.fanHeaterExchanger = transformOnOff(rawData?.o_fan_heat_exchanger)
  data.currentFHE = transformCurrent(rawData?.i_current_FHE)

  data.fanLCD = transformOnOff(rawData?.o_fan_lcd)
  data.currentLCDF = transformOnOff(rawData?.i_current_LCDF)

  data.waterPumpFillSG1 = transformOnOff(rawData?.o_water_pump_fill_steam_generator_1)
  data.currentPW1 = transformCurrentAmp(rawData?.i_current_PW1)
  data.waterPumpFillSG2 = transformOnOff(rawData?.o_water_pump_fill_steam_generator_2)
  data.currentPW2 = transformCurrentAmp(rawData?.i_current_PW2)
  data.waterPumpFillReservoir = transformOnOff(rawData?.o_water_pump_fill_reservoir)
  data.currentPWF = transformCurrent(rawData?.i_current_PWF)

  data.currentPV = transformCurrent(rawData?.i_current_PV)

  data.bandHeaterTopPowerTarget = transformNumber(rawData?.o_band_heater_top_power_target)
  data.bandHeaterTopTempTarget = transformTemp(rawData?.o_band_heater_top_temperature_target)
  data.bandHeaterTopPowerTrack1 = transformPercentage(rawData?.o_band_heater_top_power_track_1)
  data.currentBandHeaterTopTrack1 = transformCurrentAmp(rawData?.i_current_band_heater_top_track_1)
  data.bandHeaterTopPowerTrack2 = transformPercentage(rawData?.o_band_heater_top_power_track_2)
  data.currentBandHeaterTopTrack2 = transformCurrentAmp(rawData?.i_current_band_heater_top_track_2)

  data.bandHeaterBottomPowerTarget = transformNumber(rawData?.o_band_heater_bottom_power_target)
  data.bandHeaterBottomTempTarget = transformTemp(rawData?.o_band_heater_bottom_temperature_target)
  data.bandHeaterBottomPowerTrack1 = transformPercentage(rawData?.o_band_heater_bottom_power_track_1)
  data.currentBandHeaterBottomTrack1 = transformCurrentAmp(rawData?.i_current_band_heater_bottom_track_1)
  data.bandHeaterBottomPowerTrack2 = transformPercentage(rawData?.o_band_heater_bottom_power_track_2)
  data.currentBandHeaterBottomTrack2 = transformCurrentAmp(rawData?.i_current_band_heater_bottom_track_2)

  data.doorInputClosed = transformYesNo(rawData?.i_door_closed)
  data.doorLatched = transformYesNo(rawData?.i_door_latched)
  data.doorLocked = transformYesNo(rawData?.i_door_locked)
  data.doorOutputLocked = transformOnOff(rawData?.o_door_lock)
  data.currentDL = transformCurrent(rawData?.i_current_DL)

  data.topLevelSwich = transformOnOff(rawData?.i_level_switch_top_water_reservoir)
  data.bottomLevelSwich = transformOnOff(rawData?.i_level_switch_bottom_water_reservoir)

  return data
}

const transformOnOff = (val) => {
  if (val || val === 0) {
    return val === 1 ? 'ON' : 'OFF'
  }
  return NA
}

const transformOpenClose = (val) => {
  if (val || val === 0) {
    return val === 1 ? 'OPEN' : 'CLOSED'
  }
  return NA
}

const transformTemp = (val) => {
  return val || val === 0 ? val / 100 + ' \u{00B0}C' : NA
}

const transformPressure = (val) => {
  return val || val === 0 ? val / 100 + ' kPa' : NA
}

const transformCurrent = (val) => {
  return val || val === 0 ? val + ' mA' : NA
}

const transformCurrentAmp = (val) => {
  return val || val === 0 ? val / 1000 + ' A' : NA
}

const transformYesNo = (val) => {
  if (val || val === 0) {
    return val === 1 ? 'YES' : 'NO'
  }
  return NA
}

const transformNumber = (val) => {
  return val || val === 0 ? val : NA
}

const transformPercentage = (val) => {
  return val || val === 0 ? val + ' %' : NA
}

export default function DigitalTwin(props) {
  const { t } = useTranslation()
  const {
    rawData,
    rawHistoricInput,
    rawHistoricOutput,
    endTime,
    serNum,
    setEndTime,
    setRawHistoricOutput,
    setRawHistoricInput,
    setIsBackwards,
    isLatest,
    setIsLatest,
    setIsForwards,
    deviceModel,
  } = props
  const [data, setData] = useState([])
  const [historicInput, setHistoricInput] = useState([])
  const [historicOutput, setHistoricOutput] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const ref = useRef(null)
  const [width, setWidth] = useState(0)
  const [isDisabledBackwards, setIsDisabledBackwards] = useState(false)
  const isHydrim = Boolean(deviceModel?.toLowerCase().includes('hydrim'))

  useLayoutEffect(() => {
    if (ref.current) {
      const width = ref.current.offsetWidth
      setWidth(width)
    }
  }, [])

  useEffect(() => {
    isHydrim ? setData(rawData) : setData(dataParsing(rawData))
  }, [isHydrim, rawData])

  useEffect(() => {
    if (rawHistoricInput.length > 0) {
      const mappedHistoricInput = rawHistoricInput.map((item) => {
        return processHistoryInput(item)
      })

      setHistoricInput(mappedHistoricInput)
    }
  }, [rawHistoricInput])

  useEffect(() => {
    if (rawHistoricOutput.length > 0) {
      const mappedHistoricOutput = rawHistoricOutput.map((item) => {
        return processHistoryOutput(item)
      })

      setHistoricOutput(mappedHistoricOutput)
    }
  }, [rawHistoricOutput])

  const backOneMinute = () => {
    if (isLoading) {
      return
    }
    setIsBackwards(true)
    setIsLatest(false)
    setIsForwards(false)

    const newEndTime = new Date(endTime.getTime() - 60_000)
    setEndTime(newEndTime)
    const requestBody = {
      fetch: 'previous',
      timestamp: newEndTime.toISOString(),
    }
    refreshData(requestBody, true, false)
  }

  const forwardOneMinute = () => {
    if (isLoading) {
      return
    }

    setIsBackwards(false)
    setIsDisabledBackwards(false)
    setIsLatest(false)
    setIsForwards(true)

    const newEndTime = new Date(endTime.getTime() + 60_000)
    setEndTime(newEndTime)
    const requestPayload = {
      // cycleStart: new Date(newEndTime.getTime() - 5 * 60_000).toISOString(),
      fetch: 'next',
      timestamp: newEndTime.toISOString(),
    }
    refreshData(requestPayload, false, true)
  }

  const resetToNow = () => {
    if (isLoading) {
      return
    }

    refreshLatest()
  }

  const refreshLatest = () => {
    setIsLoading(true)
    setIsDisabledBackwards(false)
    setIsBackwards(false)
    setIsLatest(true)
    setIsForwards(false)

    try {
      return Promise.all([
        fetchLatest(serNum).catch((e) => {
          console.log(e.message)
          return {}
        }),
      ]).then(([historicData]) => {
        setRawHistoricInput((prevRawHistoricInput) => [...prevRawHistoricInput, historicData])
        setRawHistoricOutput((prevRawHistoricOutput) => [...prevRawHistoricOutput, historicData])
        setTimeout(() => setIsLoading(false), 500)
      })
    } catch (e) {
      setIsLoading(false)
      console.log(e.message)
    }
  }

  const refreshData = async (requestPayload, isBackwards, isForwards) => {
    setIsLoading(true)

    Promise.all([fetchPrevNextMinute(serNum, requestPayload)])
      .then(([historicData]) => {
        if (isBackwards && historicData.__date === rawHistoricInput[0].__date) {
          toast.error(t('no_data_found'))
          setIsDisabledBackwards(true)
        }

        setRawHistoricInput((prevRawHistoricInput) => {
          if (isBackwards) {
            if (historicData.__date === prevRawHistoricInput[0].__date) {
              return prevRawHistoricInput
            }
            return [historicData, ...prevRawHistoricInput] // Prepend if isBackwards is true
          } else if (isForwards) {
            return [...prevRawHistoricInput, historicData] // Append if isForwards is true
          } else {
            return prevRawHistoricInput // No update if neither is true
          }
        })

        setRawHistoricOutput((prevRawHistoricOutput) => {
          if (isBackwards) {
            if (historicData.__date === prevRawHistoricOutput[0].__date) {
              return prevRawHistoricOutput
            }
            return [historicData, ...prevRawHistoricOutput] // Prepend if isBackwards is true
          } else if (isForwards) {
            return [...prevRawHistoricOutput, historicData] // Append if isForwards is true
          } else {
            return prevRawHistoricOutput // No update if neither is true
          }
        })
        setTimeout(() => setIsLoading(false), 500)
      })
      .catch((error) => {
        setIsLoading(false)
        toast.error(t('no_data_found'))
        console.log(error.message)
      })
  }

  const loading = <div className="api-loading-spinner"></div>

  return (
    <div className={`digital-twin-container ${isHydrim ? 'hydrim-background' : ''}`}>
      {isHydrim ? (
        <div ref={ref}>
          <DebugScreen rawData={data} deviceModel={deviceModel} width={width} rawHistoricInput={rawHistoricInput} />
        </div>
      ) : (
        <>
          <SterilizerDiagram data={data} />
          <TabularView data={data} />
          <div ref={ref} className={'chart-wrapper'}>
            <div className={'chart-title_temperature_pressure'}>{`${t('advance_troubleshooting_context.sendor_input_t_p')}`}</div>
            {isLoading && loading}
            <DTChart3 width={width} data={historicInput} />
            <div className={'chart-title'}>{`${t('advance_troubleshooting_context.sensor_input')}`}</div>
            {isLoading && loading}
            <DTChart1 width={width} data={historicInput} />
          </div>
          <div className={'digital-twin-buttons'}>
            <div>
              <Button className="button" onClick={backOneMinute} disabled={isDisabledBackwards}>
                {`\u2190 ${t('advance_troubleshooting_context.one_minute')}`}
              </Button>
              <Button className="button" onClick={resetToNow}>
                {`${t('advance_troubleshooting_context.show_latest')}`}
              </Button>
              <Button className="button" onClick={forwardOneMinute} disabled={isLatest}>
                {`${t('advance_troubleshooting_context.one_minute')} \u2192`}
              </Button>
            </div>
            <div className="message">{isLatest ? 'You are viewing latest data' : 'You are viewing previous data'}</div>
          </div>
          <div className={'chart-wrapper'}>
            {/* <div className={"expand"}>
            <div>
              <FontAwesomeIcon icon={faExpand} data-tip="Expand"/>
              <ReactTooltip place="top" type="dark" effect="solid"/>
            </div>
          </div> */}
            <div className={'chart-title'}>{`${t('advance_troubleshooting_context.sensor_output')}`}</div>
            {isLoading && loading}
            <DTChart2 width={width} historicOutput={historicOutput} />
          </div>
        </>
      )}
    </div>
  )
}