import React, { useEffect, useState } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import DOMPurify from 'dompurify'
import './rich-text-input.scss'

const RichTextInput = (props) => {
  const { value, onChangeValue, isReadOnly, hasError } = props
  const [editorValue, setEditorValue] = useState(value || '')

  useEffect(() => {
    if (value !== editorValue) {
      setEditorValue(value || '')
    }
  }, [value])

  const handleChange = (content) => {
    const sanitizedContent = DOMPurify.sanitize(content || '')
    setEditorValue(sanitizedContent)
    onChangeValue(sanitizedContent)
  }

  return (
    <div className={`rich-text-input ${hasError ? 'rich-text-error' : ''}`}>
      <ReactQuill
        value={editorValue}
        onChange={handleChange}
        readOnly={isReadOnly}
        theme="snow"
      />
    </div>
  )
}

export default RichTextInput