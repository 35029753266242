import React, { useEffect, useState, Fragment } from "react"
import { useTranslation } from "react-i18next"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { useHistory } from "react-router-dom"
import { Storage } from "aws-amplify"
import LinkCard from "./LinkCard"
import DocumentCard from "./DocumentCard"
import RequestHelpModal from "./RequestHelpModal"
import Breadcrumb from "../common/Breadcrumb"
import VideoModal from "../common/VideoModal"
import SimpleButton from "../common/SimpleButton"
import EmptyPageResults from "../common/EmptyPageResults"
import LoadingSpinner from "../common/LoadingSpinner"
import SearchWithFilter from "../common/SearchWithFilter"
import { toast } from "../common/Toast"
import { VideoPlayIcon } from "../../icons"
import { useWindowSize } from "../../libs/hooks"
import { loadBulletins } from "../../actions/bulletins"
import { loadTSBDocuments, clearDocumentsMessages } from "../../actions/documents"
import { getVideosList } from "../../utils/requests/videosAPI"
import { Pages } from "../../utils/pages"
import { currentDay } from "../../utils/filters/date"
import { alphanumericValidationSchema } from "../../utils/formValidation"
import { keyboardEvents } from "../../utils/keyboardEvents"
import { productsPermission } from "../../utils/permissionValidation"
import { useAppContext } from "../../libs/contextLib"

function DealerTechDashboard(props) {
  const { t, i18n } = useTranslation()
  const isMobile = useWindowSize()[0] <= 768
  const history = useHistory()
  const { hasOrganizationAccess } = useAppContext()
  const { linkCards } = props
  const [searchedSerialNumber, setSearchedSerialNumber] = useState("")
  const [availableCards, setAvailableCards] = useState([])
  const [dealerBulletins, setDealerBulletins] = useState([])
  const [tsbDocuments, setTsbDocuments] = useState([])
  const [userPermissions, setUserPermissions] = useState([])
  const [videos, setVideos] = useState([])
  const [selectedVideo, setSelectedVideo] = useState(null)
  const [showSpinner, setShowSpinner] = useState(null)
  const [isFirstLoad, setIsFirstLoad] = useState(true)
  const [showRequestHelpModal, setShowRequestHelpModal] = useState(false)
  const [hasBulletinPermission, setHasBulletinPermission] = useState(false)
  const documentsLimit = 10
  const organizationAccessPaths = [ Pages.priceLists.route, Pages.dealerBulletins.route ]
  
  useEffect(() => {
    const permissionArr = props.userPermissions ? (Array.isArray(props.userPermissions) ? props.userPermissions : Object.values(props.userPermissions)) : []
    setUserPermissions(permissionArr)
    
    const bulletinsPermission = permissionArr.includes(Pages.dealerBulletins.permission)
    setHasBulletinPermission(bulletinsPermission)
    
    if (bulletinsPermission && hasOrganizationAccess) {
      handleLoadBulletins()
      return
    }

    if (props.tsbDocuments.length === 0) {
      handleLoadDocuments()
    }
  }, [props.tsbDocuments.length, props.userPermissions])

  useEffect(() => {
    if (linkCards?.length > 0 && userPermissions.length > 0) {
      const filteredAvailableCards = linkCards.filter(c => userPermissions.includes(c.permission) && withOrganizationAccess(c.path))
      setAvailableCards(filteredAvailableCards)
    }
  }, [userPermissions])

  useEffect(() => {
    if (props.tsbDocuments.length > 0) {
      const getDocumentNumber = (documentData) => {
        const documentNumberComponents = documentData.split("-")

        return Number(documentNumberComponents[2])
      }

      const userPrefferedLanguage = i18n.language?.split("-")[0]?.toUpperCase() || "EN"
      const maxDisplayedDocuments = 7
      let filteredTsbDocuments = []
      let currentTsbNumbers = []

      let currentIndex = 0
      while(filteredTsbDocuments.length < maxDisplayedDocuments && currentIndex < props.tsbDocuments.length) {
        const currentDocument = props.tsbDocuments[currentIndex]
        const currentDocumentNumber = getDocumentNumber(currentDocument.document_number)

        if (currentDocument.document_language === userPrefferedLanguage) {
          filteredTsbDocuments.push(currentDocument)
          currentTsbNumbers.push(currentDocumentNumber)
        } else {
          const nextDocumentNumber = currentIndex + 1 < props.tsbDocuments.length ? getDocumentNumber(props.tsbDocuments[currentIndex + 1].document_number) : null
          
          if (currentDocumentNumber === nextDocumentNumber) {
            filteredTsbDocuments.push(props.tsbDocuments[currentIndex + 1])
            currentTsbNumbers.push(nextDocumentNumber)
            currentIndex++
          } else {
            if (!currentTsbNumbers.includes(currentDocumentNumber)) {
              filteredTsbDocuments.push(currentDocument)
              currentTsbNumbers.push(currentDocumentNumber)
            }
          }
        }

        currentIndex++
      }

      setTsbDocuments(filteredTsbDocuments)
    }
  }, [props.tsbDocuments])
  
  useEffect(() => {
    if (props.bulletins.length > 0) {
      const maxDisplayedDocuments = 7
      
      const bulletinMap = new Map()
    
      props.bulletins.forEach(bulletin => {
        if (!bulletinMap.has(bulletin.bulletinType)) {
          bulletinMap.set(bulletin.bulletinType, bulletin)
        }
      })
    
      let filteredBulletins = Array.from(bulletinMap.values())

      if (filteredBulletins.length < maxDisplayedDocuments) {
        const additionalBulletins = props.bulletins.filter(bulletin => 
          !filteredBulletins.includes(bulletin)
        )
  
        filteredBulletins = [
          ...filteredBulletins,
          ...additionalBulletins.slice(0, maxDisplayedDocuments - filteredBulletins.length)
        ]
      }

      setDealerBulletins(filteredBulletins)
    }
  }, [props.bulletins])

  useEffect(() => {
    const getVideos = async () => {
      setShowSpinner(true)

      const videosQueryParams = {
        page: 1,
        limit: 4
      }

      const videosData = await getVideosList(videosQueryParams)

      if (videosData.data) {
        setVideos(videosData.data)
      }

      setShowSpinner(false)
    }

    getVideos()
  },[props.authedUser])

  useEffect(() => {
    if (props.errorMessage) {
      toast.error(props.errorMessage)

      props.actions.clearDocumentsMessages()
    }
  }, [props.errorMessage])

  useEffect(() => {
    setShowSpinner(props.isLoading)
  }, [props.isLoading])

  const handleLoadBulletins = () => {
    //TODO: Add limit after demo
    props.actions.loadBulletins().then(() => setIsFirstLoad(false))
  }
  
  const handleLoadDocuments = async () => {
    let queryParams = {
      page: 1,
      lang: [
        i18n.language?.split("-")[0]?.toUpperCase() || "",
        "EN",
      ]
    }

    await props.actions.loadTSBDocuments(queryParams, true, documentsLimit)
    setIsFirstLoad(false)
    
    if (props.tsbDocuments.length < documentsLimit ) {
      const remainingSlots = documentsLimit - props.tsbDocuments.length
      fetchOtherLanguageDocuments(remainingSlots)
    }
  }
  
  const fetchOtherLanguageDocuments = async (remainingSlots) => {
    let queryParams = {
      page: 1
    }
  
    props.actions.loadTSBDocuments(queryParams, true, remainingSlots)
  }

  const handleSearch = (value) => {
    setSearchedSerialNumber(value)
  }

  const handleViewProduct = async() => {
    const alphanumericSchema = alphanumericValidationSchema()
    
    try {
      await alphanumericSchema.validate(searchedSerialNumber)
      history.push(`/products/${searchedSerialNumber.toUpperCase()}`)
    } catch {
      toast.error(t("error.invalid_serial_number"));
    }
  }

  async function handleDownload(filePath, fileName) {
    Storage.configure({
      customPrefix: {
        public: "",
        private: "documents/private/",
      },
    })

    const result = await Storage.get(`${filePath}${fileName}`)
    window.open(result, "_blank")
  }

  const openVideoModal = (item) => {
    setSelectedVideo(item)
  }

  const closeVideoModal = () => {
    setSelectedVideo(null)
  }

  const navigateToBulletins = () => {
    history.push({
      pathname: Pages.dealerBulletins.route,
    })
  }
  
  const navigateToDocuments = () => {
    history.push({
      pathname: Pages.documents.route,
      state: {
        activeIndex: 1,
      },
    })
  }

  const withOrganizationAccess = (cardPath) => {
    return !organizationAccessPaths.includes(cardPath) || hasOrganizationAccess
  }

  return (
    <div className="dealer-technician-dashboard-wrapper">
      <Breadcrumb path={window.location.pathname} />
      <div className={`dashboard-title d-flex ${isMobile ? "flex-column" : "flex-row"} flex-justify-between`}>
        <h1>{t("dashboard.welcome", { firstName: props.authedUser?.firstname, lastName: props.authedUser?.lastname })}</h1>
        <div>{currentDay()}</div>
      </div>
      <div className="search-product-wrapper">
        {userPermissions.includes(productsPermission) && (
          <>
            <SearchWithFilter
              onSearch={(e) => handleSearch(e)}
              placeholder={t("serial_number")}
              onKeyDown={(e) => e.key === "Enter" && handleViewProduct()}
            />
            <SimpleButton
              className="submit-button"
              disabled={searchedSerialNumber.length < 6}
              onClick={() => handleViewProduct()}
            >
              {t("dashboard.view_product")}
            </SimpleButton>
          </>
        )}
      </div>
      <div className={`dashboard-content d-flex ${isMobile ? "flex-column" : "flex-row"} flex-justify-between`}>
        <div className={`links-wrapper d-flex ${isMobile ? "with-order-2 flex-column" : "flex-row"}`}>
          {availableCards.map((card, index) => {
            return (
              <Fragment key={index}>
                <LinkCard 
                  cardContent={card}
                  openRequestHelpModal={() => {
                    setShowRequestHelpModal(true)
                  }}
                />
              </Fragment>
            )
          })}
        </div>
        <div className={`documents-wrapper ${isMobile ? "with-order-1" : ""} ${availableCards.length > 4 ? "large-dashboard" : ""}`}>
          <div className="documents-title d-flex flex-align-center">{hasBulletinPermission && hasOrganizationAccess ? t("dashboard.recent_bulletins") : t("dashboard.your_documents")}</div>
          <div className={`documents-list ${showSpinner ? "hide-scrollbar" : ""}`}>
            {hasBulletinPermission && hasOrganizationAccess ?  
              (dealerBulletins.map((document, index) => 
                <DocumentCard
                  key={`document-card-${index}`}
                  type= "bulletin"
                  cardContent={document}
                  handleDownload={handleDownload}
                  isLast={index === dealerBulletins.length - 1} 
                />)
              ) : 
              (tsbDocuments.map((document, index) => 
                <DocumentCard
                  key={`document-card-${index}`}
                  type="tsb"
                  cardContent={document}
                  handleDownload={handleDownload}
                  isLast={index === tsbDocuments.length - 1} 
                />)
              )
            }
            {!showSpinner && !isFirstLoad && (hasBulletinPermission && hasOrganizationAccess ? dealerBulletins.length === 0 : tsbDocuments.length === 0) && (
              <EmptyPageResults className="empty-results" title={t("document_context.no_documents_found")} subtitle={t("cannot_find_matching_search")} />
            )}
          </div>
          <div className="buttons-wrapper d-flex flex-justify-center">
            <SimpleButton className="action-button" onClick={() => hasBulletinPermission && hasOrganizationAccess ? navigateToBulletins() : navigateToDocuments()}>
              {t("view_more")}
            </SimpleButton>
          </div>
          {showSpinner && !isFirstLoad && (
            <div className="documents-spinner-wrapper">
              <LoadingSpinner />
            </div>
          )}
        </div>
      </div>
      {videos.length > 0 && (
        <div className="videos-content d-flex flex-column">
          <div className="section-title">{t("nav.videos")}</div>
          <div className="videos-wrapper">
            {videos.map((video, index) => (
              <div
                key={index}
                className="video-item-wrapper"
                onClick = {() => openVideoModal(video)}
                tabIndex={0}
                role="button"
                onKeyDown={(e) => e.key === keyboardEvents.ENTER && openVideoModal(video)}
              >
                <div className="thumbnail-wrapper">
                  <div className="thumbnail-icon">
                    <VideoPlayIcon />
                  </div>
                  <img
                    src={video.thumbnail_url}
                    alt="video"
                  />
                </div>                
                <p className="title">{video.title}</p>
              </div>))
            }
          </div>               
        </div>
      )}
      {selectedVideo &&
        <VideoModal
          title={selectedVideo.title}
          videoId={selectedVideo.video_id}
          onCloseVideo={closeVideoModal}
        />
      }
      {showSpinner && isFirstLoad && (
        <div className={"spinner-wrapper"}>
          <LoadingSpinner />
        </div>
      )}
      {showRequestHelpModal && (
        <RequestHelpModal handleClose={() => { 
          setShowRequestHelpModal(false)
        }} />
      )}
    </div>
  )
}

function mapStateToProps({ authedUser, bulletins, documents, userPermissions }) {
  return {
    authedUser,
    bulletins: bulletins?.bulletins || [],
    tsbDocuments: documents?.tsbDocuments || [],
    isLoading: documents?.isLoading,
    errorMessage: documents?.errorMessage,
    userPermissions
  }
}

function dispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        loadBulletins,
        loadTSBDocuments,
        clearDocumentsMessages,
      },
      dispatch
    ),
  }
}

export default connect(mapStateToProps, dispatchToProps)(DealerTechDashboard)
