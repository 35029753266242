import {
  LOAD_COMPANIES_PENDING,
  LOAD_COMPANIES_FULFILLED,
  LOAD_COMPANIES_REJECTED,
  LOAD_NEW_COMPANIES_PENDING,
  LOAD_NEW_COMPANIES_FULFILLED,
  LOAD_NEW_COMPANIES_REJECTED,
  POST_COMPANY_PENDING,
  POST_COMPANY_FULFILLED,
  POST_COMPANY_REJECTED,
  PUT_COMPANY_PENDING,
  PUT_COMPANY_FULFILLED,
  PUT_COMPANY_REJECTED,
  PUT_CONVERT_ORGANIZATION_TO_INDIVIDUAL_PENDING,
  PUT_CONVERT_ORGANIZATION_TO_INDIVIDUAL_FULFILLED,
  PUT_CONVERT_ORGANIZATION_TO_INDIVIDUAL_REJECTED,
  DELETE_COMPANY_PENDING,
  DELETE_COMPANY_FULFILLED,
  DELETE_COMPANY_REJECTED,
  LOAD_COMPANIES_COUNTRY_OPTIONS_PENDING,
  LOAD_COMPANIES_COUNTRY_OPTIONS_FULFILLED,
  LOAD_COMPANIES_COUNTRY_OPTIONS_REJECTED,
  LOAD_COMPANY_ROLES_PENDING,
  LOAD_COMPANY_ROLES_FULFILLED,
  LOAD_COMPANY_ROLES_REJECTED,
  LOAD_COMPANY_ROLE_PERMISSIONS_FULFILLED,
  LOAD_COMPANY_ROLE_PERMISSIONS_PENDING,
  LOAD_COMPANY_ROLE_PERMISSIONS_REJECTED,
  CLEAR_COMPANY_ROLES,
  CLEAR_COMPANIES_MESSAGES,
} from "../actions/companies"
import i18n from 'i18next'

export default function companies(state = {}, action) {
  switch (action.type) {
    case LOAD_COMPANIES_PENDING:
    case LOAD_NEW_COMPANIES_PENDING:
    case POST_COMPANY_PENDING:
    case PUT_COMPANY_PENDING:
    case PUT_CONVERT_ORGANIZATION_TO_INDIVIDUAL_PENDING:
    case DELETE_COMPANY_PENDING:
    case LOAD_COMPANIES_COUNTRY_OPTIONS_PENDING:
    case LOAD_COMPANY_ROLES_PENDING:
    case LOAD_COMPANY_ROLE_PERMISSIONS_PENDING:
      return {
        ...state,
        isLoading: true,
        errorMessage: null,
        successMessage: null,
      }
    case LOAD_COMPANIES_REJECTED:
    case LOAD_NEW_COMPANIES_REJECTED:
    case POST_COMPANY_REJECTED:
    case PUT_COMPANY_REJECTED:
    case PUT_CONVERT_ORGANIZATION_TO_INDIVIDUAL_REJECTED:
    case DELETE_COMPANY_REJECTED:
    case LOAD_COMPANIES_COUNTRY_OPTIONS_REJECTED:
    case LOAD_COMPANY_ROLES_REJECTED:
      return {
        ...state,
        isLoading: false,
        errorMessage: i18n.t([`error.${action.payload.response?.data?.code}`, "error.something_wrong"])
      }
    case LOAD_COMPANY_ROLE_PERMISSIONS_REJECTED:
      return {
        ...state,
        isLoading: false,
      }
    case LOAD_COMPANIES_FULFILLED:
      const companies = state?.companies || []
      const newCompaniesIds = action.payload.data?.map(item => item.id)
      const difference = companies
        .filter(x => !newCompaniesIds.includes(x.id))
        .concat(action.payload.data)

      return {
        ...state,
        companies: difference,
        canLoadMore: action.payload.currentPage < action.payload.totalPages,
        isLoading: false,
      }
    case LOAD_NEW_COMPANIES_FULFILLED:
      return {
        ...state,
        companies: action.payload.data,
        canLoadMore: action.payload.currentPage < action.payload.totalPages,
        isLoading: false,
      }
    case POST_COMPANY_FULFILLED:
      const initialCompanies = state?.companies || [];

      return {
        ...state,
        companies: [...initialCompanies, action.payload],
        isLoading: false,
        successMessage: i18n.t("organization_context.success_add_company")
      }
    case PUT_COMPANY_FULFILLED:
      const updatedCompanies = state?.companies?.map((item) => item.id === action.payload.id ? {...action.payload} : item)

      return {
        ...state,
        companies: updatedCompanies,
        isLoading: false,
        successMessage: i18n.t("organization_context.success_edit_company")
      }
    case PUT_CONVERT_ORGANIZATION_TO_INDIVIDUAL_FULFILLED:
      const companiesWithoutConvertedOne = state.companies?.filter(company => company.id !== action.meta.companyId) || []

      return {
        ...state,
        companies: companiesWithoutConvertedOne,
        isLoading: false,
        successMessage: i18n.t("organization_context.success_convert_to_individual")
      }
    case DELETE_COMPANY_FULFILLED:
      const filteredCompanies = state.companies?.filter(company => company.id !== action.meta.companyId) || []
      return {
        ...state,
        companies: filteredCompanies,
        isLoading: false,
        successMessage: i18n.t("organization_context.company_deleted"),
      }
    case LOAD_COMPANIES_COUNTRY_OPTIONS_FULFILLED:
      return {
        ...state,
        countryOptions: action.payload,
        isLoading: false,
      }
    case LOAD_COMPANY_ROLES_FULFILLED:
      return {
        ...state,
        roles: action.payload,
        isLoading: false,
      }
    case LOAD_COMPANY_ROLE_PERMISSIONS_FULFILLED:
      const currentRolePermissions = state?.rolePermissions || []
      const newRolePermissions = {
        roleId: action.meta.roleId,
        permissions: action.payload || [],
      }
  
      return {
        ...state,
        isLoading: false,
        rolePermissions: [...currentRolePermissions, newRolePermissions],
      }
    case CLEAR_COMPANIES_MESSAGES: {
      return {
        ...state,
        errorMessage: null,
        successMessage: null,
      }
    }
    case CLEAR_COMPANY_ROLES: {
      return {
        ...state,
        roles: null,
      }
    }
    default:
      return state
  }
}
  