import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { connect } from "react-redux"
import { Storage } from "aws-amplify"
import TemplateNameSetupModal from "./TemplateNameSetupModal"
import LoadingSpinner from "../common/LoadingSpinner"
import SimpleButton from "../common/SimpleButton"
import { toast } from "../common/Toast"
import ConfirmationModal from "../common/ConfirmationModal"
import TextBuilderToolbox from "../common/ColteneTemplatesComponents/TextBuilderToolbox"
import LinkBuilderToolbox from "../common/ColteneTemplatesComponents/LinkBuilderToolbox"
import SectionBuilderToolbox from "../common/ColteneTemplatesComponents/SectionBuilderToolbox"
import VideoBuilderToolbox from "../common/ColteneTemplatesComponents/VideoBuilderToolbox"
import ImageBuilderToolbox from "../common/ColteneTemplatesComponents/ImageBuilderToolbox"
import CarouselBuilderToolbox from "../common/ColteneTemplatesComponents/CarouselBuilderToolbox"
import FlexContainerBuilderToolbox from "../common/ColteneTemplatesComponents/FlexContainerBuilderToolbox"
import SectionBuilder from "../common/ColteneTemplatesComponents/SectionBuilder"
import ImageBuilder from "../common/ColteneTemplatesComponents/ImageBuilder"
import VideoBuilder from "../common/ColteneTemplatesComponents/VideoBuilder"
import CarouselBuilder from "../common/ColteneTemplatesComponents/CarouselBuilder"
import { keyboardEvents } from "../../utils/keyboardEvents"
import { ArrowLeftIcon, BackSimpleIcon } from "../../icons"
import { colteneTemplatesWritePermission } from "../../utils/permissionValidation"
import "./template-editor.scss"

const defaultHeroSection = {
  id: "hero-main",
  component: "section",
  imageKey: "coltene-landing/images/default-hero-main.png",
  children: [
    {
      id: "hero-title",
      component: "text",
      style: { fontSize: "4.2rem", fontWeight: "700" },
      value: "Smarter Together",
      translation: {
        fr_CA: 'Plus intelligents ensemble',
        de_DE: 'Gemeinsam smarter',
        fr_FR: 'Plus intelligents ensemble',
        it_IT: 'Più intelligenti insieme',
        es_ES: 'Juntos somos más inteligentes',
        es_MX: 'Juntos somos más inteligentes',
        pt_BR: 'Mais inteligentes, juntos',
      },
    },
    {
      id: "hero-subtitle",
      component: "text",
      style: { fontSize: "2rem", marginTop: "2rem", marginBottom: "2rem" },
      value: "Optimize your equipment. Empower your team.",
      translation: {
        fr_CA: 'Optimisez votre équipement. Donnez de l’autonomie à votre équipe.',
        de_DE: 'Optimieren Sie Ihre Geräte. Stärken Sie Ihr Team.',
        fr_FR: 'Optimisez votre équipement. Rendez votre équipe plus performante.',
        it_IT: 'Ottimizzate le vostre apparecchiature. Potenziate il vostro team.',
        es_ES: 'Optimice su equipo. Potencie el trabajo de sus empleados.',
        es_MX: 'Optimice su equipo. Potencie a su equipo.',
        pt_BR: 'Otimizar seu equipamento. Capacitar sua equipe.',
      },
    },
    {
      id: "hero-button",
      component: "link",
      href: `${process.env.REACT_APP_HOST}`,
      value: "Sign In",
      translation: {
        fr_CA: 'Se connecter',
        de_DE: 'Anmelden',
        fr_FR: 'Connectez-vous',
        it_IT: 'Accedi',
        es_ES: 'Iniciar sesión',
        es_MX: 'Iniciar sesión',
        pt_BR: 'Entrar',
      },
    },
    {
      id: "hero-create-text-1",
      component: "text",
      style: { fontSize: "1rem", marginTop: "1rem" },
      value: "Don't have an account?",
      translation: {
        fr_CA: 'Vous n`avez pas de compte?',
        de_DE: 'Sie haben noch kein Konto?',
        fr_FR: 'Vous n`avez pas de compte?',
        it_IT: 'Non hai un account?',
        es_ES: '¿Todavía no tiene una cuenta?',
        es_MX: '¿Todavía no tiene una cuenta?',
        pt_BR: '',
      },
    },
    {
      id: "hero-create-text-2",
      component: "text",
      style: { fontSize: "1rem", color: "#01A0E9" },
      value: "Create one.",
      href: `${process.env.REACT_APP_HOST}/register`,
      translation: {
        fr_CA: 'Créez-en un.',
        de_DE: 'Hier registrieren.',
        fr_FR: 'Créez-en un.',
        it_IT: 'Creane uno.',
        es_ES: 'Registrar una cuenta.',
        es_MX: 'Registrar una cuenta.',
        pt_BR: '',
      },
    },
  ],
}

const defaultIntroSection = {
  id: "intro-section",
  component: "section",
  style: { backgroundColor: "#009AEB", paddingTop: "7.5rem", paddingBottom: "4.5rem" },
  children: [
    {
      id: "intro-text-1",
      component: "text",
      style: { fontSize: "3.8rem", fontWeight: "700" },
      value: "The myCOLTENE Advantage",
      translation: {
        fr_CA: 'L’avantage myCOLTENE',
        de_DE: 'Der myCOLTENE-Vorteil',
        fr_FR: 'L’avantage myCOLTENE',
        it_IT: 'Il vantaggio di myCOLTENE',
        es_ES: 'Ventajas de myCOLTENE',
        es_MX: 'La ventaja de myCOLTENE',
        pt_BR: 'A vantagem do myCOLTENE',
      },
    },
    {
      id: "intro-text-2",
      component: "text",
      style: { fontSize: "1.2rem", marginTop: "1.4rem", maxWidth: "55.5rem" },
      value: "Reduce anxiety and uncertainty related to new equipment and infenction control",
      translation: {
        fr_CA: 'Réduire l’anxiété et l’incertitude liées au nouvel équipement et au contrôle des infections.',
        de_DE: 'Reduzieren Sie Ängste und Unsicherheiten bei neuen Geräten und der Infektionskontrolle.',
        fr_FR: 'Réduisez l’anxiété et l’incertitude liées au nouvel équipement et au contrôle des infections.',
        it_IT: 'Riducete l’ansia e l’incertezza correlate alle nuove apparecchiature e al controllo delle infezioni.',
        es_ES: 'Reduzca la ansiedad y la incertidumbre relacionadas con los nuevos equipos y el control de infecciones.',
        es_MX: 'Reduzca la ansiedad y la incertidumbre relacionadas con los nuevos equipos y el control de infecciones.',
        pt_BR: 'Reduza a ansiedade e a incerteza relacionadas a novos equipamentos e o controle de infecções.',
      },
    },
    {
      id: "intro-text-3",
      component: "text",
      style: { fontSize: "1.2rem", marginBottom: "5rem", maxWidth: "55.5rem" },
      value: "Support your team with myCOLTENE, our digital platform that empowers staff with helpful tutorials, maintenance videos, support materials and more.",
      translation: {
        fr_CA: 'Soutenir votre équipe avec myCOLTENE, notre plateforme numérique qui permet au personnel de recevoir des tutoriels utiles, des vidéos d’entretien, du matériel de soutien et plus encore.',
        de_DE: 'Unterstützen Sie Ihr Team mit myCOLTENE, unserer digitalen Plattform, die Mitarbeitern hilfreiche Tutorials, Wartungsvideos, Supportmaterialien und mehr bietet.',
        fr_FR: 'Soutenez votre équipe avec myCOLTENE, notre plateforme numérique qui permet au personnel de bénéficier de tutoriels utiles, de vidéos de maintenance, de matériel d’accompagnements et plus encore.',
        it_IT: 'Supportate il vostro team con myCOLTENE, la nostra piattaforma digitale che offre al personale esercitazioni utili, video di manutenzione, materiali di supporto e altro ancora.',
        es_ES: 'Apoye la labor de sus trabajadores con myCOLTENE, la plataforma digital que permite al personal acceder a útiles tutoriales, vídeos de mantenimiento, material de apoyo y mucho más',
        es_MX: 'Apoye a su equipo con myCOLTENE, nuestra plataforma digital que brinda al personal útiles tutoriales, videos de mantenimiento, materiales de apoyo y más.',
        pt_BR: 'De apoio à sua equipe com o myCOLTENE, nossa plataforma digital que capacita a equipe com tutoriais úteis, vídeos de manutenção, materiais de apoio e muito mais.',
      },
    },
    {
      id: "intro-container-1",
      component: "flex-container",
      style: { gap: "1.5rem" },
      children: [
        {
          id: "machine-learning-container-1",
          component: "flex-container",
          style: { gap: "1.75rem", flexDirection: "column", width: "25rem", alignItems: "unset" },
          children: [
            {
              id: "machine-learning-image-1",
              component: "image",
              imageKey: "coltene-landing/images/default-intro-machine-learning.svg",
              style: { height: "7.5rem", width: "7.5rem" },
            },
            {
              id:"machine-learning-internal-container-1",
              component: "flex-container",
              style: { gap: "0.6rem", flexDirection: "column", minHeight: "11.5rem", borderRight: "1px solid #FFFFFF", justifyContent: "unset" },
              children: [
                {
                  id: "machine-learning-internal-title-1",
                  component: "text",
                  style: { fontSize: "2rem", fontWeight: "700" },
                  value: "On Demand Learning",
                  translation: {
                    fr_CA: 'Apprentissage sur demande',
                    de_DE: 'On-Demand-Learning',
                    fr_FR: 'Apprentissage à la demande',
                    it_IT: 'Apprendimento su richiesta',
                    es_ES: 'Formación a demanda',
                    es_MX: 'Aprendizaje a pedido',
                    pt_BR: 'Aprendizagem sob demanda',
                  },
                },
                {
                  id:"machine-learning-internal-1-container-1",
                  component: "flex-container",
                  style: { flexDirection: "column", gap: "0.4em", width: "100%" },
                  children: [
                    {
                      id: "machine-learning-internal-option-1",
                      component: "text",
                      style: { fontSize: "1rem" },
                      value: "• Tips from the equipment experts",
                      translation: {
                        fr_CA: '•	Conseils des experts en équipement',
                        de_DE: '•	Tipps von Geräteexperten',
                        fr_FR: '•	Conseils des experts de l’équipement',
                        it_IT: '•	Suggerimenti degli esperti delle apparecchiature',
                        es_ES: '•	Consejos de los expertos en equipos',
                        es_MX: '•	Consejos de los expertos en equipos',
                        pt_BR: '•	Dicas dos especialistas em equipamentos',
                      },
                    },
                    {
                      id: "machine-learning-internal-option-2",
                      component: "text",
                      style: { fontSize: "1rem" },
                      value: "• How-to video learning",
                      translation: {
                        fr_CA: '•	Apprentissage vidéo pratique',
                        de_DE: '•	Anleitungsvideos',
                        fr_FR: '•	Apprentissage vidéo pratique',
                        it_IT: '•	Apprendimento video pratico',
                        es_ES: '•	Formación sobre procedimientos en vídeo',
                        es_MX: '•	Aprendizaje en video instructivo',
                        pt_BR: '•	Aprendizagem em tutorias em vídeo',
                      },
                    },
                    {
                      id: "machine-learning-internal-option-3",
                      component: "text",
                      style: { fontSize: "1rem" },
                      value: "• Central locations for all manuals and guides",
                      translation: {
                        fr_CA: '•	Emplacements centraux pour tous les manuels et guides',
                        de_DE: '•	Zentraler Zugriff auf alle Handbücher und Leitfäden',
                        fr_FR: '•	Emplacements centraux pour tous les manuels et les guides',
                        it_IT: '•	Posizioni centralizzate per tutti i manuali e le guide',
                        es_ES: '•	Ubicaciones centralizadas para todos los manuales y guías',
                        es_MX: '•	Ubicaciones centrales para todos los manuales y guías',
                        pt_BR: '•	Localizações centralizadas para todos os manuais e guias',
                      },
                    },
                  ]
                }
              ]
            }
          ]
        },
        {
          id: "support-container-1",
          component: "flex-container",
          style: { gap: "1.75rem", flexDirection: "column", width: "25rem", alignItems: "unset" },
          children: [
            {
              id: "support-image-1",
              component: "image",
              imageKey: "coltene-landing/images/default-intro-support.svg",
              style: { height: "7.5rem", width: "7.5rem" },
            },
            {
              id:"support-internal-container-1",
              component: "flex-container",
              style: { gap: "0.6rem", flexDirection: "column", minHeight: "11.5rem", borderRight: "1px solid #FFFFFF", justifyContent: "unset" },
              children: [
                {
                  id: "support-internal-title-1",
                  component: "text",
                  style: { fontSize: "2rem", fontWeight: "700" },
                  value: "Device Support",
                  translation: {
                    fr_CA: 'Prise en charge des appareils',
                    de_DE: 'Geräte-Support',
                    fr_FR: 'Support de l’appareil',
                    it_IT: 'Supporto del dispositivo',
                    es_ES: 'Asistencia para dispositivos',
                    es_MX: 'Soporte del dispositivo',
                    pt_BR: 'Suporte a dispositivos',
                  },
                },
                {
                  id:"support-internal-1-container-1",
                  component: "flex-container",
                  style: { flexDirection: "column", gap: "0.4em", width: "100%" },
                  children: [
                    {
                      id: "support-internal-option-1",
                      component: "text",
                      style: { fontSize: "1rem" },
                      value: "• Maintenance Reminders",
                      translation: {
                        fr_CA: '•	Rappel d’entretien',
                        de_DE: '•	Wartungserinnerungen',
                        fr_FR: '•	Rappels de maintenance',
                        it_IT: '•	Promemoria di manutenzione',
                        es_ES: '•	Recordatorios de mantenimiento',
                        es_MX: '•	Recordatorios de mantenimiento',
                        pt_BR: '•	Lembretes de manutenção',
                      },
                    },
                    {
                      id: "support-internal-option-2",
                      component: "text",
                      style: { fontSize: "1rem" },
                      value: "• Remote support for fast troubleshooting",
                      translation: {
                        fr_CA: '•	Soutien à distance pour un dépannage rapide',
                        de_DE: '•	Remote-Support für rasche Fehlerbehebung',
                        fr_FR: '•	Assistance à distance pour un dépannage rapide',
                        it_IT: '•	Supporto remoto per una rapida risoluzione dei problemi',
                        es_ES: '•	Asistencia a distancia para resolver los problemas con rapidez',
                        es_MX: '•	Soporte remoto para una solución rápida de los problemas',
                        pt_BR: '•	Suporte remoto para solução rápida de problemas',
                      },
                    },
                    {
                      id: "support-internal-option-3",
                      component: "text",
                      style: { fontSize: "1rem" },
                      value: "• User-interface software update notifications",
                      translation: {
                        fr_CA: '•	Notifications de mise à jour du logiciel de l’interface utilisateur',
                        de_DE: '•	Benachrichtigungen zu Software-Updates der Benutzeroberfläche',
                        fr_FR: '•	Notifications de mise à jour du logiciel d’interface utilisateur',
                        it_IT: '•	Notifiche di aggiornamento software dell’interfaccia utente',
                        es_ES: '•	Notificaciones de actualización de software de interfaz de usuario',
                        es_MX: '•	Notificaciones de actualización del software de la interfaz de usuario',
                        pt_BR: '•	Notificações de atualização de software da interface do usuário',
                      },
                    },
                    {
                      id: "support-internal-option-4",
                      component: "text",
                      style: { fontSize: "1rem" },
                      value: "• Single-dashboard for device registrations",
                      translation: {
                        fr_CA: '•	Tableau de bord unique pour l’enregistrement des appareils',
                        de_DE: '•	Einfaches Dashboard für Geräteregistrierungen',
                        fr_FR: '•	Tableau de bord unique pour les enregistrements d’appareils',
                        it_IT: '•	Dashboard singola per le registrazioni dei dispositivi',
                        es_ES: '•	Panel único para registros de dispositivos',
                        es_MX: '•	Panel único para registros de dispositivos',
                        pt_BR: '•	Painel único para registros de dispositivos',
                      },
                    },
                  ]
                }
              ]
            }
          ]
        },
        {
          id: "traceability-container-1",
          component: "flex-container",
          style: { gap: "1.75rem", flexDirection: "column", width: "25rem", alignItems: "unset" },
          children: [
            {
              id: "traceability-image-1",
              component: "image",
              imageKey: "coltene-landing/images/default-intro-traceability.svg",
              style: { height: "7.5rem", width: "7.5rem" },
            },
            {
              id:"traceability-internal-container-1",
              component: "flex-container",
              style: { gap: "0.6rem", flexDirection: "column", minHeight: "11.5rem", justifyContent: "unset" },
              children: [
                {
                  id: "traceability-internal-title-1",
                  component: "text",
                  style: { fontSize: "2rem", fontWeight: "700" },
                  value: "Traceability",
                  translation: {
                    fr_CA: 'Traçabilité',
                    de_DE: 'Rückverfolgbarkeit',
                    fr_FR: 'Traçabilité',
                    it_IT: 'Tracciabilità',
                    es_ES: 'Trazabilidad',
                    es_MX: 'Trazabilidad',
                    pt_BR: 'Rastreabilidade',
                  },
                },
                {
                  id:"traceability-internal-1-container-1",
                  component: "flex-container",
                  style: { flexDirection: "column", gap: "0.4em", width: "100%" },
                  children: [
                    {
                      id: "traceability-internal-option-1",
                      component: "text",
                      style: { fontSize: "1rem" },
                      value: "• Enhanced load traceability options",
                      translation: {
                        fr_CA: '•	Options de traçabilité de charge améliorées',
                        de_DE: '•	Erweiterte Optionen zur Rückverfolgbarkeit von Ladungen',
                        fr_FR: '•	Options de traçabilité des charges optimisées',
                        it_IT: '•	Opzioni di tracciabilità del carico migliorate',
                        es_ES: '•	Opciones de trazabilidad de la carga mejoradas',
                        es_MX: '•	Opciones mejoradas de trazabilidad de carga',
                        pt_BR: '•	Opções aprimoradas de rastreabilidade de carga',
                      },
                    },
                    {
                      id: "traceability-internal-option-2",
                      component: "text",
                      style: { fontSize: "1rem" },
                      value: "• Secure cloud storage",
                      translation: {
                        fr_CA: '•	Stockage sécurisé dans le nuage',
                        de_DE: '•	Sicherer Cloud-Speicher',
                        fr_FR: '•	Stockage sécurisé dans le cloud',
                        it_IT: '•	Archiviazione cloud sicura',
                        es_ES: '•	Almacenamiento seguro en la nube',
                        es_MX: '•	Almacenamiento seguro en la nube',
                        pt_BR: '•	Armazenamento seguro na nuvem',
                      },
                    },
                    {
                      id: "traceability-internal-option-3",
                      component: "text",
                      style: { fontSize: "1rem" },
                      value: "• Improved instrument reprocessing reports",
                      translation: {
                        fr_CA: '•	Rapports de retraitement des instruments améliorés',
                        de_DE: '•	Verbesserte Berichte zur Instrumentenaufbereitung',
                        fr_FR: '•	Rapports de retraitement des instruments améliorés',
                        it_IT: '•	Rapporti sul ricondizionamento degli strumenti migliorati',
                        es_ES: '•	Informes de reprocesamiento de instrumentos mejorados',
                        es_MX: '•	Mejores informes de reprocesamiento de instrumentos',
                        pt_BR: '•	Relatórios aprimorados de reprocessamento de instrumentos',
                      },
                    },
                  ]
                }
              ]
            }
          ]
        },
      ]
    },
  ],
}

const defaultVideoSection = {
  id: "video-section",
  component: "video",
  style: { backgroundColor: "#FFFFFF" },
  href: "youtube.com/watch?v=uLIE-UbXZGU",
  translation: {
    fr_CA: '',
    de_DE: 'youtube.com/watch?v=hTiKWXSV4vw',
    fr_FR: '',
    it_IT: '',
    es_ES: 'youtube.com/watch?v=LCMveqVl2Ug',
    es_MX: 'youtube.com/watch?v=LCMveqVl2Ug',
    pt_BR: '',
  },
  children: [
    {
      id: "video-thumbnail-1",
      component: "image",
      imageKey: "coltene-landing/images/default-video-thumbnail-1.png",
      style: { height: "59.3rem", width: "100%" },
    },
  ],
}

const defaultFunctionalitySection = {
  id: "functionality-section",
  component: "section",
  style: { background: "linear-gradient(120deg, #121C35, #0461A5)", padding: "7.5rem" },
  children: [
    {
      id: "functionality-into-text-1",
      component: "text",
      style: { fontSize: "3.8rem", fontWeight: "700" },
      value: "myCOLTENE and your practice",
      translation: {
        fr_CA: 'myCOLTENE et votre pratique',
        de_DE: 'myCOLTENE und Ihre Praxis',
        fr_FR: 'myCOLTENE et votre cabinet',
        it_IT: 'myCOLTENE e il vostro studio',
        es_ES: 'myCOLTENE y su consulta',
        es_MX: 'myCOLTENE y su consulta',
        pt_BR: 'myCOLTENE e a sua prática clínica',
      },
    },
    {
      id: "functionality-into-text-2",
      component: "text",
      style: { fontSize: "1.4rem", marginTop: "1.5rem", marginBottom: "10.4rem" },
      value: "With myCOLTENE you can improve your practice in many ways…",
      translation: {
        fr_CA: 'Avec myCOLTENE, vous pouvez améliorer votre pratique de plusieurs façons…',
        de_DE: 'Mit myCOLTENE können Sie Ihre Praxis auf viele Arten verbessern...',
        fr_FR: 'Avec myCOLTENE, vous pouvez améliorer votre cabinet de nombreuses façons...',
        it_IT: 'Con myCOLTENE potete migliorare il vostro studio in molti modi...',
        es_ES: 'myCOLTENE mejora su consulta de muchas maneras...',
        es_MX: 'Con myCOLTENE puede mejorar su consulta de muchas maneras...',
        pt_BR: 'Com o myCOLTENE, você pode melhorar sua prática clínica de muitas formas...',
      },
    },
    {
      id: "functionality-container-1",
      component: "flex-container",
      style: { gap: "7.25rem"},
      children: [
        {
          id: "functionality-image-1",
          component: "image",
          imageKey: "coltene-landing/images/default-functionality-1.png",
          style: { height: "32.5rem", width: "37.5rem" },
        },
        {
          id: "functionality-1-container-1",
          component: "flex-container",
          style: { flexDirection: "column", gap: "1.1rem", width: "32rem" },
          children: [
            {
              id: "functionality-1-title-1",
              component: "text",
              style: { fontSize: "2.5rem", fontWeight: "700" },
              value: "Total Traceability for Teams",
              translation: {
                fr_CA: 'Traçabilité totale pour les équipes',
                de_DE: 'Gesamtrückverfolgbarkeit für Teams',
                fr_FR: 'Traçabilité totale pour les équipes',
                it_IT: 'Tracciabilità totale per i team',
                es_ES: 'Trazabilidad total para el personal',
                es_MX: 'Trazabilidad total para equipos',
                pt_BR: 'Rastreabilidade total para equipes',
              },
            },
            {
              id: "functionality-1-text-1",
              component: "text",
              style: { fontSize: "1rem" },
              value: "Tracking equipment use creates accountability and supports your office’s reprocessing protocols. Enhanced settings enable comprehensive instrument tracking options for your infection control equipment.",
              translation: {
                fr_CA: 'Le suivi de l’utilisation de l’équipement crée une responsabilité et soutient les protocoles de retraitement de votre bureau. Les réglages améliorés permettent des options complètes de suivi des instruments pour votre équipement de contrôle des infections.',
                de_DE: 'Die Nachverfolgung der Gerätenutzung schafft Verantwortlichkeit und unterstützt die Aufbereitungsprotokolle Ihres Büros. Erweiterte Einstellungen ermöglichen umfassende Optionen zur Instrumentenverfolgung für Ihre Infektionskontrollgeräte.',
                fr_FR: 'Le suivi de l’utilisation de l’équipement crée une responsabilité et étaye les protocoles de retraitement de votre cabinet. Des réglages optimisés permettent des options de suivi complet des instruments pour votre équipement de contrôle des infections.',
                it_IT: 'Il monitoraggio dell’uso delle apparecchiature crea responsabilità e supporta i protocolli di ritrattamento del vostro ufficio. Le impostazioni avanzate offrono opzioni complete di monitoraggio dello strumento per le apparecchiature di controllo delle infezioni.',
                es_ES: 'El seguimiento del uso del equipo permite exigir responsabilidades y sirve como apoyo para los protocolos de reprocesamiento de su consultorio. La configuración mejorada ofrece la posibilidad de acceder a opciones completas de seguimiento de los instrumentos para el equipo de control de infecciones.',
                es_MX: 'El seguimiento del uso del equipo crea responsabilidad y respalda los protocolos de reprocesamiento de su consultorio. La configuración mejorada permite opciones integrales de seguimiento de instrumentos para su equipo de control de infecciones.',
                pt_BR: 'O rastreamento do uso do equipamento cria responsabilidade e apoia os protocolos de reprocessamento do seu consultório. As configurações aprimoradas permitem opções abrangentes de rastreamento de instrumentos para seu equipamento de controle de infecções.',
              },
            },
          ]
        }
      ]
    },
    {
      id: "functionality-container-2",
      component: "flex-container",
      style: { gap: "7.25rem"},
      children: [
        {
          id: "functionality-2-container-1",
          component: "flex-container",
          style: { flexDirection: "column", gap: "1.1rem", width: "32rem" },
          children: [
            {
              id: "functionality-2-title-1",
              component: "text",
              style: { fontSize: "2.5rem", fontWeight: "700" },
              value: "Secured cloud storage and improved records",
              translation: {
                fr_CA: 'Stockage infonuagique sécurisé et dossiers améliorés',
                de_DE: 'Sicherer Cloud-Speicher und verbesserte Aufzeichnungen',
                fr_FR: 'Stockage sécurisé dans le cloud et enregistrements améliorés',
                it_IT: 'Archiviazione cloud sicura e record migliorati',
                es_ES: 'Almacenamiento seguro en la nube y registros mejorados',
                es_MX: 'Almacenamiento seguro en la nube y registros mejorados',
                pt_BR: 'Armazenamento seguro na nuvem e registros aprimorados',
              },
            },
            {
              id: "functionality-2-text-1",
              component: "text",
              style: { fontSize: "1rem" },
              value: "All your connected equipment cycle histories are organized for easy reporting. Every unit, every cycle, for the equipment’s lifetime. Access comprehensive cycle data, such as instrument tracking and user authentication, to generate audit reports.",
              translation: {
                fr_CA: 'Tous les historiques de cycles des équipements sont connectés et organisés pour faciliter la production de rapports. Chaque unité, chaque cycle, pour la durée de vie de l’équipement. Accédez à des données de cycle complètes, telles que le suivi des instruments et l’authentification des utilisateurs, pour générer des rapports de vérification.',
                de_DE: 'Alle Zyklusverläufe Ihrer verbundenen Geräte sind für eine einfache Berichterstellung organisiert. Jede Einheit, jeder Zyklus, für die gesamte Lebensdauer der Geräte. Greifen Sie auf umfassende Zyklusdaten wie Instrumentenverfolgung und Benutzerauthentifizierung zu, um Auditberichte zu erstellen.',
                fr_FR: 'Tous les historiques des cycles de vos équipements connectés sont organisés pour faciliter l’établissement de rapports. Chaque équipement, chaque cycle, pendant toute la durée de vie de l’équipement. Accédez à des données de cycle complètes, telles que le suivi des instruments et l’authentification des utilisateurs, pour générer des rapports d’audit.',
                it_IT: 'Tutte le cronologie dei cicli delle apparecchiature connesse sono organizzate in modo da assicurare una facile creazione di report. Ogni unità, ogni ciclo, per tutta la vita utile dell’apparecchiatura. Accedete a dati di ciclo completi, come il monitoraggio dello strumento e l’autenticazione dell’utente, per generare report di audit.',
                es_ES: 'Todos sus historiales de ciclos de equipos conectados se organizan para facilitar la elaboración de informes. Cada unidad, cada ciclo, durante toda la vida útil del equipo. Acceda a datos completos de los ciclos, como el seguimiento de los instrumentos y la autenticación de usuarios, para generar informes de auditoría.',
                es_MX: 'Todos sus historiales de ciclos de equipos conectados están organizados para facilitar el informe. Toda unidad, todo ciclo, durante la vida útil del equipo. Acceda a los datos integrales del ciclo, como el seguimiento de instrumentos y la autenticación de usuarios, para generar informes de auditoría.',
                pt_BR: 'Todos os históricos de ciclo dos seus equipamentos conectados são organizados para facilitar a elaboração de relatórios. Todas as unidades, todos os ciclos, por toda a vida útil do equipamento. Acesse dados de ciclo abrangentes, como rastreamento de instrumentos e autenticação de usuários, para gerar relatórios de auditoria.',
              },
            },
          ]
        },
        {
          id: "functionality-image-2",
          component: "image",
          imageKey: "coltene-landing/images/default-functionality-2.png",
          style: { height: "32.5rem", width: "37.5rem" },
        },
      ]
    },
    {
      id: "functionality-container-3",
      component: "flex-container",
      style: { gap: "7.25rem"},
      children: [
        {
          id: "functionality-image-3",
          component: "image",
          imageKey: "coltene-landing/images/default-functionality-3.png",
          style: { height: "32.5rem", width: "37.5rem" },
        },
        {
          id: "functionality-3-container-1",
          component: "flex-container",
          style: { flexDirection: "column", gap: "1.1rem", width: "32rem" },
          children: [
            {
              id: "functionality-3-title-1",
              component: "text",
              style: { fontSize: "2.5rem", fontWeight: "700" },
              value: "Learn and Go",
              translation: {
                fr_CA: 'Apprentissage et mise en œuvre',
                de_DE: 'Lernen und loslegen',
                fr_FR: 'Apprenez et commencez',
                it_IT: 'Imparate e iniziate a operare',
                es_ES: 'Formación instantánea',
                es_MX: 'Aprenda y avance',
                pt_BR: 'Aprenda e avance',
              },
            },
            {
              id: "functionality-3-text-1",
              component: "text",
              style: { fontSize: "1rem" },
              value: "New staff might need a refresher. Search the latest digital versions of quick start guides, manuals, tutorials, troubleshooting and installation instructions for quick and easy explanations.",
              translation: {
                fr_CA: 'Le nouveau personnel pourrait avoir besoin d’un rappel. Consultez les dernières versions numériques des guides de démarrage rapide, des manuels, des tutoriels, des instructions de dépannage et d’installation pour des explications rapides et faciles.',
                de_DE: 'Neue Mitarbeiter benötigen möglicherweise eine Auffrischung. Schnelle und einfache Erläuterungen finden Sie in den neuesten digitalen Versionen unserer Kurzanleitungen, Handbücher, Tutorials sowie Fehlerbehebungs- und Installationsanleitungen.',
                fr_FR: 'Le nouveau personnel pourrait avoir besoin d’une formation de mise à jour. Recherchez les dernières versions numériques des guides de démarrage rapide, des manuels, des didacticiels, des instructions de dépannage et d’installation pour obtenir des explications rapides et faciles.',
                it_IT: 'Il nuovo personale potrebbe aver bisogno di un aggiornamento. Cercate le ultime versioni digitali di guide di avvio rapido, manuali, esercitazioni, istruzioni per la risoluzione dei problemi e l’installazione per ottenere spiegazioni rapide e semplici.',
                es_ES: 'Es posible que el nuevo personal necesite actualizar sus conocimientos. Busque las últimas versiones digitales de guías de inicio rápido, manuales, tutoriales, directrices para la resolución de problemas e instrucciones de instalación para obtener explicaciones rápidas y sencillas.',
                es_MX: 'Es posible que el personal nuevo necesite un repaso. Busque las últimas versiones digitales de las guías de inicio rápido, manuales, tutoriales, resolución de problemas e instrucciones de instalación para obtener explicaciones rápidas y fáciles.',
                pt_BR: 'Novos funcionários podem precisar de atualização. Pesquise as versões digitais mais recentes de guias de início rápido, manuais, tutoriais, solução de problemas e instruções de instalação para obter explicações rápidas e fáceis.',
              },
            },
          ]
        }
      ]
    },
    {
      id: "functionality-container-4",
      component: "flex-container",
      style: { gap: "7.25rem"},
      children: [
        {
          id: "functionality-4-container-1",
          component: "flex-container",
          style: { flexDirection: "column", gap: "1.1rem", width: "32rem" },
          children: [
            {
              id: "functionality-4-title-1",
              component: "text",
              style: { fontSize: "2.5rem", fontWeight: "700" },
              value: "Keep Equipment at Peak Performance",
              translation: {
                fr_CA: 'Garder l’équipement à son meilleur',
                de_DE: 'Halten Sie Ihre Geräte auf Spitzenleistung',
                fr_FR: 'Maintenez l’équipement à son meilleur niveau de performance',
                it_IT: 'Mantenete le apparecchiature al massimo delle prestazioni',
                es_ES: 'Mantenga el máximo rendimiento del equipo',
                es_MX: 'Mantenga el equipo al máximo rendimiento',
                pt_BR: 'Mantenha o desempenho máximo do equipamento',
              },
            },
            {
              id: "functionality-4-text-1",
              component: "text",
              style: { fontSize: "1rem" },
              value: "Maintenance reminders help staff never forget to change your seal or filter again! Automatic reminders and email notifications take the guesswork out of maintenance.",
              translation: {
                fr_CA: 'Les rappels d’entretien aident le personnel à ne plus jamais oublier de changer un joint ou un filtre! Les rappels automatiques et les notifications par courriel éliminent les devinettes quant à la maintenance.',
                de_DE: 'Wartungserinnerungen helfen Mitarbeitern dabei, nie wieder zu vergessen, dass eine Dichtung oder ein Filter gewechselt werden muss! Automatische Erinnerungen und E-Mail-Benachrichtigungen sorgen dafür, dass es in puncto Wartung kein Rätselraten mehr gibt.',
                fr_FR: 'Les rappels de maintenance aident le personnel à ne jamais oublier de changer votre joint ou votre filtre ! Les rappels automatiques et les notifications par e-mail éliminent toute incertitude quant à la maintenance.',
                it_IT: 'I promemoria di manutenzione aiutano il personale a non dimenticare mai più di sostituire la guarnizione o il filtro! Promemoria automatici e notifiche e-mail eliminano le congetture dalla manutenzione.',
                es_ES: 'Los recordatorios de mantenimiento ayudan al personal a no olvidarse nunca de cambiar la junta o el filtro. Los recordatorios automáticos y las notificaciones por correo electrónico eliminan las conjeturas a la hora de realizar el mantenimiento.',
                es_MX: '¡Los recordatorios de mantenimiento ayudan al personal a nunca más olvidarse de cambiar su sello o filtro! Los recordatorios automáticos y las notificaciones por correo electrónico eliminan las conjeturas de mantenimiento.',
                pt_BR: 'Os lembretes de manutenção ajudam a equipe a nunca mais se esquecer de trocar a vedação ou o filtro novamente! Os lembretes automáticos e as notificações por e-mail eliminam as incertezas da manutenção.',
              },
            },
          ]
        },
        {
          id: "functionality-image-4",
          component: "image",
          imageKey: "coltene-landing/images/default-functionality-4.png",
          style: { height: "32.5rem", width: "37.5rem" },
        },
      ]
    },
    {
      id: "functionality-container-5",
      component: "flex-container",
      style: { gap: "7.25rem"},
      children: [
        {
          id: "functionality-image-5",
          component: "image",
          imageKey: "coltene-landing/images/default-functionality-5.png",
          style: { height: "32.5rem", width: "37.5rem" },
        },
        {
          id: "functionality-5-container-1",
          component: "flex-container",
          style: { flexDirection: "column", gap: "1.1rem", width: "32rem" },
          children: [
            {
              id: "functionality-5-title-1",
              component: "text",
              style: { fontSize: "2.5rem", fontWeight: "700" },
              value: "Remote Support and Equipment Check-ups",
              translation: {
                fr_CA: 'Soutien à distance et vérifications de l’équipement',
                de_DE: 'Remote-Support und Gerätechecks',
                fr_FR: 'Assistance et contrôles de l’équipement à distance',
                it_IT: 'Assistenza remota e controlli delle apparecchiature',
                es_ES: 'Asistencia y revisiones del equipo a distancia',
                es_MX: 'Soporte remoto y revisiones de equipos',
                pt_BR: 'Suporte remoto e check-ups de equipamentos',
              },
            },
            {
              id: "functionality-5-text-1",
              component: "text",
              style: { fontSize: "1rem" },
              value: "Experience less downtime when certified technicians can remotely troubleshoot your unit for faster, better service without having to visit your office!",
              translation: {
                fr_CA: 'Subissez moins de temps d’arrêt lorsque des techniciens certifiés peuvent dépanner votre appareil à distance sans avoir à visiter votre bureau, vous offrant un service plus rapide et de meilleure qualité!',
                de_DE: 'Zertifizierte Techniker müssen Sie nicht mehr im Büro aufsuchen, sondern können Fehler Ihrer Einheit aus der Ferne beheben. Dadurch genießen Sie weniger Ausfallzeiten und einen schnelleren, besseren Service!',
                fr_FR: 'Des techniciens certifiés peuvent dépanner votre appareil à distance pour un service plus rapide et de meilleure qualité, sans avoir à se rendre dans vos locaux !',
                it_IT: 'Riducete i tempi di fermo quando i tecnici certificati possono risolvere i problemi da remoto della vostra unità, per un servizio migliore e più veloce senza dovervi recare in ufficio!',
                es_ES: 'Reduzca el tiempo de inactividad gracias a técnicos certificados capaces de resolver los problemas de su unidad a distancia para un servicio más rápido y optimizado sin necesidad de acudir a su consultorio.',
                es_MX: '¡Experimente menos tiempo de inactividad ya que los técnicos certificados pueden solucionar problemas de manera remota en su unidad para que tenga un servicio mejor y más rápido sin tener que visitar su consultorio!',
                pt_BR: 'Ao reduzir substancialmente o tempo de inatividade, técnicos certificados podem solucionar problemas da sua unidade remotamente para proporcionar um serviço mais rápido e de melhor qualidade, sem a necessidade de visitar seu consultório!',
              },
            },
          ]
        }
      ]
    },
    {
      id: "functionality-container-6",
      component: "flex-container",
      style: { gap: "7.25rem"},
      children: [
        {
          id: "functionality-6-container-1",
          component: "flex-container",
          style: { flexDirection: "column", gap: "1.1rem", width: "32rem" },
          children: [
            {
              id: "functionality-6-title-1",
              component: "text",
              style: { fontSize: "2.5rem", fontWeight: "700" },
              value: "Keep Equipment Up to Date",
              translation: {
                fr_CA: 'Tenir l’équipement à jour',
                de_DE: 'Halten Sie Ihre Geräte auf dem neuesten Stand',
                fr_FR: 'Maintenez l’équipement à jour',
                it_IT: 'Mantenete l’apparecchiatura aggiornata',
                es_ES: 'Mantenga el equipo actualizado',
                es_MX: 'Mantenga el equipo actualizado',
                pt_BR: 'Mantenha o equipamento atualizado',
              },
            },
            {
              id: "functionality-6-text-1",
              component: "text",
              style: { fontSize: "1rem" },
              value: "Your myCOLTENE dashboard tells you if a user-interface software update is available for your registered equipment, so you can be confident your unit is at its best.",
              translation: {
                fr_CA: 'Votre tableau de bord myCOLTENE vous indique si une mise à jour du logiciel d’interface utilisateur est disponible pour votre équipement enregistré, afin que vous puissiez être sûr que votre appareil est au maximum de ses capacités.',
                de_DE: 'Ihr myCOLTENE-Dashboard informiert Sie, sobald ein Software-Update für die Benutzeroberfläche Ihrer registrierten Geräte verfügbar ist, damit Sie sicher sein können, dass Ihr Gerät optimal funktioniert.',
                fr_FR: 'Votre tableau de bord myCOLTENE vous indique si une mise à jour logicielle de l’interface utilisateur est disponible pour votre équipement enregistré, vous pouvez ainsi être sûr que votre appareil fonctionne de manière optimale.',
                it_IT: 'La dashboard myCOLTENE vi informa se è disponibile un aggiornamento software dell’interfaccia utente per le vostre apparecchiature registrate, in modo da poter essere sicuri che la vostra unità sia al meglio.',
                es_ES: 'El panel de myCOLTENE le indica si hay disponible una actualización del software de interfaz de usuario para su equipo registrado, de modo que podrá asegurarse de que su unidad esté en un estado óptimo en todo momento.',
                es_MX: 'Su panel myCOLTENE le indica si hay una actualización de software de interfaz de usuario disponible para su equipo registrado, para que pueda estar seguro de que su unidad está a tope.',
                pt_BR: 'O seu painel de controle myCOLTENE informa se uma atualização de software de interface de usuário está disponível para os seus equipamentos registrados, assim você pode ter certeza de que sua unidade está no estado ideal.',
              },
            },
          ]
        },
        {
          id: "functionality-image-6",
          component: "image",
          imageKey: "coltene-landing/images/default-functionality-6.png",
          style: { height: "32.5rem", width: "37.5rem" },
        },
      ]
    },
    {
      id: "functionality-container-7",
      component: "flex-container",
      style: { gap: "7.25rem"},
      children: [
        {
          id: "functionality-image-7",
          component: "image",
          imageKey: "coltene-landing/images/default-functionality-7.png",
          style: { height: "32.5rem", width: "37.5rem" },
        },
        {
          id: "functionality-7-container-1",
          component: "flex-container",
          style: { flexDirection: "column", gap: "1.1rem", width: "32rem" },
          children: [
            {
              id: "functionality-7-title-1",
              component: "text",
              style: { fontSize: "2.5rem", fontWeight: "700" },
              value: "Expert Advice and Tips",
              translation: {
                fr_CA: 'Conseils et astuces d’experts',
                de_DE: 'Ratschläge und Tipps von Experten',
                fr_FR: 'Conseils et astuces d’experts',
                it_IT: 'Consigli e suggerimenti degli esperti',
                es_ES: 'Consejos y sugerencias de expertos',
                es_MX: 'Consejos y asesoría de expertos',
                pt_BR: 'Conselhos e dicas de especialistas',
              },
            },
            {
              id: "functionality-7-text-1",
              component: "text",
              style: { fontSize: "1rem" },
              value: "Sometimes your team needs a little help. Product videos provide helpful tips and maintenance how-to’s for all our products.",
              translation: {
                fr_CA: 'Parfois, votre équipe a besoin d’un peu d’aide. Les vidéos sur les produits fournissent des conseils utiles et des instructions d’entretien pour tous nos produits.',
                de_DE: 'Manchmal benötigt Ihr Team ein wenig Hilfe. Produktvideos bieten hilfreiche Tipps und Wartungsanleitungen für alle unsere Produkte.',
                fr_FR: 'Parfois, votre équipe a besoin d’un peu d’aide. Les vidéos sur les produits fournissent des conseils utiles et des instructions de maintenance pour tous nos produits.',
                it_IT: 'A volte il vostro team ha bisogno di un piccolo aiuto. I video sui prodotti forniscono consigli utili e istruzioni per la manutenzione di tutti i nostri prodotti.',
                es_ES: 'En ocasiones, el personal necesita un poco de ayuda. Los vídeos de productos proporcionan consejos y procedimientos de mantenimiento muy útiles para todos nuestros productos.',
                es_MX: 'A veces, su equipo necesita un poco de ayuda. Los videos de productos proporcionan consejos útiles e instrucciones de mantenimiento para todos nuestros productos.',
                pt_BR: 'Às vezes, sua equipe precisa de uma pequena ajuda. Os vídeos de produtos fornecem dicas úteis e instruções de manutenção para todos os nossos produtos.',
              },
            },
          ]
        }
      ]
    },
  ],
}

const defaultLearningSection = {
  id: "learning-section",
  component: "section",
  style: { background: "linear-gradient(180deg, #0678bf, #6ebdef)", padding: "7.5rem" },
  children: [
    {
      id: "learning-text-1",
      component: "text",
      style: { fontSize: "4.4rem", fontWeight: "700" },
      value: "Learning Centre",
      translation: {
        fr_CA: 'Centre d’apprentissage',
        de_DE: 'Learning Centre',
        fr_FR: 'Centre d’apprentissage',
        it_IT: 'Centro di apprendimento',
        es_ES: 'Centro de formación',
        es_MX: 'Centro de aprendizaje',
        pt_BR: 'Centro de aprendizagem',
      },
    },
    {
      id: "learning-text-2",
      component: "text",
      style: { fontSize: "1.2rem", marginBottom: "5rem", marginTop: "3rem", maxWidth: "37.5rem" },
      value: "Step-by-step tutorials on how to sign up, connect your equipment and find what you're looking for.",
      translation: {
        fr_CA: 'Tutoriels étape par étape sur la façon de s’inscrire, de connecter votre équipement et de trouver ce que vous cherchez.',
        de_DE: 'Das Learning Centre bietet Schritt-für-Schritt-Tutorials zur Anmeldung und Verbindung Ihrer Geräte sowie zur Suche allgemein, damit Sie genau das finden, was Sie benötigen.',
        fr_FR: 'Des tutoriels étape par étape pour vous inscrire, connecter votre équipement et trouver ce que vous cherchez.',
        it_IT: 'Esercitazioni passo passo su come registrarsi, collegare le apparecchiature e trovare ciò che state cercando.',
        es_ES: 'Tutoriales paso a paso sobre cómo registrarse, conectar el equipo y encontrar lo que está buscando.',
        es_MX: 'Tutoriales paso a paso sobre cómo inscribirse, conectar su equipo y encontrar lo que está buscando.',
        pt_BR: 'Tutoriais passo a passo sobre como se inscrever, conectar seu equipamento e encontrar o que procura.',
      },
    },
    {
      id: "learning-carousel-1",
      component: "carousel",
      children: [
        {
          id: "learning-video-container-1",
          component: "flex-container",
          style: { flexDirection: "column" },
          children: [
            {
              id: "learning-video-section-1",
              component: "video",
              style: { maxWidth: "40rem" },
              href: "youtube.com/watch?v=Jbt26YauGZk",
              translation: {
                fr_CA: '',
                de_DE: 'youtube.com/watch?v=NYI_fsgwTjw',
                fr_FR: '',
                it_IT: '',
                es_ES: 'youtube.com/watch?v=MFKQzRxklqE',
                es_MX: 'youtube.com/watch?v=MFKQzRxklqE',
                pt_BR: '',
              },
              children: [
                {
                  id: "learning-video-thumbnail-1",
                  component: "image",
                  imageKey: "coltene-landing/images/default-learning-1.jpg",
                  style: { height: "25.4rem", width: "100%" },
                  translation: {
                    fr_CA: 'coltene-landing/images/default-learning-1-fr_CA.jpg',
                    de_DE: 'coltene-landing/images/default-learning-1-de_DE.jpg',
                    fr_FR: 'coltene-landing/images/default-learning-1-fr_FR.jpg',
                    it_IT: 'coltene-landing/images/default-learning-1-it_IT.jpg',
                    es_ES: 'coltene-landing/images/default-learning-1-es_ES.jpg',
                    es_MX: 'coltene-landing/images/default-learning-1-es_MX.jpg',
                    pt_BR: 'coltene-landing/images/default-learning-1-pt_BR.jpg',
                  },
                },
              ],
            },
            {
              id: "learning-video-container-1-text-1",
              component: "text",
              style: { fontSize: "1.5rem", marginTop: "3.75rem", fontWeight: "700", textAlign: "center" },
              value: "Searching the video archive",
              translation: {
                fr_CA: 'Recherche dans les archives vidéo',
                de_DE: 'Durchsuchen des Videoarchivs',
                fr_FR: 'Recherche dans l’archive vidéo',
                it_IT: 'Ricerca nell’archivio video',
                es_ES: 'Búsqueda de un archivo de video',
                es_MX: 'Búsqueda de un archivo de video',
                pt_BR: 'Pesquisar o arquivo de vídeo',
              },
            },
            {
              id: "learning-video-container-1-text-2",
              component: "text",
              style: { fontSize: "14px", marginTop: "18px", textAlign: "center" },
              value: "Need a refresher on how to use or maintain equipment? Search our video tutorials. Learn how here.",
              translation: {
                fr_CA: 'Vous avez besoin d’un rappel sur la façon d’utiliser ou d’entretenir l’équipement? Recherchez nos tutoriels vidéo. Apprenez la façon de faire ici.',
                de_DE: 'Sie benötigen eine Auffrischung zur Verwendung oder Wartung von Geräten? Stöbern Sie in unseren Video-Tutorials. Wie das geht, erfahren Sie hier.',
                fr_FR: 'Besoin d’un rappel sur la façon d’utiliser ou d’entretenir l’équipement? Consultez nos tutoriels vidéo. Découvrez comment ici.',
                it_IT: 'Hai bisogno di un aggiornamento su come utilizzare o manutenere l’apparecchiatura? Cerca nelle nostre esercitazioni video. Scopri come qui.',
                es_ES: '¿Necesita actualizar sus conocimientos sobre cómo usar el equipo o realizar su mantenimiento? Consulte nuestros tutoriales en vídeo. Descubra cómo hacerlo aquí.',
                es_MX: '¿Necesita un repaso sobre cómo usar o mantener el equipo? Busque nuestros tutoriales en video. Aprenda cómo hacerlo aquí.',
                pt_BR: 'Precisa de um curso de atualização sobre como usar ou manter equipamentos? Pesquise nossos tutoriais em vídeo. Saiba como, aqui.',
              },
            },
          ]
        },
        {
          id: "learning-video-container-2",
          component: "flex-container",
          style: { flexDirection: "column" },
          children: [
            {
              id: "learning-video-section-2",
              component: "video",
              style: { maxWidth: "40rem" },
              href: "youtube.com/watch?v=2lG7igln-Gs",
              translation: {
                fr_CA: 'youtube.com/watch?v=uKEZmu1QNZ8',
                de_DE: '',
                fr_FR: 'youtube.com/watch?v=uKEZmu1QNZ8',
                it_IT: '',
                es_ES: '',
                es_MX: '',
                pt_BR: '',
              },
              children: [
                {
                  id: "learning-video-thumbnail-2",
                  component: "image",
                  imageKey: "coltene-landing/images/default-learning-2.jpg",
                  style: { height: "25.4rem", width: "100%" },
                  translation: {
                    fr_CA: 'coltene-landing/images/default-learning-2-fr_CA.jpg',
                    de_DE: '',
                    fr_FR: 'coltene-landing/images/default-learning-2-fr_FR.jpg',
                    it_IT: '',
                    es_ES: '',
                    es_MX: '',
                    pt_BR: '',
                  },
                },
              ],
            },
            {
              id: "learning-video-container-2-text-1",
              component: "text",
              style: { fontSize: "1.5rem", marginTop: "3.75rem", fontWeight: "700", textAlign: "center" },
              value: "How to register as an organization",
              translation: {
                fr_CA: 'Comment s’inscrire en tant qu’organisation sur myCOLTENE',
                de_DE: 'Wie man sich als Organisation registriert',
                fr_FR: 'Comment s’inscrire en tant qu’organisation sur myCOLTENE',
                it_IT: 'Come registrarsi come organizzazione',
                es_ES: 'Cómo registrarse como organización',
                es_MX: 'Cómo registrarse como organización',
                pt_BR: '',
              },
            },
            {
              id: "learning-video-container-2-text-2",
              component: "text",
              style: { fontSize: "14px", marginTop: "18px", textAlign: "center" },
              value: "Joining an organization with your existing account is easy. Learn how.",
              translation: {
                fr_CA: 'Créer un compte qui permet au personnel et aux associés de consulter vos dossiers.',
                de_DE: '',
                fr_FR: 'Créer un compte qui permet au personnel et aux associés de consulter vos dossiers.',
                it_IT: '',
                es_ES: '',
                es_MX: '',
                pt_BR: '',
              },
            },
          ]
        },
        {
          id: "learning-video-container-3",
          component: "flex-container",
          style: { flexDirection: "column" },
          children: [
            {
              id: "learning-video-section-3",
              component: "video",
              style: { maxWidth: "40rem" },
              href: "youtube.com/watch?v=zH25oTx6SL0",
              translation: {
                fr_CA: 'youtube.com/watch?v=3UvZ_6YXhn8',
                de_DE: '',
                fr_FR: 'youtube.com/watch?v=3UvZ_6YXhn8',
                it_IT: '',
                es_ES: '',
                es_MX: '',
                pt_BR: '',
              },
              children: [
                {
                  id: "learning-video-thumbnail-3",
                  component: "image",
                  imageKey: "coltene-landing/images/default-learning-3.jpg",
                  style: { height: "25.4rem", width: "100%" },
                  translation: {
                    fr_CA: 'coltene-landing/images/default-learning-3-fr_CA.jpg',
                    de_DE: '',
                    fr_FR: 'coltene-landing/images/default-learning-3-fr_FR.jpg',
                    it_IT: '',
                    es_ES: '',
                    es_MX: '',
                    pt_BR: '',
                  },
                },
              ],
            },
            {
              id: "learning-video-container-3-text-1",
              component: "text",
              style: { fontSize: "1.5rem", marginTop: "3.75rem", fontWeight: "700", textAlign: "center" },
              value: "How to convert to an organization",
              translation: {
                fr_CA: 'Comment passer à une organisation sur myCOLTENE',
                de_DE: 'Wie man zu einer Organisation konvertiert',
                fr_FR: 'Comment passer à une organisation sur myCOLTENE',
                it_IT: 'Come convertire un’organizzazione',
                es_ES: 'Cómo convertirse en organización',
                es_MX: 'Cómo convertirse en organización',
                pt_BR: '',
              },
            },
            {
              id: "learning-video-container-3-text-2",
              component: "text",
              style: { fontSize: "14px", marginTop: "18px", textAlign: "center" },
              value: "Want to convert your individual account to an organization account? Here’s how.",
              translation: {
                fr_CA: 'Apprenez à convertir votre compte individuel en compte d’organisation.',
                de_DE: '',
                fr_FR: 'Apprenez à convertir votre compte individuel en compte d’organisation.',
                it_IT: '',
                es_ES: '',
                es_MX: '',
                pt_BR: '',
              },
            },
          ]
        },
        {
          id: "learning-video-container-4",
          component: "flex-container",
          style: { flexDirection: "column" },
          children: [
            {
              id: "learning-video-section-4",
              component: "video",
              style: { maxWidth: "40rem" },
              href: "youtube.com/watch?v=lWdkXuwMNsw",
              translation: {
                fr_CA: 'youtube.com/watch?v=w2IP47SNZBY',
                de_DE: '',
                fr_FR: 'youtube.com/watch?v=w2IP47SNZBY',
                it_IT: '',
                es_ES: '',
                es_MX: '',
                pt_BR: '',
              },
              children: [
                {
                  id: "learning-video-thumbnail-4",
                  component: "image",
                  imageKey: "coltene-landing/images/default-learning-4.jpg",
                  style: { height: "25.4rem", width: "100%" },
                  translation: {
                    fr_CA: 'coltene-landing/images/default-learning-4-fr_CA.jpg',
                    de_DE: '',
                    fr_FR: 'coltene-landing/images/default-learning-4-fr_FR.jpg',
                    it_IT: '',
                    es_ES: '',
                    es_MX: '',
                    pt_BR: '',
                  },
                },
              ],
            },
            {
              id: "learning-video-container-4-text-1",
              component: "text",
              style: { fontSize: "1.5rem", marginTop: "3.75rem", fontWeight: "700", textAlign: "center" },
              value: "How to join an organization",
              translation: {
                fr_CA: 'Comment rejoindre une organisation à partir d’un compte existant sur myCOLTENE',
                de_DE: 'Wie man einer Organisation beitritt',
                fr_FR: 'Comment rejoindre une organisation à partir d’un compte existant sur myCOLTENE',
                it_IT: 'Come aderire a un’organizzazione',
                es_ES: 'Cómo afiliarse a una organización',
                es_MX: 'Cómo afiliarse a una organización',
                pt_BR: '',
              },
            },
            {
              id: "learning-video-container-4-text-2",
              component: "text",
              style: { fontSize: "14px", marginTop: "18px", textAlign: "center" },
              value: "Joining an organization with your existing account is easy. Learn how.",
              translation: {
                fr_CA: 'Un compte existant peut demander à rejoindre une organization.',
                de_DE: '',
                fr_FR: 'Un compte existant peut demander à rejoindre une organization.',
                it_IT: '',
                es_ES: '',
                es_MX: '',
                pt_BR: '',
              },
            },
          ]
        },
        {
          id: "learning-video-container-5",
          component: "flex-container",
          style: { flexDirection: "column" },
          children: [
            {
              id: "learning-video-section-5",
              component: "video",
              style: { maxWidth: "40rem" },
              href: "youtube.com/watch?v=UuRhot72zv0",
              translation: {
                fr_CA: '',
                de_DE: 'youtube.com/watch?v=TR8jjwUkDaY',
                fr_FR: '',
                it_IT: '',
                es_ES: 'youtube.com/watch?v=-5YcNK_aF1w',
                es_MX: 'youtube.com/watch?v=-5YcNK_aF1w',
                pt_BR: '',
              },
              children: [
                {
                  id: "learning-video-thumbnail-5",
                  component: "image",
                  imageKey: "coltene-landing/images/default-learning-5.jpg",
                  style: { height: "25.4rem", width: "100%" },
                  translation: {
                    fr_CA: 'coltene-landing/images/default-learning-5-fr_CA.jpg',
                    de_DE: 'coltene-landing/images/default-learning-5-de_DE.jpg',
                    fr_FR: 'coltene-landing/images/default-learning-5-fr_FR.jpg',
                    it_IT: 'coltene-landing/images/default-learning-5-it_IT.jpg',
                    es_ES: 'coltene-landing/images/default-learning-5-es_ES.jpg',
                    es_MX: 'coltene-landing/images/default-learning-5-es_MX.jpg',
                    pt_BR: 'coltene-landing/images/default-learning-5-pt_BR.jpg',
                  },
                },
              ],
            },
            {
              id: "learning-video-container-5-text-1",
              component: "text",
              style: { fontSize: "1.5rem", marginTop: "3.75rem", fontWeight: "700", textAlign: "center" },
              value: "Using My Products to find manuals and videos",
              translation: {
                fr_CA: 'Utiliser Mes produits pour trouver des manuels et des vidéos',
                de_DE: 'Verwendung von „My Products“ (Meine Produkte) zum Finden von Handbüchern und Videos',
                fr_FR: 'Utilisation de My Products pour trouver des manuels et des vidéos',
                it_IT: 'Utilizzo di I miei prodotti per trovare manuali e video',
                es_ES: 'Uso de Mis productos para encontrar manuales y videos',
                es_MX: 'Uso de Mis productos para encontrar manuales y videos',
                pt_BR: 'Usar Meus produtos para encontrar manuais e vídeos',
              },
            },
            {
              id: "learning-video-container-5-text-2",
              component: "text",
              style: { fontSize: "14px", marginTop: "18px", textAlign: "center" },
              value: "Your dashboard gives you easy access to all the manuals and videos for your SciCan equipment. Learn how here.",
              translation: {
                fr_CA: 'Votre tableau de bord vous offre un accès facile à tous les manuels et vidéos de votre équipement SciCan. Apprenez la façon de faire ici.',
                de_DE: 'Über Ihr Dashboard greifen Sie mühelos auf alle Handbücher und Videos für Ihre SciCan-Geräte zu. Wie das geht, erfahren Sie hier.',
                fr_FR: 'Votre tableau de bord vous donne un accès facile à tous les manuels et vidéos de votre équipement SciCan. Découvrez comment ici.',
                it_IT: 'La dashboard consente di accedere facilmente a tutti i manuali e i video delle apparecchiature SciCan. Scopri come qui.',
                es_ES: 'El panel de control le permite acceder con facilidad a todos los manuales y vídeos de su equipo SciCan. Descubra cómo hacerlo aquí.',
                es_MX: 'Su tablero le brinda fácil acceso a todos los manuales y videos de su equipo SciCan. Aprenda cómo hacerlo aquí.',
                pt_BR: 'Esse painel oferece acesso fácil a todos os manuais e vídeos para seu equipamento SciCan. Saiba como, aqui.',
              },
            },
          ]
        },
        {
          id: "learning-video-container-6",
          component: "flex-container",
          style: { flexDirection: "column" },
          children: [
            {
              id: "learning-video-section-6",
              component: "video",
              style: { maxWidth: "40rem" },
              href: "youtube.com/watch?v=dZgGHQ4Kldg",
              translation: {
                fr_CA: '',
                de_DE: 'youtube.com/watch?v=3bfgIYk7cDY',
                fr_FR: '',
                it_IT: '',
                es_ES: 'youtube.com/watch?v=SBHyEQRtAMM',
                es_MX: 'youtube.com/watch?v=SBHyEQRtAMM',
                pt_BR: '',
              },
              children: [
                {
                  id: "learning-video-thumbnail-6",
                  component: "image",
                  imageKey: "coltene-landing/images/default-learning-6.jpg",
                  style: { height: "25.4rem", width: "100%" },
                  translation: {
                    fr_CA: 'coltene-landing/images/default-learning-6-fr_CA.jpg',
                    de_DE: 'coltene-landing/images/default-learning-6-de_DE.jpg',
                    fr_FR: 'coltene-landing/images/default-learning-6-fr_FR.jpg',
                    it_IT: 'coltene-landing/images/default-learning-6-it_IT.jpg',
                    es_ES: 'coltene-landing/images/default-learning-6-es_ES.jpg',
                    es_MX: 'coltene-landing/images/default-learning-6-es_MX.jpg',
                    pt_BR: 'coltene-landing/images/default-learning-6-pt_BR.jpg',
                  },
                },
              ],
            },
            {
              id: "learning-video-container-6-text-1",
              component: "text",
              style: { fontSize: "1.5rem", marginTop: "3.75rem", fontWeight: "700", textAlign: "center" },
              value: "Using the cycle calendar and reporting features",
              translation: {
                fr_CA: 'Utiliser le calendrier des cycles et les fonctions de production de rapports',
                de_DE: 'Verwendung des Zykluskalenders und der Berichtsfunktionen',
                fr_FR: 'Utilisation du calendrier des cycles et des fonctions de rapport',
                it_IT: 'Utilizzo del calendario dei cicli e delle funzioni di reporting',
                es_ES: 'Uso del calendario de ciclos y las funciones de informes',
                es_MX: 'Uso del calendario de ciclos y las funciones de informes',
                pt_BR: 'Usar o calendário de ciclos e os recursos de relatório',
              },
            },
            {
              id: "learning-video-container-6-text-2",
              component: "text",
              style: { fontSize: "14px", marginTop: "18px", textAlign: "center" },
              value: "Looking for a cycle record or reprocessing report? It’s easy. Learn how here.",
              translation: {
                fr_CA: 'Vous cherchez l’enregistrement d’un cycle ou un rapport de retraitement? C’est facile. Apprenez la façon de faire ici.',
                de_DE: 'Suchen Sie nach einem bestimmten Zyklusprotokoll oder Aufbereitungsbericht? Das ist einfach. Wie das geht, erfahren Sie hier.',
                fr_FR: 'Vous recherchez un enregistrement de cycle ou un rapport de retraitement? C’est facile. Découvrez comment ici.',
                it_IT: 'Stai cercando un record dei cicli o un rapporto sul ricondizionamento? È facile. Scopri come qui.',
                es_ES: '¿Busca un registro de ciclo o un informe de reprocesamiento? Es sencillo. Descubra cómo hacerlo aquí.',
                es_MX: '¿Busca un registro de ciclo o un informe de reprocesamiento? Es fácil. Aprenda cómo hacerlo aquí.',
                pt_BR: 'Procura um registro de ciclo ou relatório de reprocessamento? É fácil. Saiba como, aqui.',
              },
            },
          ]
        },
        {
          id: "learning-video-container-7",
          component: "flex-container",
          style: { flexDirection: "column" },
          children: [
            {
              id: "learning-video-section-7",
              component: "video",
              style: { maxWidth: "40rem" },
              href: "youtube.com/watch?v=UIodxfwUmdg",
              translation: {
                fr_CA: '',
                de_DE: 'youtube.com/watch?v=Iyp2WcuSvfo',
                fr_FR: '',
                it_IT: '',
                es_ES: 'youtube.com/watch?v=3ICX9OwhLz4',
                es_MX: 'youtube.com/watch?v=3ICX9OwhLz4',
                pt_BR: '',
              },
              children: [
                {
                  id: "learning-video-thumbnail-7",
                  component: "image",
                  imageKey: "coltene-landing/images/default-learning-7.jpg",
                  style: { height: "25.4rem", width: "100%" },
                  translation: {
                    fr_CA: 'coltene-landing/images/default-learning-7-fr_CA.jpg',
                    de_DE: 'coltene-landing/images/default-learning-7-de_DE.jpg',
                    fr_FR: 'coltene-landing/images/default-learning-7-fr_FR.jpg',
                    it_IT: 'coltene-landing/images/default-learning-7-it_IT.jpg',
                    es_ES: 'coltene-landing/images/default-learning-7-es_ES.jpg',
                    es_MX: 'coltene-landing/images/default-learning-7-es_MX.jpg',
                    pt_BR: 'coltene-landing/images/default-learning-7-pt_BR.jpg',
                  },
                },
              ],
            },
            {
              id: "learning-video-container-7-text-1",
              component: "text",
              style: { fontSize: "1.5rem", marginTop: "3.75rem", fontWeight: "700", textAlign: "center" },
              value: "How to create an account",
              translation: {
                fr_CA: 'Comment créer un compte',
                de_DE: 'So erstellen Sie ein Konto',
                fr_FR: 'Comment créer un compte',
                it_IT: 'Come creare un account',
                es_ES: 'Cómo crear una cuenta',
                es_MX: 'Cómo crear una cuenta',
                pt_BR: 'Como criar uma conta',
              },
            },
            {
              id: "learning-video-container-7-text-2",
              component: "text",
              style: { fontSize: "14px", marginTop: "18px", textAlign: "center" },
              value: "Signing up for myCOLTENE is easy. Learn how here.",
              translation: {
                fr_CA: 'Il est facile de s’inscrire à myCOLTENE. Apprenez la façon de faire ici.',
                de_DE: 'Die Anmeldung bei myCOLTENE ist einfach. Wie das geht, erfahren Sie hier.',
                fr_FR: 'S’inscrire à myCOLTENE est facile. Découvrez comment ici.',
                it_IT: 'Registrarsi a myCOLTENE è facile. Scopri come qui.',
                es_ES: 'Registrarse en myCOLTENE es muy sencillo. Descubra cómo hacerlo aquí.',
                es_MX: 'Registrarse en myCOLTENE es fácil. Aprenda cómo hacerlo aquí.',
                pt_BR: 'É fácil se inscrever no myCOLTENE. Saiba como, aqui.',
              },
            },
          ]
        },
        {
          id: "learning-video-container-8",
          component: "flex-container",
          style: { flexDirection: "column" },
          children: [
            {
              id: "learning-video-section-8",
              component: "video",
              style: { maxWidth: "40rem" },
              href: "youtube.com/watch?v=alsWOvmEk1o",
              translation: {
                fr_CA: '',
                de_DE: 'youtube.com/watch?v=yK2-t979omw',
                fr_FR: '',
                it_IT: '',
                es_ES: 'youtube.com/watch?v=e4vVbb6pe3A',
                es_MX: 'youtube.com/watch?v=e4vVbb6pe3A',
                pt_BR: '',
              },
              children: [
                {
                  id: "learning-video-thumbnail-8",
                  component: "image",
                  imageKey: "coltene-landing/images/default-learning-8.jpg",
                  style: { height: "25.4rem", width: "100%" },
                  translation: {
                    fr_CA: 'coltene-landing/images/default-learning-8-fr_CA.jpg',
                    de_DE: 'coltene-landing/images/default-learning-8-de_DE.jpg',
                    fr_FR: 'coltene-landing/images/default-learning-8-fr_FR.jpg',
                    it_IT: 'coltene-landing/images/default-learning-8-it_IT.jpg',
                    es_ES: 'coltene-landing/images/default-learning-8-es_ES.jpg',
                    es_MX: 'coltene-landing/images/default-learning-8-es_MX.jpg',
                    pt_BR: 'coltene-landing/images/default-learning-8-pt_BR.jpg',
                  },
                },
              ],
            },
            {
              id: "learning-video-container-8-text-1",
              component: "text",
              style: { fontSize: "1.5rem", marginTop: "3.75rem", fontWeight: "700", textAlign: "center" },
              value: "How to connect your G4 equipment to myCOLTENE",
              translation: {
                fr_CA: 'Comment connecter votre équipement G4 à myCOLTENE',
                de_DE: 'So verbinden Sie Ihre G4-Geräte mit myCOLTENE',
                fr_FR: 'Comment connecter votre équipement G4 à myCOLTENE',
                it_IT: 'Come collegare l’apparecchiatura G4 a myCOLTENE',
                es_ES: 'Cómo conectar su equipo G4 a myCOLTENE',
                es_MX: 'Cómo conectar su equipo G4 a myCOLTENE',
                pt_BR: 'Como conectar seu equipamento G4 ao myCOLTENE',
              },
            },
            {
              id: "learning-video-container-8-text-2",
              component: "text",
              style: { fontSize: "14px", marginTop: "18px", textAlign: "center" },
              value: "Want to connect your SciCan equipment to myCOLTENE? Learn how here.",
              translation: {
                fr_CA: 'Vous voulez connecter votre équipement SciCan à myCOLTENE? Apprenez la façon de faire ici.',
                de_DE: 'Sie möchten Ihre SciCan-Geräte mit myCOLTENE verbinden? Wie das geht, erfahren Sie hier.',
                fr_FR: 'Vous souhaitez connecter votre équipement SciCan à myCOLTENE ? Découvrez comment ici.',
                it_IT: 'Come collegare l’apparecchiatura SciCan a myCOLTENE? Scopri come qui.',
                es_ES: '¿Quiere conectar su equipo SciCan a myCOLTENE? Descubra cómo hacerlo aquí.',
                es_MX: '¿Desea conectar su equipo SciCan a myCOLTENE? Aprenda cómo hacerlo aquí.',
                pt_BR: 'Quer conectar seu equipamento SciCan ao myCOLTENE? Saiba como, aqui.',
              },
            },
          ]
        },
        {
          id: "learning-video-container-9",
          component: "flex-container",
          style: { flexDirection: "column" },
          children: [
            {
              id: "learning-video-section-9",
              component: "video",
              style: { maxWidth: "40rem" },
              href: "youtube.com/watch?v=nV22l496LoA",
              translation: {
                fr_CA: '',
                de_DE: 'youtube.com/watch?v=35nc52Tzj6A',
                fr_FR: '',
                it_IT: '',
                es_ES: 'youtube.com/watch?v=mgEUStMueaU',
                es_MX: 'youtube.com/watch?v=mgEUStMueaU',
                pt_BR: '',
              },
              children: [
                {
                  id: "learning-video-thumbnail-9",
                  component: "image",
                  imageKey: "coltene-landing/images/default-learning-9.jpg",
                  style: { height: "25.4rem", width: "100%" },
                  translation: {
                    fr_CA: 'coltene-landing/images/default-learning-9-fr_CA.jpg',
                    de_DE: 'coltene-landing/images/default-learning-9-de_DE.jpg',
                    fr_FR: 'coltene-landing/images/default-learning-9-fr_FR.jpg',
                    it_IT: 'coltene-landing/images/default-learning-9-it_IT.jpg',
                    es_ES: 'coltene-landing/images/default-learning-9-es_ES.jpg',
                    es_MX: 'coltene-landing/images/default-learning-9-es_MX.jpg',
                    pt_BR: 'coltene-landing/images/default-learning-9-pt_BR.jpg',
                  },
                },
              ],
            },
            {
              id: "learning-video-container-9-text-1",
              component: "text",
              style: { fontSize: "1.5rem", marginTop: "3.75rem", fontWeight: "700", textAlign: "center" },
              value: "Searching the documents library",
              translation: {
                fr_CA: 'Recherche dans la bibliothèque de documents',
                de_DE: 'Durchsuchen der Dokumentenbibliothek',
                fr_FR: 'Recherche dans la bibliothèque de documents',
                it_IT: 'Ricerca nella libreria documenti',
                es_ES: 'Búsqueda de documentos en la biblioteca',
                es_MX: 'Búsqueda de documentos en la biblioteca',
                pt_BR: 'Pesquisar a biblioteca de documentos',
              },
            },
            {
              id: "learning-video-container-9-text-2",
              component: "text",
              style: { fontSize: "14px", marginTop: "18px", textAlign: "center" },
              value: "Can’t find your manual? We’ve got a library full. Learn how here.",
              translation: {
                fr_CA: 'Vous ne trouvez pas votre manuel? Nous avons une grande bibliothèque. Apprenez la façon de faire ici.',
                de_DE: 'Sie können Ihr Handbuch nicht finden? Wir haben eine umfassende Bibliothek. Wie das geht, erfahren Sie hier.',
                fr_FR: 'Vous ne trouvez pas votre manuel? Nous avons une bibliothèque pleine. Découvrez comment ici.',
                it_IT: 'Non riesci a trovare il tuo manuale? Abbiamo un’intera libreria. Scopri come qui.',
                es_ES: '¿No encuentra el manual? Tenemos toda una biblioteca llena de ellos. Descubra cómo hacerlo aquí.',
                es_MX: '¿No encuentra su manual? Tenemos una biblioteca llena. Aprenda cómo hacerlo aquí.',
                pt_BR: 'Não consegue encontrar o seu manual? Temos uma biblioteca completa. Saiba como, aqui.',
              },
            },
          ]
        },
      ]
    },
  ],
}

const defaultRegisterSection = {
  id: "register-section",
  component: "section",
  style: { backgroundColor: "#052C4D" },
  children: [
    {
      id: "register-text-1",
      component: "text",
      style: { fontSize: "1.5rem", marginBottom: "3rem", marginTop: "5rem" },
      value: "Improve your practice with myCOLTENE digital solutions.",
      translation: {
        fr_CA: 'Améliorez votre pratique avec les solutions numériques myCOLTENE.',
        de_DE: 'Verbessern Sie Ihre Praxis mit den digitalen Lösungen von myCOLTENE.',
        fr_FR: 'Améliorez votre pratique avec les solutions numériques myCOLTENE.',
        it_IT: 'Migliorate il vostro studio con le soluzioni digitali myCOLTENE.',
        es_ES: 'Mejore su consulta con las soluciones digitales de myCOLTENE.',
        es_MX: 'Mejore su consulta con las soluciones digitales myCOLTENE.',
        pt_BR: 'Melhore sua prática clínica com as soluções digitais myCOLTENE.',
      },
    },
    {
      id: "register-button-2",
      component: "link",
      style: { marginBottom: "6rem" },
      href:`${process.env.REACT_APP_HOST}/register`,
      value: "Create an account",
      translation: {
        fr_CA: 'Créer un compte',
        de_DE: 'Konto erstellen',
        fr_FR: 'Créez un compte',
        it_IT: 'Crea un account',
        es_ES: 'Crear una cuenta',
        es_MX: 'Crear una cuenta',
        pt_BR: 'Criar uma conta',
      },
    },
  ],
}

const defaultUiTree = [
  defaultHeroSection,
  defaultIntroSection,
  defaultVideoSection,
  defaultFunctionalitySection,
  defaultLearningSection,
  defaultRegisterSection
]

const TemplateEditor = (props) => {
  const { t } = useTranslation()
  const { selectedTemplate, isCloneTemplate, onNewTemplate, onPreview, onPublish, onToggle } = props
  const [templateId, setTemplateId] = useState('')
  const [templateName, setTemplateName] = useState(isCloneTemplate ? `${selectedTemplate.name}(CLONE)` : selectedTemplate?.name || "MyColtene Landing")
  const [currentUiTree, setCurrentUiTree] = useState(selectedTemplate?.uiTree || defaultUiTree)
  const [hasUiChanges, setHasUiChanges] = useState(false)
  const [selectedElement, setSelectedElement] = useState(null)
  const [showTemplateNameSetupModal, setShowTemplateNameSetupModal] = useState(false)
  const [showSpinner, setShowSpinner] = useState(null)
  const [showExitConfirmationModal, setShowExitConfirmationModal] = useState(false)
  const [showPublishConfirmationModal, setShowPublishConfirmationModal] = useState(false)
  const [isPublishDisabled, setIsPublishDisabled] = useState(true)
  const [lastPublishTime, setLastPublishTime] = useState(null)
  const [isFirstLoad, setIsFirstLoad] = useState(true)
  const heroMainId = "hero-main"
  const heroSectionIndex = currentUiTree?.findIndex((u) => u.id === heroMainId)
  const templateStatus = {
    available: "available",
    deployed: "deployed",
    preview: "preview"
  }
  const s3ColteneTemplatesFolder = "coltene-landing/templates"
  const initialUiTree = JSON.stringify(selectedTemplate?.uiTree || defaultUiTree)
  const threeMinutes = 180000

  useEffect(() => {
    setTemplateId(isCloneTemplate || !selectedTemplate ? new Date().getTime() : selectedTemplate.id)
  },[])

  useEffect(() => {
    const getLastPublishTime = async () => { 
      const s3DeployedTemplateKey = "coltene-landing/published/deployed.json"
  
      try {
        const deployedFileData = await Storage.get(s3DeployedTemplateKey, { level: 'public', download: true })
        const jsonData = await deployedFileData.Body.text()

        if (jsonData) {
          const templateData = JSON.parse(jsonData)
          setLastPublishTime(templateData.createdAt)
        }
      } catch {
        console.log("There's no deployed template yet")
        setLastPublishTime(new Date() - threeMinutes)
      } finally {
        setIsFirstLoad(false)
      }
    }

    getLastPublishTime()
  },[])

  useEffect(() => {
    if (isFirstLoad) {
      return
    }

    if (!lastPublishTime) {
      return
    }

    const checkLastDeploy = () => {
      const now = new Date()
      const lastDeploy = new Date(lastPublishTime)
      const diff = now - lastDeploy

      setIsPublishDisabled(diff < threeMinutes)
    }

    checkLastDeploy()
    
    const tenSeconds = 10000
    const intervalId = setInterval(() => {
      checkLastDeploy()
    }, tenSeconds)

    return () => clearInterval(intervalId)
  }, [lastPublishTime, isFirstLoad])

  const renderComponent = (componentData) => {
    const { id, component, style, children, imageKey, value, href, src } = componentData

    switch (component) {
      case 'section':
        return (
          <SectionBuilder
            key={id}
            id={id}
            style={style}
            imageKey={imageKey}
            onUpdate={handleUpdateComponent}
            handleClick={() => setSelectedElement(componentData)}
          >
            {children && children.map((child) => renderComponent(child))}
          </SectionBuilder>
        )
      case 'flex-container':
        return (
          <div
            key={id}
            className="flex-container"
            style={style}
            onClick={(e) => { e.stopPropagation(); setSelectedElement(componentData)}}
          >
            {children && children.map((child) => renderComponent(child))}
          </div>
        )
      case 'text':
        return (
          <div
            key={id}
            className="text"
            style={style}
            onClick={(e) => { e.stopPropagation(); setSelectedElement(componentData) }}
          >
            {value}
          </div>
        )
      case 'link':
        return (
          <a 
            key={id} 
            className="link" 
            style={style} 
            href={href}
            target="_blank"
            rel="noreferrer"
            onClick={(e) => { e.preventDefault(); e.stopPropagation(); setSelectedElement(componentData)}}
          >
            {value}
          </a>
        )
      case 'image':
        return (
          <ImageBuilder
            key={id}
            id={id}
            style={style}
            imageKey={imageKey}
            src={src}
            onUpdate={handleUpdateComponent}
            handleClick={() => setSelectedElement(componentData)}
          />
        )
      case 'video':
        return (
          <VideoBuilder
            key={id}
            id={id}
            style={style}
            handleClick={() => setSelectedElement(componentData)}
          >
            {children && children.map((child) => renderComponent(child))}
          </VideoBuilder>
        )
      case 'carousel':
        return (
          <CarouselBuilder
            key={id}
            id={id}
            style={style}
            handleClick={() => setSelectedElement(componentData)}
          >
            {children && children.map((child) => renderComponent(child))}
          </CarouselBuilder>
        )
      default:
        return null
    }
  }

  const renderToolbox = (componentData) => {
    const { id, component, style, children, value, translation, href, src } = componentData

    switch (component) {
      case 'text':
        return (
          <TextBuilderToolbox
            id={id}
            style={style}
            value={value}
            href={href}
            translation={translation}
            onUpdate={(id, props) => handleUpdateComponent(id, props, true)}
          />
        )
      case 'link':
        return (
          <LinkBuilderToolbox
            id={id}
            style={style}
            value={value}
            href={href}
            translation={translation}
            onUpdate={(id, props) => handleUpdateComponent(id, props, true)}
          />
        )
      case 'section':
        return (
          <SectionBuilderToolbox
            templateId={templateId}
            id={id}
            style={style}
            children={children}
            onUpdate={(id, props) => handleUpdateComponent(id, props, true)}
          />
        )
      case 'flex-container':
        return (
          <FlexContainerBuilderToolbox
            id={id}
            style={style}
            children={children}
            onUpdate={(id, props) => handleUpdateComponent(id, props, true)}
          />
        )
      case 'image':
        return (
          <ImageBuilderToolbox
            templateId={templateId}
            id={id}
            style={style}
            src={src}
            translation={translation}
            onUpdate={(id, props) => handleUpdateComponent(id, props, true)}
          />
        )
      case 'video':
        return (
          <VideoBuilderToolbox
            id={id}
            href={href}
            translation={translation}
            onUpdate={(id, props) => handleUpdateComponent(id, props, true)}
          />
        )
      case 'carousel':
        return (
          <CarouselBuilderToolbox />
        )
      default:
        return null
    }
  }

  const updateComponentInTree = (tree, id, updatedProps) => {
    return tree.map((node) => {
      if (node.id === id) {
        return { ...node, ...updatedProps }
      }

      if (node.children?.length > 0) {
        return { ...node, children: updateComponentInTree(node.children, id, updatedProps) }
      }

      return node
    })
  }

  const handleUpdateComponent = (id, updatedProps, withChanges) => {
    setCurrentUiTree((prevUiTree) => updateComponentInTree(prevUiTree, id, updatedProps))

    if (withChanges) {
      setHasUiChanges(withChanges)
    }
  }

  const renderLayoutShiftElement = (id, index) => {
    const nrOfElements = currentUiTree.length

    return (
      <div key={id} className="item">
        <ArrowLeftIcon 
          className={"arrow-down" + (index === heroSectionIndex || index === nrOfElements - 1 ? " disabled-shift" : "")}
          onClick={() => handleShiftLayout(index, "up")}
        />
        <ArrowLeftIcon 
          className={"arrow-up" + (index === heroSectionIndex || index === 0 || index - 1 === heroSectionIndex ? " disabled-shift" : "")}
          onClick={() => handleShiftLayout(index, "down")}
        />
        <span>{id}</span>
      </div>
    )
  }

  const handleShiftLayout = (index, direction) => {
    const newUiTree = [...currentUiTree]

    if (direction === "up" && index < newUiTree.length - 1) {
      [newUiTree[index + 1], newUiTree[index]] = [newUiTree[index], newUiTree[index + 1]]
    } else if (direction === "down" && index > 0) {
      [newUiTree[index - 1], newUiTree[index]] = [newUiTree[index], newUiTree[index - 1]]
    }

    setCurrentUiTree(newUiTree)
    setHasUiChanges(true)
  }

  const updateImages = async (uiTree, newTemplateId, oldTemplateId) => {
    return Promise.all(
      uiTree.map(async (component) => {
        let updatedComponent = { ...component }
  
        if (component.imageKey?.includes(oldTemplateId)) {
          const newImageKey = component.imageKey.replace(oldTemplateId, newTemplateId)
  
          if (oldTemplateId !== newTemplateId) {
            await Storage.copy(
              { key: component.imageKey, level: 'public' },
              { key: newImageKey, level: 'public' },
            )
          }
  
          const { backgroundImage, ...newStyle } = component.style
          updatedComponent = {
            ...updatedComponent,
            imageKey: newImageKey,
            style: newStyle,
            ...(component.src && { src: null }),
          }
        }
  
        if (component.component === "image" && component.translation) {
          const updatedTranslation = {}

          for (const [lang, data] of Object.entries(component.translation)) {
            let currentImageKey = data?.imageKey || data || ''
            if (currentImageKey.includes(oldTemplateId)) {
              const newImageKey = currentImageKey.replace(oldTemplateId, newTemplateId)
  
              if (oldTemplateId !== newTemplateId) {
                await Storage.copy(
                  { key: currentImageKey, level: 'public' },
                  { key: newImageKey, level: 'public' },
                )
              }
  
              currentImageKey = newImageKey
            }

            updatedTranslation[lang] = currentImageKey
          }

          updatedComponent.translation = updatedTranslation
        }

        if (component.children) {
          updatedComponent.children = await updateImages(
            component.children,
            newTemplateId,
            oldTemplateId,
          )
        }
  
        return updatedComponent
      })
    )
  }

  const buildUpdatedUiTree = async () => {
    const finalUiTree = [...currentUiTree]
    const updatedUiTree = await updateImages(finalUiTree, templateId, !selectedTemplate ? "default" : selectedTemplate.id)

    return updatedUiTree
  }

  const saveChanges = async (withPublish) => {
    try {
      setShowSpinner(true)

      const uiTree = await buildUpdatedUiTree()

      await saveTemplate(uiTree, withPublish ? templateStatus.deployed : null)
      if (!withPublish) {
        toast.success(t("template_context.update_sucess"))
      }

      return uiTree
    } catch (error) {
      console.log("Error while saving template", error)
      toast.error(t("error.something_wrong"))
    } finally {
      setShowSpinner(false)
    }
  }

  const saveTemplate = async (uiTree, status) => {
    const template = {
      id: templateId,
      name: templateName,
      createdAt: isCloneTemplate || !selectedTemplate ? new Date().toISOString() : selectedTemplate.createdAt,
      createdBy: isCloneTemplate || !selectedTemplate ? props.email : selectedTemplate.createdBy,
      status: status || (isCloneTemplate || !selectedTemplate ? templateStatus.available : selectedTemplate.status),
      uiTree: uiTree
    }

    const jsonTemplate = JSON.stringify(template, null, 2)

    try {
      var { key } = await Storage.put(`${s3ColteneTemplatesFolder}/${templateId}.json`, jsonTemplate, {
        level: "public",
        contentType: "application/json",
      })
      //onNewTemplate(key, template)

      setHasUiChanges(false)
    } catch (error) {
      throw error
    }
  }

  const saveWithPreview = async () => {
    const uiTree = await buildUpdatedUiTree()
    onPreview(uiTree)
  }

  const saveWithPublish = async () => {
    const uiTree = await saveChanges(true)
    setLastPublishTime(new Date())
    onPublish(uiTree)
    //onToggle()
  }

  const handleDiscardChanges = () => {
    setSelectedElement(null)
    setCurrentUiTree(JSON.parse(initialUiTree))
    setHasUiChanges(false)
  }

  return (
    <div className="template-editor-wrapper">
      <div className="header">
        <div className="title">
          {templateName}
          {/*A.S: Hidden until further iteration */}
          {/* <span 
            className="edit-title"
            onClick={() => setShowTemplateNameSetupModal(true)}
            tabIndex={0}
            role="button"
            onKeyDown={(e) => e.key === keyboardEvents.ENTER && setShowTemplateNameSetupModal(true)}
          >
            {t("edit")}
          </span> */}
        </div>
        <div className="actions-wrapper">
          <SimpleButton
            className="cancel-button"
            onClick={() => setShowExitConfirmationModal(true)}
            disabled={!hasUiChanges}
          >
            {t("discard")}
          </SimpleButton>
          {/* <SimpleButton
            className="cancel-button"
            onClick={() => hasUiChanges ? setShowExitConfirmationModal(true) : onToggle()}
          >
            {t("exit")}
          </SimpleButton> */}
          <SimpleButton
            className="submit-button"
            onClick={() => saveChanges()}
            disabled={!hasUiChanges}
            requiredPermission={colteneTemplatesWritePermission}
          >
            {t("save")}
          </SimpleButton>
          <SimpleButton
            className="action-button"
            onClick={() => saveWithPreview()}
            requiredPermission={colteneTemplatesWritePermission}
          >
            {t("preview")}
          </SimpleButton>
          <SimpleButton
            className="submit-button"
            onClick={() => setShowPublishConfirmationModal(true)}
            disabled={isPublishDisabled}
            requiredPermission={colteneTemplatesWritePermission}
          >
            {isPublishDisabled && !isFirstLoad ? t("deploying") : t("publish")}
          </SimpleButton>
        </div>
      </div>
      <div className="content-wrapper">
        <div className="editor-container">
          {currentUiTree.map((componentData) =>
            renderComponent(componentData)
          )}
        </div>
        <div className="toolbox">
          <div className="title">{t("toolbox")}</div>
          {!selectedElement && (
            <>
              <div className="empty-selection">
              {t("template_context.no_element_selected")}
              </div>
              <div className="layout-shift-wrapper">
                <div className="title">{t("layout")}</div>
                {currentUiTree.map((componentData, index) => 
                  renderLayoutShiftElement(componentData.id, index)
                )}
              </div>
            </>
          )}
          {selectedElement && (
            <>
              <div className="element-title">
                <BackSimpleIcon className="back-icon" onClick={() => setSelectedElement(null)} />
                {selectedElement.id}
              </div>
              {renderToolbox(selectedElement)}
            </>
          )}
        </div>
      </div>
      {showTemplateNameSetupModal &&
        <TemplateNameSetupModal
          currentTemplateName={templateName}
          handleChangeTemplateName={(newTemplateName) => setTemplateName(newTemplateName)}
          closeModal={() => setShowTemplateNameSetupModal(false)}
          showSpinner={showSpinner}
        />
      }
      {showExitConfirmationModal && (
        <ConfirmationModal
          onToggle={() => setShowExitConfirmationModal(false)}
          //message={t("template_context.exit_confirmation")}
          message={t("template_context.discard_confirmation")}
          onCancel={() => setShowExitConfirmationModal(false)}
          //onAccept={() => { onToggle(); setShowExitConfirmationModal(false)} }
          onAccept={() => { handleDiscardChanges(); setShowExitConfirmationModal(false)} }
        />
      )}
      {showPublishConfirmationModal && (
        <ConfirmationModal
          onToggle={() => setShowPublishConfirmationModal(false)}
          message={t("template_context.publish_confirmation")}
          onCancel={() => setShowPublishConfirmationModal(false)}
          onAccept={() => { saveWithPublish(); setShowPublishConfirmationModal(false) }}
          requiredPermission={colteneTemplatesWritePermission}
        />
      )}
      {showSpinner && (
        <div className={"spinner-wrapper"}>
          <LoadingSpinner />
        </div>
      )}
    </div>
  )
}

function mapStateToProps({ authedUser }) {
  return {
    email: authedUser?.email,
  }
}

export default connect(mapStateToProps)(TemplateEditor)