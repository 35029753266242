import React from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router"
import SimpleButton from "../../common/SimpleButton"
import { formatStoreProductPrice } from "../../../utils/filters/filters"
import { convertToSimpleDate } from "../../../utils/filters/date"
import { Pages } from "../../../utils/pages"
import { capitalizeFirstLetter } from "../../../utils/functions"

const needUpdateStatuses = ["unpaid", "incomplete_expired", "incomplete", "past_due"]

const SubscriptionCard = (props) => {
  const history = useHistory()
  const { t } = useTranslation()
  const { subscription, hasActiveSubscription, handleCancel, handleRenew, handleReorder } = props

  const expiryDateInFuture = () => {
    const date = new Date()
    return new Date(subscription?.endDate) >= date
  }

  const handleUpdatePayments = () => {
    //history.push(Pages.paymentMethods.route)
  }

  const viewSubscriptionDetails = () => {
    history.push(`/coltene-store/${subscription.product.id}`)
  }

  return (
    <div className="subscription-container">
      <div className="details d-flex">
        {needUpdateStatuses.includes(subscription.status) && (
          <div className="aditional-information-row">
            <div className="aditional-information">{t("coltene_store.subscription_history.review-payment")}</div>
          </div>
        )}
        <div className="row">
          <div className="details-l">{t("coltene_store.subscription_history.subscription-title") + ":  "}</div>
          <div className="details-r subscription-title" onClick={() => viewSubscriptionDetails()}>
            {subscription?.product?.title || "-"}
          </div>
        </div>
        <div className="row">
          <div className="details-l">{t("coltene_store.subscription_history.subscription-type") + ":  "}</div>
          <div className="details-r">{t([`coltene_store.subscription_history.${subscription.subscriptionType}`, subscription.subscriptionType || "-"])}</div>
        </div>
        <div className="row">
          <div className="details-l">{t("status") + ": "}</div>
          <div className={`details-r ${subscription.status}`}>
            {(subscription.subscriptionType === "test_drive" && subscription.status === "active")
              ? t("active")
              : t([`coltene_store.subscription_history.statuses.${subscription.status}`, subscription.status])}
          </div>
        </div>
        <div className="row">
          <div className="details-l">{t("coltene_store.subscription_history.subscription-plan") + ":  "}</div>
          <div className="details-r">
            {subscription.priceRecurrenceInterval
              ? t([`coltene_store.create_product.${subscription.priceRecurrenceInterval}_plan`, subscription.priceRecurrenceInterval])
              : "-"}
          </div>
        </div>
        <div className="row">
          <div className="details-l">{t("coltene_store.subscription_history.subscription-start") + ": "}</div>
          <div className="details-r">{convertToSimpleDate(subscription?.startDate) || "-"}</div>
        </div>
        <div className="row">
          <div className="details-l">
            {(subscription.status === "active" ? t("store_context.renewal_end_date") : capitalizeFirstLetter(t("end_date"))) +
              ": "}
          </div>
          <div className="details-r">{convertToSimpleDate(subscription?.endDate) || "-"}</div>
        </div>
        <div className="row">
          <div className="details-l">{t("renewal_price") + ": "}</div>
          <div className="details-r">
            {subscription.paymentAmount && subscription.paymentCurrency
              ? formatStoreProductPrice(subscription.paymentAmount, subscription.paymentCurrency)
              : "-"}
          </div>
        </div>
      </div>
      <div className="button-wrapper">
        {!subscription.cancelAtPeriodEnd && subscription.status !== "canceled" && subscription.status !== "inactive" && (
          <SimpleButton className="action-button" onClick={() => handleCancel(subscription.id)}>
            {t("cancel")}
          </SimpleButton>
        )}
        {/* {needUpdateStatuses.includes(subscription.status) && (
          <SimpleButton className="submit-button" onClick={() => handleUpdatePayments(subscription.id)}>
            {t("review")}
          </SimpleButton>
        )} */}
        {subscription.orderId && subscription.cancelAtPeriodEnd && expiryDateInFuture() && (
          <SimpleButton className="action-button" onClick={() => handleRenew(subscription.id)}>
            {t("renew")}
          </SimpleButton>
        )}
        {subscription.orderId && subscription.status === "canceled" && !hasActiveSubscription && (
          <SimpleButton className="action-button" onClick={() => handleReorder(subscription)}>
            {t("re_order")}
          </SimpleButton>
        )}
      </div>
    </div>
  )
}

export default SubscriptionCard
