import {
  getDocuments,
  getTSBDocuments,
  getDocumentTypes,
  getDocumentLanguages,
  getTsbDocumentLanguages
} from "../utils/requests/documentsApi"
import { getDocumentModels } from "../utils/requests/productsAPI"

export const LOAD_DOCUMENTS = "LOAD_DOCUMENTS"
export const LOAD_DOCUMENTS_PENDING = "LOAD_DOCUMENTS_PENDING"
export const LOAD_DOCUMENTS_FULFILLED = "LOAD_DOCUMENTS_FULFILLED"
export const LOAD_DOCUMENTS_REJECTED = "LOAD_DOCUMENTS_REJECTED"

export const LOAD_NEW_DOCUMENTS = "LOAD_NEW_DOCUMENTS"
export const LOAD_NEW_DOCUMENTS_PENDING = "LOAD_NEW_DOCUMENTS_PENDING"
export const LOAD_NEW_DOCUMENTS_FULFILLED = "LOAD_NEW_DOCUMENTS_FULFILLED"
export const LOAD_NEW_DOCUMENTS_REJECTED = "LOAD_NEW_DOCUMENTS_REJECTED"

export const LOAD_TSB_DOCUMENTS = "LOAD_TSB_DOCUMENTS"
export const LOAD_TSB_DOCUMENTS_PENDING = "LOAD_TSB_DOCUMENTS_PENDING"
export const LOAD_TSB_DOCUMENTS_FULFILLED = "LOAD_TSB_DOCUMENTS_FULFILLED"
export const LOAD_TSB_DOCUMENTS_REJECTED = "LOAD_TSB_DOCUMENTS_REJECTED"

export const LOAD_NEW_TSB_DOCUMENTS = "LOAD_NEW_TSB_DOCUMENTS"
export const LOAD_NEW_TSB_DOCUMENTS_PENDING = "LOAD_NEW_TSB_DOCUMENTS_PENDING"
export const LOAD_NEW_TSB_DOCUMENTS_FULFILLED = "LOAD_NEW_TSB_DOCUMENTS_FULFILLED"
export const LOAD_NEW_TSB_DOCUMENTS_REJECTED = "LOAD_NEW_TSB_DOCUMENTS_REJECTED"

export const LOAD_DOCUMENT_MODELS = "LOAD_DOCUMENT_MODELS"
export const LOAD_DOCUMENT_MODELS_PENDING = "LOAD_DOCUMENT_MODELS_PENDING"
export const LOAD_DOCUMENT_MODELS_FULFILLED = "LOAD_DOCUMENT_MODELS_FULFILLED"
export const LOAD_DOCUMENT_MODELS_REJECTED = "LOAD_DOCUMENT_MODELS_REJECTED"

export const LOAD_DOCUMENT_TYPES = "LOAD_DOCUMENT_TYPES"
export const LOAD_DOCUMENT_TYPES_PENDING = "LOAD_DOCUMENT_TYPES_PENDING"
export const LOAD_DOCUMENT_TYPES_FULFILLED = "LOAD_DOCUMENT_TYPES_FULFILLED"
export const LOAD_DOCUMENT_TYPES_REJECTED = "LOAD_DOCUMENT_TYPES_REJECTED"

export const LOAD_DOCUMENT_LANGUAGES = "LOAD_DOCUMENT_LANGUAGES"
export const LOAD_DOCUMENT_LANGUAGES_PENDING = "LOAD_DOCUMENT_LANGUAGES_PENDING"
export const LOAD_DOCUMENT_LANGUAGES_FULFILLED = "LOAD_DOCUMENT_LANGUAGES_FULFILLED"
export const LOAD_DOCUMENT_LANGUAGES_REJECTED = "LOAD_DOCUMENT_LANGUAGES_REJECTED"

export const LOAD_TSB_DOCUMENT_LANGUAGES = "LOAD_TSB_DOCUMENT_LANGUAGES"
export const LOAD_TSB_DOCUMENT_LANGUAGES_PENDING = "LOAD_TSB_DOCUMENT_LANGUAGES_PENDING"
export const LOAD_TSB_DOCUMENT_LANGUAGES_FULFILLED = "LOAD_TSB_DOCUMENT_LANGUAGES_FULFILLED"
export const LOAD_TSB_DOCUMENT_LANGUAGES_REJECTED = "LOAD_TSB_DOCUMENT_LANGUAGES_REJECTED"

export const CLEAR_DOCUMENTS_MESSAGES = "CLEAR_DOCUMENTS_MESSAGES"

export function loadDocuments(queryParams, refetch = false, limit) {
  return {
    type: refetch ? LOAD_NEW_DOCUMENTS : LOAD_DOCUMENTS,
    payload: getDocuments(queryParams, limit)
  }
}

export function loadTSBDocuments(queryParams, refatch = false, limit) {
  return {
    type: refatch ? LOAD_NEW_TSB_DOCUMENTS : LOAD_TSB_DOCUMENTS,
    payload: getTSBDocuments(queryParams, limit)
  }
}

export function loadDocumentModels() {
  return {
    type: LOAD_DOCUMENT_MODELS,
    payload: getDocumentModels()
  }
}

export function loadDocumentTypes() {
  return {
    type: LOAD_DOCUMENT_TYPES,
    payload: getDocumentTypes()
  }
}

export function loadDocumentLanguages() {
  return {
    type: LOAD_DOCUMENT_LANGUAGES,
    payload: getDocumentLanguages()
  }
}

export function loadTsbDocumentLanguages() {
  return {
    type: LOAD_TSB_DOCUMENT_LANGUAGES,
    payload: getTsbDocumentLanguages()
  }
}

export function clearDocumentsMessages() {
  return {
    type: CLEAR_DOCUMENTS_MESSAGES
  }
}

